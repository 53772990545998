import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Radio } from "antd";
import type { RadioChangeEvent } from 'antd';

import styles from '../index.module.scss';

const GeneralInfo = (props: { accepted?: boolean | null, onChange?: (e: RadioChangeEvent) => void, hideRadioButtons?: boolean }) => {
    const { t } = useTranslation();

    return (
        <Card className={styles.CustomCard}>
            <p className={styles.InfoTitle} >{t('kpmgForm.generalInfo.title')}</p>
            <p style={{ margin: 0 }} >{t('kpmgForm.generalInfo.p1')}</p>
            <ul>
                <li>{t('kpmgForm.generalInfo.l1')}</li>
                <li>{t('kpmgForm.generalInfo.l2')}</li>
                <li>{t('kpmgForm.generalInfo.l3')}</li>
                <li>{t('kpmgForm.generalInfo.l4')}</li>
                <li>{t('kpmgForm.generalInfo.l5')}</li>
                <li>{t('kpmgForm.generalInfo.l6')}</li>
                <li>{t('kpmgForm.generalInfo.l7')}</li>
                <li>{t('kpmgForm.generalInfo.l8')}</li>
                <li>{t('kpmgForm.generalInfo.l9')}</li>
            </ul>
            {!props.hideRadioButtons && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Radio.Group onChange={props.onChange} value={props.accepted}>
                        <Radio value={true}>{t('kpmgForm.generalInfo.yes')}</Radio>
                        <Radio value={false}>{t('kpmgForm.generalInfo.no')}</Radio>
                    </Radio.Group>
                </div>
            )}

        </Card>
    )
}

export default GeneralInfo;