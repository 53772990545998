import i18n from 'i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['tr', 'en', 'fr', 'de'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      queryStringParams: { v: '1.0.0' },
			loadPath: `${process.env.PUBLIC_URL}/static/locales/{{lng}}/translation.json`
    },
    debug: false,
  })

export default i18n