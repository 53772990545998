import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import { Badge, Space, Tag, Button, Form, Divider } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import FormPreview from '../../components/FormPreview';
import BasePage from '../BasePage';

import { IncidentData } from '../../app/interface';
import { useIncidentDetail } from './hooks';
import formatDate from '../../utils/formatDate';

import styles from './index.module.scss';

const Header = (props: { incident: { data: IncidentData } }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	}

	return (
		<div className={styles.DetailHeader}>
			<div className={styles.NavigationButtons} onClick={handleBack}>
				<ArrowLeftOutlined className={styles.Back} />
				<p className={styles.Back} style={{ marginLeft: '3px ' }}>{t('globals.back')}</p>
			</div>
			<div className={styles.Details}>
				<div className={styles.Name}>{t('incident.details.overview.incident')} (#{props.incident.data.id})</div>
				<div className={styles.OtherDetails}>
					<Space size="large">
						<Space>
							<div>{t('incident.details.overview.currentStatus')}</div>
							<Badge status={props.incident.data.status?.colour} text={props.incident?.data.status?.name} />
						</Space>
						<Space>
							<div>{t('incident.details.overview.priorityLevel')}</div>
							<Tag>{props.incident?.data.priority!}</Tag>
						</Space>
					</Space>
				</div>
			</div>
		</div>
	);
};

const FormPage = () => {
	const { t } = useTranslation();
	const { incidentId } = useParams();
	const query = useIncidentDetail(incidentId!);

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<div>
						<div className={styles.Title}>
							<p>{t('incident.details.overview.title')}</p>
							<Button
								onClick={window.print}
							>
								{t('globals.print')}
							</Button>
						</div>
						{query.data && (
							<div className={styles.Tabs}>
								<div className={styles.Overview}>
									<Form labelAlign="left" labelCol={{ span: 6 }}>
										<Form.Item label={t('incident.details.overview.createdDate')}>{formatDate(query.data.createdDate)}</Form.Item>
										<Divider />
										<Form.Item label={t('incident.details.overview.dueDate')}>{query.data.dueDate ? formatDate(query.data.dueDate) : '-'}</Form.Item>
										<Divider />
									</Form>
								</div>
							</div>
						)}
					</div>
					<div>
						<p className={styles.Title}>{t('incident.details.form.title')}</p>
						<FormPreview data={query.data} />
					</div>
				</div>
			}
			isTop={true}
			header={query.data != null ? <Header incident={query.data} /> : null}
		/>
	);
};

export default FormPage;
