import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Form, Col, Row, Button, Input, Modal } from "antd";

import VerificationModal from "../../../modals/VerificationModal";
import { getSession } from "../../../helpers/session";
import { updateUser, userMfa } from "../../../app/apis/users";
import { MfaResponseData } from "../../../app/interface";
import messages from "../../../utils/message";

const ChangePassword = () => {
    const { t } = useTranslation();
    const { user = {} } = getSession();
    const [form] = Form.useForm();

    const [open, setOpen] = useState<boolean>(false);
    const [payload, setPayload] = useState<MfaResponseData>({ verificationCode: '' });
    const [loading, setLoading] = useState<{}>(false);
    const [disabled, setDisabled] = useState<boolean>(false);


    const updateUserPassword = () => {
        setLoading(true);
        setDisabled(true);
        userMfa().then((response) => {
            setPayload(response.data);
            setOpen(true);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            setDisabled(false);
            messages.showErrorMessage(error?.response?.data);
        })
    }

    const onSubmit = () => {
        Modal.confirm({
            title: t('settings.changePassword.confirmModal.title'),
            content: t('settings.changePassword.confirmModal.content'),
            onOk: updateUserPassword,
        });
    };

    const onVerify = async (code: string) => {
        const formValues = form.getFieldsValue(['password', 'newPassword']);
        updateUser({ authCode: code, verificationCode: payload.verificationCode, password: formValues.password, newPassword: formValues.newPassword })
            .then(() => {
                form.resetFields();
                setOpen(false);
                setDisabled(false);
                messages.showSuccesMessage();
                return true;
            }).catch((error) => {
                messages.showErrorMessage(error.response?.data);
            })
        return false;
    };

    const onCancel = () => {
        setOpen(false);
        form.resetFields();
    };

    const onResend = () => {
        updateUserPassword();
    };

    return (
        <>
            <Card title={t('settings.changePassword.title')}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={user}
                    onFinish={onSubmit}
                >
                    <Row gutter={52}>
                        <Col span={24}>
                            <Form.Item
                                label={t('settings.currentPassword.label')}
                                name="password"
                                rules={[{ required: true, message: t('errors.required')! }]}
                            >
                                <Input
                                    type="password"
                                    disabled={disabled}
                                    placeholder={t('settings.currentPassword.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="newPassword"
                                label={t('settings.newPassword.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    { min: 6, message: t('form.password6')! },
                                    { pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/, message: t('form.passwordRules')! },
                                ]}
                            >
                                <Input.Password
                                    disabled={disabled}
                                    placeholder={t('settings.newPassword.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="confirm-password"
                                label={t('settings.confirmPassword.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    t('form.passwrodsNotMatch')!,
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    disabled={disabled}
                                    placeholder={t('settings.confirmPassword.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify="center">
                                <Col>
                                    <Button
                                        loading={loading}
                                        htmlType="submit"
                                        type="primary"
                                    >
                                        {t('globals.save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <VerificationModal
                open={open}
                email={payload.mfaType === 'email' ? payload.sentAddress : undefined}
                phone={payload.mfaType !== 'email' ? payload.sentAddress : undefined}
                method={payload.mfaType}
                timeout={120}
                onCancel={onCancel}
                onResend={onResend}
                onVerify={onVerify}
            />
        </>
    )
}

export default ChangePassword;