import axios, { InternalAxiosRequestConfig } from 'axios';
import { camelize, snakeize } from 'casing';

const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json'
	},
	responseType: 'json'
});

apiClient.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const session = JSON.parse(window.localStorage.getItem('SESSION')!);

		if (config.headers != null) {
			if (session != null) {
				config.headers.Authorization = `Token ${session.token}`;
			}

			if (config.data != null && config.headers['Content-Type'] === 'application/json') {
				config.data = snakeize(config.data);
			}
		}

		if (config.params != null) {
			config.params = snakeize(config.params);
		}

		return config;
	},
	(error: any) => {
		return Promise.reject(error);
	}
);

apiClient.interceptors.response.use(
	(response) => {
		if (response.data != null && response.headers['content-type'] === 'application/json') {
			response.data = camelize(response.data);
		}

		return response;
	},
	(error) => {
		if (error.response != null && error.response.status === 401 && error.response.data != null && error.response.data.detail === 'Invalid token.') {
			window.localStorage.removeItem('token');
		}

		return Promise.reject(error);
	}
);

export default apiClient;
