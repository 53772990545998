import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Form, Col, Row, Input, Button, Modal } from "antd";

import VerificationModal from "../../../modals/VerificationModal";
import { getSession, setUser } from "../../../helpers/session";
import messages from "../../../utils/message";
import { updateUser, userMfa } from "../../../app/apis/users";

const AccountSettings = () => {
    const { t } = useTranslation();
    const { user = {} } = getSession();
    const [form] = Form.useForm();

    const [open, setOpen] = useState<boolean>(false);
    const [payload, setPayload] = useState<{ verificationCode: string, mfaType?: string, sentAddress?: string }>({ verificationCode: '' });
    const [loading, setLoading] = useState<{}>(false);
    const [userData, setUserData] = useState<{ email: string, name?: string, phoneNumber?: string }>({ ...user })

    const updateUserAccount = () => {
        const phoneNumber = form.getFieldValue('phoneNumber');
        setLoading(true);
        userMfa({ phoneNumber }).then((response) => {
            setPayload(response.data);
            setOpen(true);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            messages.showErrorMessage(error?.response?.data);
        })
    }

    const onSubmit = () => {
        Modal.confirm({
            title: t('settings.accountSettings.confirmModal.title'),
            content: t('settings.accountSettings.confirmModal.content'),
            onOk: updateUserAccount,
        });
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onResend = () => {
        updateUserAccount();
    };

    const onVerify = async (code: string) => {
        const formValues = form.getFieldsValue(['name', 'phoneNumber']);
        updateUser({ authCode: code, verificationCode: payload.verificationCode, ...formValues })
            .then(() => {
                setUserData((user) => ({ ...user, name: formValues.name, phoneNumber: formValues.phoneNumber }))
                setUser({ ...user, name: formValues.name, phoneNumber: formValues.phoneNumber })
                setOpen(false);
                messages.showSuccesMessage();
                return true;
            }).catch((error) => {
                messages.showErrorMessage(error.response?.data);
            })
        return false;
    };

    return (
        <>
            <Card
                title={t('settings.accountSettings.title')}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        ...userData
                    }}
                    onFinish={onSubmit}
                >
                    <Row gutter={52}>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label={t('settings.email.label')}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={t('settings.name.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    { min: 3 },
                                ]}
                            >
                                <Input
                                    placeholder={t('settings.name.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="phoneNumber"
                                label={t('settings.phone.label')}
                                rules={[{ required: true, min: 12 }]}
                            >
                                <Input
                                    placeholder={t('settings.phone.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify="center">
                                <Col>
                                    <Button
                                        loading={loading}
                                        htmlType="submit"
                                        type="primary"
                                    >
                                        {t('globals.save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <VerificationModal
                open={open}
                email={payload.mfaType === 'email' ? payload.sentAddress : undefined}
                phone={payload.mfaType !== 'email' ? payload.sentAddress : undefined}
                method={payload.mfaType}
                timeout={120}
                onCancel={onCancel}
                onResend={onResend}
                onVerify={onVerify}
            />
        </>
    )
}

export default AccountSettings;