import { useQuery } from 'react-query';
import { getDashboardData } from '../../../app/apis/users';

export const useDashboardData = () => {
	return useQuery(
		['dashboard'],
		async () => {
			const response: any = await getDashboardData();

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};

