import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Row,
    Col,
    Form,
    Alert,
    Input,
    Button,
    Space,
    Typography,
} from 'antd';
import { Footer } from 'antd/es/layout/layout';

import useQueryParams from '../../hooks/useQueryParams';
import Header from '../../components/Header';
import { resetPassword } from '../../app/apis/auth';
import messages from '../../utils/message';

import styles from './index.module.scss';

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useQueryParams();

    const [success, setSuccess] = useState<boolean>();

    const onSubmit = (formValues: { password: string }) => {
        const { password } = formValues;
        resetPassword({ password, token: token! }).then(() => {
            messages.showSuccesMessage();
            setSuccess(true);
        }).catch((err) => {
            messages.showErrorMessage(err?.response?.data);
        })
    };

    if (!token) {
        navigate('/admin-login');
    }

    return (
        <>
            <Header showLogout={false} showUser={false} />
            <Space direction="vertical" size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
                <Row justify="center">
                    <Col>
                        <Link to="/">
                            <img alt="Logo" src="/static/images/logo.png" width="auto" height="124" />
                        </Link>
                    </Col>
                </Row>
                <Typography.Text>{t('resetPassword.title')}</Typography.Text>
                <Form layout="vertical" onFinish={onSubmit}>
                    <Row style={{ width: 500 }}>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                label={t('resetPassword.newPassword.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    { min: 6, message: t('form.password6')! },
                                    { pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/, message: t('form.passwordRules')! },
                                ]}
                            >
                                <Input.Password
                                    disabled={success}
                                    placeholder={t('resetPassword.newPassword.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="confirm-password"
                                label={t('resetPassword.confirmPassword.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    t('form.passwrodsNotMatch')!,
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    disabled={success}
                                    placeholder={t('resetPassword.confirmPassword.placeholder')!}
                                />
                            </Form.Item>
                        </Col>
                        {success && (
                            <Col span={24}>
                                <Alert
                                    type='success'
                                    style={{ marginBottom: '2em' }}
                                    description={t('resetPassword.success')}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                            <Row justify="center">
                                <Col xs={24} sm={12} xl={10}>
                                    <Button
                                        disabled={success}
                                        htmlType="submit"
                                        type="primary"
                                        className={styles['reset-password__reset-button']}
                                    >
                                        {t('resetPassword.reset')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <Link to="/admin-login">
                                        <Button
                                            type="text"
                                            className={styles['reset-password__back-button']}
                                        >
                                            {t('resetPassword.back')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Space>
            <Footer style={{ textAlign: 'center' }}>{t('globals.footer')}</Footer>
        </>
    );
};

export default ResetPassword;
