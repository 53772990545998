import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Badge, Space, Tabs, Tag } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import BasePage from '../BasePage';
import History from './components/History';
import IncidentDocuments from './components/IncidentDocuments';
import IncidentNotes from './components/IncidentNotes';
import IncidentOverview from './components/IncidentOverview';
import Resolve from './components/Resolve';
import WhistleblowerAnswers from './components/WhistleblowerAnswers';

import { useIncidentDetail } from './hooks';
import Priority from '../../constants/priority';
import { IncidentData } from '../../app/interface';

import styles from './index.module.scss';

const Header = (props: { incident: IncidentData, updatedPriority: number, updatedStatus?: { name: string; colour: "default" | "success" | "processing" | "error" | "warning" | undefined } }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	}

	return (
		<div className={styles.DetailHeader}>
			<div className={styles.NavigationButtons} onClick={handleBack}>
				<ArrowLeftOutlined className={styles.Back} />
				<p className={styles.Back} style={{ marginLeft: '3px ' }}>{t('globals.back')}</p>
			</div>
			<div className={styles.Details}>
				<div className={styles.Name}>{t('incident.details.overview.incident')} (#{props.incident.id})</div>
				<div className={styles.OtherDetails}>
					<Space size="large">
						<Space>
							<div>{t('incident.details.overview.currentStatus')}</div>
							<Badge status={
								props.updatedStatus ?
									props.updatedStatus?.colour :
									props.incident?.status?.colour
							}
								text={
									props.updatedStatus ?
										props.updatedStatus.name :
										props.incident?.status?.name
								}
							/>
						</Space>
						<Space>
							<div>{t('incident.details.overview.priorityLevel')}</div>
							<Tag>{t(`priority.${Priority[(props.updatedPriority ? props.updatedPriority : props.incident.priority) as keyof typeof Priority]}`)}</Tag>
						</Space>
					</Space>
				</div>
			</div>
		</div>
	);
};

const Detail = () => {
	const { t } = useTranslation();
	const { incidentId } = useParams();
	const query = useIncidentDetail(incidentId!);

	const [updatedPriority, setUpdatedPriority] = useState<number>();
	const [updatedStatus, setUpdatedStatus] = useState<{ name: string; colour: "default" | "success" | "processing" | "error" | "warning" | undefined }>();

	const handleUpdatePriorty = (value: number) => {
		setUpdatedPriority(value);
	}

	const handleUpdateStatus = (value: { name: string; colour: "default" | "success" | "processing" | "error" | "warning" | undefined }) => {
		setUpdatedStatus(value);
	}

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<Tabs
						tabBarStyle={{
							margin: 0
						}}
						tabBarGutter={4}
						type="card"
						items={[
							{
								label: t('incident.details.overview.tabs.incidentOverview'),
								key: 'overview',
								children: query.data != null ? <IncidentOverview incident={query.data?.data} onUpdatePriority={handleUpdatePriorty} /> : null
							},
							{
								label: t('incident.details.overview.tabs.documents'),
								key: 'documents',
								children: query.data != null ? <IncidentDocuments /> : null
							},
							{
								label: t('incident.details.overview.tabs.notes'),
								key: 'notes',
								children: query.data != null ? <IncidentNotes /> : null
							},
							{
								label: t('incident.details.overview.tabs.whistleblowerAnswers'),
								key: 'whistleblower-answers',
								children: query.data != null ? <WhistleblowerAnswers incident={query.data} /> : null
							},
							{
								label: t('incident.details.overview.tabs.history'),
								key: 'history',
								children: query.data != null ? <History /> : null
							},
							{
								label: t('incident.details.overview.tabs.resolveIncident'),
								key: 'resolve',
								children: query.data != null ? <Resolve incident={query.data} onUpdateStatus={handleUpdateStatus} /> : null
							}
						]}
					/>
				</div>
			}
			isTop={true}
			header={query.data != null ? <Header incident={query.data.data} updatedPriority={updatedPriority!} updatedStatus={updatedStatus} /> : null}
		/>
	);
};

export default Detail;
