import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Space, Card, Typography, Result } from 'antd';

import Layout, { Content } from 'antd/es/layout/layout';
import Header from '../../components/Header';

import { selectUser } from '../../app/slices/userSlice';

const { Text, Link } = Typography;

const ConfirmationPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = useSelector(selectUser);
    const incident = user.id;

    if (!incident) {
        navigate('/');
    }

    return (
        <Layout>
            <Header showLogout={false} showUser={false} companyType={1} />
            <Content
                className='content'
                style={{
                    background: `#fff`,
                    minHeight: 'calc(100vh - 150px)'
                }}
            >
                <Card style={{ background: '#fff', padding: 20, width: '100%' }}>
                    <Result
                        status="success"
                        title={incident}
                        subTitle={
                            <Space direction="vertical">
                                <Text>{t('confirmation.info')}</Text>
                                <Text>{t('confirmation.info2')}</Text>
                                <Link href="/login">{t('confirmation.link')}</Link>
                            </Space>
                        }
                    />
                </Card>
            </Content>
        </Layout>
    );
};

export default ConfirmationPage;