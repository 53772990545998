import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Typography, Card, Row, Col, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

import Header from '../../components/Header';

import { createIncident } from '../../app/apis/whistleblower';
import { setReportKey } from '../../app/slices/userSlice';

import styles from './index.module.scss';
import { removeSession } from '../../helpers/session';

const { Title, Text } = Typography;

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const [isAlertHovered, setIsAlertHovered] = useState<boolean>(false);
    const [isComputerHovered, setIsComputerHovered] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onStartClick = (): void => {
        createIncident()
            .then((response) => {
                dispatch(setReportKey(response.data.id));
                navigate('/form');
            })
            .catch((error) => { });
    };

    useEffect(() => {
        removeSession();
    }, []);

    return (
        <Layout>
            <Header showLogout={false} showUser={false} companyType={1} />
            <Content
                className='content'
                style={{
                    background: `#fff`,
                    minHeight: 'calc(100vh - 150px)'
                }}
            >
                <Row gutter={[16, 24]} >
                    <Col span={24}>
                        <Title level={4}>{t('index.title')}</Title>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card
                            className={styles.CustomCard}
                            hoverable
                            style={{ borderColor: '#001529' }}
                            onMouseEnter={() => setIsAlertHovered(true)}
                            onMouseLeave={() => setIsAlertHovered(false)}
                            onClick={onStartClick}
                        >
                            <div className={styles.CardContent}>
                                <img
                                    src={isAlertHovered ? '/static/icons/hover-alert-icon.png' : '/static/icons/alert-icon.png'}
                                    alt='alert-icon'
                                    className={styles.Icon}
                                />
                                <h2>{t('index.startIncidentCardTitle')}</h2>
                                <p>{t('index.startIncidentCardDescription')}</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Card
                            className={styles.CustomCard}
                            hoverable
                            style={{ borderColor: '#001529' }}
                            onMouseEnter={() => setIsComputerHovered(true)}
                            onMouseLeave={() => setIsComputerHovered(false)}
                            onClick={() => navigate('/login')}
                        >
                            <div className={styles.CardContent}>
                                <img
                                    src={isComputerHovered ? '/static/icons/hover-computer-icon.png' : '/static/icons/computer-icon.png'}
                                    alt='computer-icon'
                                    className={styles.Icon}
                                />
                                <h2>{t('index.followUpCardTitle')}</h2>
                                <p>{t('index.followUpCardDescription')}</p>
                            </div>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Title level={4}>{t('index.paragraphTitle')}</Title>
                        <Row gutter={[16, 24]} style={{ marginBottom: 8 }}>
                            <Col span={24}>
                                <Text style={{ fontSize: 18 }}>{t('index.paragraph1')}</Text>
                            </Col>
                            <Col span={24}>
                                <Text strong style={{ fontSize: 18 }}>
                                    {t('index.paragraph2')}
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Text style={{ fontSize: 18 }}>{t('index.paragraph3')}</Text>
                            </Col>
                        </Row>
                        <div
                            style={{
                                flexGrow: 1,
                                backgroundColor: '#001529',
                                color: '#fff',
                                padding: '25px',
                                marginTop: '30px',
                                marginBottom: '30px',
                                lineHeight: '2.5em'
                            }}
                        >
                            <Col span={24} style={{ fontSize: 18 }}>
                                {t('index.subtext1')}
                            </Col>
                            <Col span={24} style={{ fontSize: 18 }}>
                                {t('index.subtext2')}
                            </Col>
                            <Col span={24} style={{ fontSize: 18 }}>
                                {t('index.subtext3')}
                            </Col>
                            <Col span={24} style={{ fontSize: 18 }}>
                                {t('index.subtext4')}
                            </Col>
                            <Col span={24} style={{ fontSize: 18 }}>
                                {t('index.subtext5')}
                            </Col>
                        </div>
                        <Text style={{ fontSize: 18 }}>{t('index.paragraph4')}</Text>
                        <div style={{ display: 'flex', marginTop: 16 }}>
                            <ul style={{ flexGrow: 1 }}>
                                <li className={styles.ListItem}>{t('index.list1')}</li>
                                <li className={styles.ListItem}>{t('index.list2')}</li>
                                <li className={styles.ListItem}>{t('index.list3')}</li>
                            </ul>
                            <ul style={{ flexGrow: 1 }}>
                                <li className={styles.ListItem}>{t('index.list4')}</li>
                                <li className={styles.ListItem}>{t('index.list5')}</li>
                                <li className={styles.ListItem}>{t('index.list6')}</li>
                            </ul>
                            <ul style={{ flexGrow: 1 }}>
                                <li className={styles.ListItem}>{t('index.list7')}</li>
                                <li className={styles.ListItem}>{t('index.list8')}</li>
                                <li className={styles.ListItem}>{t('index.list9')}</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default HomePage;
