import React from 'react';

import { Card, Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { IncidentData } from '../../../app/interface';
import formatDate from '../../../utils/formatDate';

import styles from '../index.module.scss';

const IncidentOverview = (props: { incident: IncidentData }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.Tabs}>
			<div className={styles.Overview}>
				<Form labelAlign="left" labelCol={{ xs: 12, md: 6 }}>
					<Form.Item label={t('incident.details.overview.createdDate')}>{formatDate(props.incident.createdDate)}</Form.Item>
					<Divider />
					<Form.Item label={t('incident.details.overview.dueDate')}>{props.incident.resolution ? formatDate(props.incident.resolution?.createdDate) : '-'}</Form.Item>
					<Divider />
					<Card title={t('incident.details.overview.conclusion')}>
						{props.incident.status.value === 1 ? (
							<p>{t('incident.details.overview.waiting')}</p>
						) : props.incident.resolution ? props.incident.resolution?.content : '-'}
					</Card>
				</Form>
			</div>
		</div>
	);
};

export default IncidentOverview;
