import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined, CaretRightOutlined } from '@ant-design/icons';

import { TotalIncidentData } from "../../../app/interface";
import handshakeSVG from '../assets/handshake.svg';

import styles from './index.module.scss';

const TotalIncident = (props: { states: Array<TotalIncidentData> }) => {
    const { t } = useTranslation();
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        props.states?.forEach((s) => {
            if (s.id !== 1) {
                setTotal((total) => total + s.count)
            }
        })
    }, [props.states]);

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '24px',
            }}
        >
            <Row gutter={[10, 16]}>
                <Col>
                    <div className={styles.Icon}>
                        <img
                            src={handshakeSVG}
                            alt="svg icon"
                        />
                    </div>
                </Col>
                <Col>
                    <Row gutter={[10, 0]} style={{ marginBottom: 3, display: 'flex' }}>
                        <Col>
                            <h4 className={styles.Title}>{t('dashboard.totalIncident.title')}</h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.totalIncident.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 3 }}>
                        <Col className={styles.InfoText}>
                            {total}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row gutter={[12, 0]}>
                                <Col>
                                    <Row gutter={[4, 0]}>
                                        <Col>{t('dashboard.totalIncident.resolved')}</Col>
                                        <Col>
                                            <CaretRightOutlined style={{ color: '#0042EF' }} />
                                        </Col>
                                        <Col>{props.states?.find((s) => s.id === 3)?.count}</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row gutter={[4, 0]}>
                                        <Col>{t('dashboard.totalIncident.inProgress')}</Col>
                                        <Col>
                                            <CaretRightOutlined style={{ color: '#8FA9ED' }} />
                                        </Col>
                                        <Col>{props.states?.find((s) => s.id === 2)?.count}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
};

export default TotalIncident;