import React, { useEffect, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { ConfigProvider, Layout } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';

import SideMenu from './components/SideMenu';
import Header from '../../components/Header';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import i18n from '../../app/i18n';
import { selectUser, setLanguage } from '../../app/slices/userSlice';
import useSession from '../../hooks/useSession';
import { checkIsAdmin } from '../../helpers/session';

import styles from './BaseLayout.module.scss';


const BaseLayout = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isAdmin = checkIsAdmin();
	const { isAuthenticated, isAuthenticatedWhistleblower } = useSession();
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const userLanguage = useAppSelector(selectUser);
	const language = userLanguage.language;

	useEffect(() => {
		if (language == null) {
			dispatch(setLanguage(i18n.resolvedLanguage));
			i18n.changeLanguage(i18n.resolvedLanguage);
		}
	}, [dispatch, userLanguage, language]);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
	}, [isAuthenticated, navigate]);

	useEffect(() => {
		if (!isAdmin && !isAuthenticatedWhistleblower) {
			navigate('/login')
		}
	}, [isAdmin, userLanguage, isAuthenticatedWhistleblower, navigate])

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#0042EF',
				},
			}}
		>
			<I18nextProvider i18n={i18n}>
				<Layout className={styles.Base}>
					<Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme="light">
						<div className={styles.SideLogo}>
							<img alt="Logo" src="/static/images/side-logo.png" />
						</div>
						<SideMenu isAdmin={isAdmin} />
					</Sider>
					<Layout className={styles.Layout}>
						<Header showLogout showUser showLogo={false} />
						<Outlet />
						<Footer style={{ textAlign: 'center' }}>{t('globals.footer')}</Footer>
					</Layout>
				</Layout>
			</I18nextProvider>
		</ConfigProvider>
	);
};

export default BaseLayout;
