import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import useCountries from '../../../hooks/useCountires';
import geoUrl from './features.json';

import styles from './index.module.scss';
import { IncidentCountryData } from "../../../app/interface";

const colorScale = scaleLinear<string>().domain([0, 100]).range(['#F0F5FF', '#030852']);

const IncidentCountry = (props: { countries: Array<IncidentCountryData> }) => {
    const { t } = useTranslation();
    const { getCountry } = useCountries();

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Row gutter={[10, 0]} style={{ marginBottom: 3 }}>
                        <Col>
                            <h4 className={styles.Title}>
                                {t('dashboard.incidentCountry.title')}
                            </h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.incidentCountry.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div className={styles['dashboard__country-chart__map']}>
                        <ComposableMap
                            height={425}
                            projection="geoEqualEarth"
                            projectionConfig={{
                                scale: 155,
                            }}
                        >
                            <Geographies
                                geography={geoUrl}
                            >
                                {({ geographies }) => geographies.map((geo) => {
                                    const d = props.countries?.find(
                                        (s) => {
                                            const country = getCountry(s?.isoCode);
                                            return country?.alpha3 === geo.id.toLowerCase();
                                        },
                                    );
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill={d ? colorScale(d?.count) : '#FFF'}
                                            stroke="rgba(134, 134, 134)"
                                            strokeWidth={0.25}
                                            style={{
                                                default: {
                                                    outline: 'none',
                                                },
                                                hover: {
                                                    outline: 'none',
                                                },
                                                pressed: {
                                                    outline: 'none',
                                                },
                                            }}
                                        />
                                    );
                                })}
                            </Geographies>
                        </ComposableMap>
                    </div>
                </Col>

            </Row>
        </Card>
    )
};

export default IncidentCountry;