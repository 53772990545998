import apiClient from "../api";

const BASE_URL = `${process.env.REACT_APP_API_URL}/users/notifications`;

export const getNotifications = (page?: number, page_size?: number) => {
    const params = {
        page: page,
        page_size: page_size
    };
    return apiClient.get(`${BASE_URL}/`, { params });
}

export const patchNotification = (data: {}) => {
    return apiClient.patch(`${BASE_URL}/`, data);
}

export const puthNotification = (data: {}) => {
    return apiClient.put(`${BASE_URL}/`, data);
}

export const deleteNotification = () => {
    return apiClient.delete(`${BASE_URL}/`);
}

export const readAllNotifications = () => {
    return apiClient.patch(`${BASE_URL}/read-all/`);
}