import { message } from 'antd';
import i18n from '../app/i18n';

function showSuccesMessage(content?: string) {
    const text = content || i18n.t('messages.defaultSuccess');
    return message.success(text);
}

function showErrorMessage(content?: string) {
    const text = content || i18n.t('messages.defaultError');
    return message.error(text);
}

const messages = { showSuccesMessage, showErrorMessage }

export default messages;