import { useState } from 'react';

function useLocalStorage(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState(() => {
        let item = initialValue;

        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            item = window.localStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return item;
        }
    });

    const setValue = (value: any) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);

            if (typeof window !== 'undefined') {
                let val = valueToStore;

                if (typeof valueToStore !== 'string') {
                    val = JSON.stringify(valueToStore);
                }
                window.localStorage.setItem(key, val);
            }
            return null;
        } catch (error) {
            return error;
        }
    };
    return [storedValue, setValue];
}

export default useLocalStorage;
