import React, { ReactNode } from 'react';

import { theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import styles from './BasePage.module.scss';

const BasePage = (props: { content: ReactNode | null; header: ReactNode | null; isTop: boolean; }) => {
	const {
		token: { colorBgContainer }
	} = theme.useToken();

	return (
		<div>
			<Header className={props.isTop ? styles.PageHeaderTop : styles.PageHeader} style={{ backgroundColor: colorBgContainer }}>
				{props.header}
			</Header>
			<Content className={styles.PageContent} style={{ backgroundColor: colorBgContainer }}>
				{props.content}
			</Content>
		</div>
	);
};

export default BasePage;
