import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";

import Question from "../../KPMGFormPage/components/Question";

const Company = () => {
    const { t } = useTranslation();

    const questions = [
        {
            id: 1,
            type: 6,
            isRequired: true,
            row: 1,
            content: "Select company",
            slug: "company",
            options: [
                {
                    id: 1,
                    value: 1,
                    label: "Borusan"
                },
                {
                    id: 2,
                    value: 2,
                    label: "Türk Hava Yolları"
                },
                {
                    id: 3,
                    value: 3,
                    label: "Acun Medya"
                }
            ]
        },
        {
            id: 2,
            type: 6,
            isRequired: true,
            row: 5,
            content: "Select report file",
            slug: "reportfile",
            options: [
                {
                    id: 1,
                    value: 1,
                    label: "etikdestekhattı"
                }
            ]
        }
    ];

    return (
        <div style={{ maxWidth: 600, margin: "auto" }}>
            <p style={{ textAlign: 'center' }}>{t('startIncident.company.info')}</p>
            <Form>
                {questions.map((question) => (
                    <div id={question.slug} style={{ padding: '1em' }}>
                        <Question question={question} parentSlug={question.slug} />
                    </div>
                ))}
            </Form>
        </div>
    )
};

export default Company;