import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { Button, Empty, Divider } from 'antd';

import WhistleblowerQuestionModal from '../../../modals/WhistleblowerQuestionModal';

import { IncidentData, IncidentAdditionalQuestionData } from '../../../app/interface';
import { sendIncidentQuestion, uploadIncidentQuestionFiles } from '../../../app/apis/incidents';
import { useIncidentQuestions } from '../hooks';

import styles from '../index.module.scss';

const WhistleblowerAnswers = (props: { incident: IncidentData }) => {
	const { t } = useTranslation();
	const { incidentId } = useParams();
	const [isQuestionToWhistleblowerModalOpen, setQuestionToWhistleblowerModalOpen] = useState<boolean>(false);
	const [toggle, setToggle] = useState<boolean>(false);

	const query = useIncidentQuestions(incidentId!, toggle);

	const handleSendQuestion = (payload: { content: string, files: [] }) => {
		sendIncidentQuestion(incidentId!, payload).then((response) => {
			setToggle((toggle) => !toggle);
			const questionId = response.data?.id;
			handleSubmitQuestionDocument(questionId, payload.files);
			setQuestionToWhistleblowerModalOpen(false);
		});
	}

	const handleSubmitQuestionDocument = (questionId: string, payload: []) => {
		payload.map(async (file) => {
			await uploadIncidentQuestionFiles(incidentId!, questionId!, file)
		})
	}

	const Question = (props: { question: IncidentAdditionalQuestionData }) => {
		const { question } = props;

		return (
			<div key={question.id}>
				<p>{t('incident.details.incomingQuestions.questionAsked')}</p>
				<p className={styles.NoteInfo} >{`${question.user?.name || question.user.email} - ${dayjs(question.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
				<p>{question.content}</p>
				<Divider />
				{question.replies && question.replies.map((answer) => (
					<div style={{ marginLeft: 25 }}>
						<p>{t('incident.details.incomingQuestions.answered')}</p>
						<p className={styles.NoteInfo} >{`${dayjs(answer.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
						<p style={{ marginTop: 20 }}>
							{answer.content}
						</p>
					</div>
				))}
			</div>
		)
	}

	return query.data != null ? (
		<div className={styles.Tabs}>
			<div className={styles.WhistleblowerAnswers}>
				{query.data.count > 0 ? (
					<div className={styles.AdditionalQuestions}>
						<Divider />
						{query.data.results?.map((question: IncidentAdditionalQuestionData, index: number) => (
							<Question question={question} key={index} />
						)
						)}
					</div>
				) : (
					<div className={styles.NoQuestion}>
						<Empty
							image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
							imageStyle={{ height: 60 }}
							description={
								<span>
									{t('incident.details.whistleblowerAnswers.noQuestion')}
								</span>
							}
						>
							<Button
								type="primary"
								onClick={() => setQuestionToWhistleblowerModalOpen(true)}
							>
								{t('incident.details.whistleblowerAnswers.questionToWhistleblower')}
							</Button>
						</Empty>
					</div>
				)}
			</div>
			<WhistleblowerQuestionModal
				isOpen={isQuestionToWhistleblowerModalOpen}
				setOpen={(value: boolean) => setQuestionToWhistleblowerModalOpen(value)}
				onSubmit={handleSendQuestion}
			/>
		</div>
	) : null;
};

export default WhistleblowerAnswers;
