import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from 'dayjs';

import { Button, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { ActivityFeedData } from "../../../app/interface";

import styles from '../index.module.scss';

const FeedItem = (props: { item: ActivityFeedData }) => {
    const { id, createdDate, content, isRead } = props.item;

    const { t } = useTranslation();

    const buttonContent = (
        <Button type="text">{isRead ? t('activityFeed.markAsRead') : t('activityFeed.markAsUnread')}</Button>
    )

    return (
        <div key={id} className={styles.Item} style={isRead ? { background: '#e5f7ff' } : undefined}>
            {isRead && <div className={styles.ReadDot} />}
            <div className={styles.TextArea}>
                <p style={isRead ? { color: '#256bbf' } : { color: '#4b4b4b' }} >{content}</p>
                <p style={isRead ? { color: '#6fbfff' } : { color: '#bdbdbd' }} >{dayjs(createdDate).format('DD/MM/YYYY HH:MM')}</p>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ marginRight: '1em' }}>
                <Popover content={buttonContent} trigger='click' placement="leftTop">
                    <Button shape="circle" icon={<EllipsisOutlined />} />
                </Popover>
            </div>
        </div>
    )
}

export default FeedItem;