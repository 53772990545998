import apiClient from "../api";
import { MfaData } from "../interface";

const BASE_URL = `${process.env.REACT_APP_API_URL}/users`;

export const getDashboardData = () => {
    return apiClient.get(`${BASE_URL}/dashboard`);
};

export const getCurrentUsers = () => {
    return apiClient.get(`${BASE_URL}/current/`);
};

export const updateUser = (data: MfaData) => {
    return apiClient.patch(`${BASE_URL}/current/`, data);
};

export const userMfa = (data?: { phoneNumber?: string }) => {
    return apiClient.post(`${BASE_URL}/current/mfa/`, data);
};