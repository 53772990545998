import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import BasePage from '../BasePage';
import IncidentOverview from './components/IncidentOverview';
import Form from '../../components/FormPreview';
import Header from './components/Header';

import { useIncidentDetail } from './hooks';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../app/slices/userSlice';

import styles from './index.module.scss';

const Detail = () => {
	const { t } = useTranslation();
	const user = useAppSelector(selectUser);
	const { id, token } = user;

	const query = useIncidentDetail(id!, token!);

	if (query.data) {
		window.localStorage.setItem('status', JSON.stringify(query.data.status));
	}

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<div>
						<div className={styles.Title}>
							<p>{t('incident.details.overview.title')}</p>
							<Button
								onClick={window.print}
							>
								{t('globals.print')}
							</Button>
						</div>
						{query.data && <IncidentOverview incident={query.data} />}
					</div>
					<div>
						<p className={styles.Title}>{t('incident.details.form.title')}</p>
						<Form data={query.data} />
					</div>
				</div>
			}
			isTop={false}
			header={query.data != null ? <Header /> : null}
		/>
	);
};

export default Detail;
