import { createBrowserRouter } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout';
import Login from '../pages/Login';
import routes from '../routes';
import NotFound from '../pages/Errors/NotFound';
import HomePage from '../pages/HomePage/HomePage';
import FormPage from '../pages/FormPage/FormPage';
import ConfirmationPage from '../pages/ConfirmationPage/ConfirmationPage';
import FAQPage from '../pages/FAQPage/FAQPage';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import KPMGFormPage from '../pages/KPMGFormPage/KPMGFormPage';

const router = createBrowserRouter([
	{
		path: '/panel',
		element: <BaseLayout />,
		children: routes
	},
	{
		path: '/',
		element: <HomePage />
	},
	{
		path: '/form',
		element: <FormPage />
	},
	{
		path: '/5a6d39',
		element: <KPMGFormPage />
	},
	{
		path: '/confirmation',
		element: <ConfirmationPage />
	},
	{
		path: '/login',
		element: <Login.UserLogin />
	},
	{
		path: '/admin-login',
		element: <Login.AdminLogin />
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />
	},
	{
		path: '/reset-password',
		element: <ResetPassword />
	},
	{
		path: '/faq',
		element: <FAQPage />
	},
	{
		path: '*',
		element: <NotFound />
	}
]);

export default router;
