import Detail from './Detail';
import List from './List';
import Form from './Form';

const Incidents = {
	Detail,
	List,
	Form
};

export default Incidents;
