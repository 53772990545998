import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Avatar, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FileOutlined } from '@ant-design/icons';

import { IncidentData, IncidentDocumentData } from '../../../app/interface';
import { whistleblowerFileDownload } from '../../../app/apis/whistleblower';

import styles from '../index.module.scss';

const IncidentDocuments = (props: { incident: IncidentData, onRefresh: () => void }) => {
	const { t } = useTranslation();
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(25);

	const handleDownloadDocument = (file: any) => {
		whistleblowerFileDownload({ incident: props.incident.id, file: file.id }).then((response) => {
			const objectUrl = URL.createObjectURL(response.request.response);
			const link = document.createElement('a');
			link.href = objectUrl;
			link.download = `${file.id}.${file.extension.toLowerCase()}`;
			document.body.appendChild(link);
			link.click();

		})
	}

	const columns: ColumnsType<IncidentDocumentData> = [
		{
			title: t('incident.details.documents.createdDate'),
			dataIndex: 'createdDate',
			key: 'createdDate',
			sorter: (a, b) => dayjs(a.createdDate).diff(b.createdDate),
			defaultSortOrder: 'descend',
			render: (createdDate) => dayjs(createdDate).format('DD/MM/YYYY HH:MM')
		},
		{
			title: t('incident.details.documents.uploadedBy'),
			dataIndex: 'user',
			key: 'user',
			render: (user) => (user != null ? user.name : t('incident.details.documents.informer'))
		},
		{
			title: t('incident.details.documents.document'),
			dataIndex: 'id',
			key: 'id',
			render: (_, document) => (
				<Space>
					<Avatar icon={<FileOutlined />} />
					<Typography.Text
						underline
						style={{ cursor: 'pointer' }}
						onClick={() => handleDownloadDocument(document)}
					>
						{`${document.id}.${document.extension}`}
					</Typography.Text>
				</Space>
			)
		}
	];

	return (
		<div className={styles.Tabs}>
			<div className={styles.Documents}>
				<Table
					columns={columns}
					dataSource={props.incident.files}
					pagination={{
						current: page,
						onChange: (page: number, pageSize: number) => {
							setPage(page);
							setPageSize(pageSize);
							props.onRefresh();
						},
						pageSize: pageSize,
						total: props.incident.files.length
					}}
				/>
			</div>
		</div>
	);
};

export default IncidentDocuments;
