import { useMemo, } from 'react';

import { checkSession, checkSessionWhistleblower } from '../helpers/session';

function useSession() {

    const isAuthenticated = useMemo(() => {
        const isAuthSession = checkSession();
        return isAuthSession;
    }, []);

    const isAuthenticatedWhistleblower = useMemo(() => {
        const isAuthSession = checkSessionWhistleblower();
        return isAuthSession;
    }, []);

    return {
        isAuthenticated,
        isAuthenticatedWhistleblower
    };
}

export default useSession;
