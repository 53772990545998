import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "antd";
import { SearchOutlined, ReadOutlined } from "@ant-design/icons";

import styles from '../index.module.scss';
import { readAllNotifications } from "../../../app/apis/notifications";
import messages from "../../../utils/message";

const Header = (props: { disableButton: boolean }) => {
    const { t } = useTranslation();

    const handleReadAll = () => {
        readAllNotifications().then(() => {
            messages.showSuccesMessage();
        }).catch((error) => {
            messages.showErrorMessage(error?.response?.data);
        })
    }

    return (
        <div className={styles.Header}>
            <Input
                style={{ maxWidth: 300 }}
                placeholder={t('activityFeed.search')!}
                suffix={<SearchOutlined />}
            />
            <Button
                icon={<ReadOutlined />}
                onClick={handleReadAll}
                disabled={props.disableButton}
            >
                {t('activityFeed.markAsAllRead')}
            </Button>
        </div>
    )
}

export default Header;