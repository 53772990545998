import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Badge, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import AddDocumentModal from '../../../modals/AddDocumentModal';
import AddNoteModal from '../../../modals/AddNoteModal';

import { submitWhistleblowerNote, whistleblowerFileUpload } from '../../../app/apis/whistleblower';

import styles from '../index.module.scss';
import messages from '../../../utils/message';

const Header = () => {
	const { t } = useTranslation();

	const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false);
	const [isNotesModalOpen, setNotesModalOpen] = useState<boolean>(false);

	const statusString = window.localStorage.getItem('status');
	const status = JSON.parse(statusString!);

	const handleSubmitNote = async (payload: {}) => {
		submitWhistleblowerNote(payload).then(() => {
			messages.showSuccesMessage();
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	const handleSubmitDocument = (payload: []) => {
		payload.map(async (file: { originFileObj: File }) => {
			const data = new FormData();
			data.append('file', file.originFileObj);
			whistleblowerFileUpload(data).then(() => {
				messages.showSuccesMessage();
			}).catch((error) => {
				messages.showErrorMessage(error?.response?.data);
			})
		})
	}

	return (
		<>
			<div className={styles.DetailHeader}>
				<div className={styles.NavigationButtons}></div>
				<div className={styles.Details}>
					<div className={styles.Buttons}>
						<Space size="large">
							<Space>
								<Button
									icon={<PlusOutlined />}
									onClick={() => setNotesModalOpen(true)}
								>
									{t('incident.addNote')}
								</Button>
							</Space>
							<Space>
								<Button
									icon={<PlusOutlined />}
									onClick={() => setDocumentModalOpen(true)}
								>
									{t('incident.addDocument')}
								</Button>
							</Space>
						</Space>
					</div>
					<div className={styles.OtherDetails}>
						<Space size="large">
							<Space>
								<div>{t('incident.currentStatus')}</div>
								<Badge status={status?.colour} text={status?.name || '-'} />
							</Space>
						</Space>
					</div>
				</div>
			</div>
			<AddDocumentModal
				isOpen={isDocumentModalOpen}
				setOpen={(value: boolean) => setDocumentModalOpen(value)}
				onSubmit={handleSubmitDocument}
			/>
			<AddNoteModal
				isOpen={isNotesModalOpen}
				setOpen={(value: boolean) => setNotesModalOpen(value)}
				onSubmit={handleSubmitNote}
			/>
		</>

	);
};

export default Header;
