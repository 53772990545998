import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "antd";

import styles from '../index.module.scss';

const ReviewReportInfo = () => {
    const { t } = useTranslation();

    return (
        <Card className={styles.CustomCard}>
            <p className={styles.InfoTitle} >{t('kpmgForm.reviewReport.title')}</p>
            <p style={{ margin: 0 }} >{t('kpmgForm.reviewReport.p1')}</p>
        </Card>
    )
}

export default ReviewReportInfo;