import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

import styles from './index.module.scss';

const OTHER_OPTION_ID = -1;

interface Option {
    label: string;
    value: number;
    additionalExplanation?: string;
}

interface QuestionProps {
    question: {
        slug: string;
        type: number;
        content: string;
        additionalExplanation?: string;
        isMandatory: boolean;
        options?: Option[];
    };
    disabled?: boolean;
}

const Question: React.FC<QuestionProps> = ({ question, disabled = false }) => {
    const { t } = useTranslation();

    const form = Form.useFormInstance();

    const OtherOption = () => {
        const currentSlug = question.slug;
        const formValues = form.getFieldsValue();
        const currentSlugValue = formValues[currentSlug];

        const [selectedOption, setSelectedOption] = useState<number | null>(currentSlugValue);

        const handleOptionSelect = (value: number) => {
            setSelectedOption(value);
        };

        useEffect(() => {
            if (currentSlugValue) {
                setSelectedOption(currentSlugValue);
            }
        }, [currentSlugValue]);

        return (
            <span>
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Select
                        value={selectedOption}
                        style={{ width: '100%' }}
                        onSelect={handleOptionSelect}
                    >
                        <Select.Option value={0}>{t('form.pleaseSelect')}</Select.Option>
                        {question.options?.map((option, _) => (
                            <Select.Option key={option.label} value={option.value}>
                                <p style={{ margin: 0, whiteSpace: 'break-spaces' }}>{option.label}</p>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {selectedOption === -1 && (
                    <Form.Item name={`${currentSlug}description`} rules={[{ required: true, message: t('errors.required')! }]}>
                        <Input type="text" style={{ marginTop: '5px' }} autoFocus />
                    </Form.Item>
                )}
            </span>
        );
    };

    const [isOpen, setIsOpen] = useState<Record<number, boolean>>({});

    switch (question.type) {
        case 0:
            let opts: Option[] = [];
            if (question?.slug === 'locationcountry' || question?.slug === 'incidentcountry') {
                opts = question?.options?.sort((a, b) => a.label.localeCompare(b.label))!;
            } else {
                opts = question?.options || [];
            }
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Select
                        showSearch={question?.slug === 'locationcountry' || question?.slug === 'incidentcountry'}
                        style={{ width: '100%' }}
                    >
                        <Select.Option value={0}>{t('form.pleaseSelect')}</Select.Option>
                        {opts.map((option) => (
                            <Select.Option key={option.label} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            );

        case 1:
            return null;

        case 2:
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Input />
                </Form.Item >
            );

        case 3:
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    key={question.slug}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Form.List name={question.slug}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <Row align="middle" key={field.key} gutter={[12, 12]} style={{ width: '100%' }}>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                label={t('form.name')}
                                                name={[field.name, 'name']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing name',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                label={t('form.lastName')}
                                                name={[field.name, 'lastName']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing last name',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                {...field}
                                                label={t('form.position')}
                                                name={[field.name, 'position']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing position',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        {!disabled && (
                                            <Col span={3}>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Col>
                                        )}
                                    </Row>
                                ))}
                                {!disabled && (
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            {t('form.addPerson')}
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>
                </Form.Item>
            );

        case 4:
            return (
                <>
                    {question.options?.findIndex((o) => o.value === OTHER_OPTION_ID)! > -1 ? (
                        <OtherOption />
                    ) : (
                        <Form.Item
                            className={styles.Question}
                            extra={question.additionalExplanation}
                            label={question.content}
                            name={question.slug}
                            rules={[{ required: question.isMandatory }]}
                        >
                            <Select style={{ width: '100%' }}>
                                <Select.Option value={0}>{t('form.pleaseSelect')}</Select.Option>
                                {question.options?.map((option, _) => (
                                    <Select.Option key={option.label} value={option.value}>
                                        <p style={{ margin: 0, whiteSpace: 'break-spaces' }}>{option.label}</p>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </>
            );

        case 5:
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Radio.Group>
                        <Row gutter={[12, 12]}>
                            {question.options?.map((r, index) => (
                                <Col key={index} span={24}>
                                    <div
                                        className={styles.SelectionQuestion}
                                        onClick={() => {
                                            if (!disabled) form.setFieldValue(question.slug, r.value);
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <div className={styles.Radio}>
                                                <Radio checked={form.getFieldValue(question.slug) === r.value} value={r.value} />
                                            </div>
                                            <div className={styles.Label}>{r.label}</div>
                                            {r.additionalExplanation && (
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setIsOpen((prevState) => ({ ...prevState, [index]: !prevState[index] }));
                                                    }}
                                                >
                                                    {t('form.details')}
                                                    {isOpen[index] ? (
                                                        <UpOutlined style={{ marginLeft: '5px' }} />
                                                    ) : (
                                                        <DownOutlined style={{ marginLeft: '5px' }} />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {isOpen[index] && <div className={styles.Description}>{r.additionalExplanation}</div>}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>
                </Form.Item>
            );

        case 6:
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    key={question.slug}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <TextArea rows={6} />
                </Form.Item>
            );

        case 7:
            return (
                <Form.Item
                    className={styles.Question}
                    extra={question.additionalExplanation}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isMandatory }]}
                >
                    <Checkbox.Group>
                        <Row gutter={[12, 12]}>
                            {question.options?.map((r, index) => (
                                <Col key={index} span={8}>
                                    <div className={styles.SelectionQuestion}>
                                        <div className={styles.Checkbox}>
                                            <Checkbox
                                                checked={form.getFieldValue(question.slug)?.includes(r.value)}
                                                value={r.value}
                                            >
                                                <div className={styles.Label}>{r.label}</div>
                                            </Checkbox>
                                        </div>
                                        {r.additionalExplanation && <div className={styles.Description}>{r.additionalExplanation}</div>}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            );

        default:
            return null;
    }
};

export default Question;