import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, Input } from "antd";

const Done = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: '100%', maxWidth: 500, margin: "auto", marginBottom: '3em' }}>
            <Alert
                message={t('kpmgForm.success.p1')}
                type="success"
                showIcon
            />
            <p>{t('kpmgForm.success.info')}</p>
            <Form>
                <Form.Item
                    name="reportKey"
                    label={t('kpmgForm.success.reportKey')}
                    style={{ marginBottom: '4em' }}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('kpmgForm.success.password')}
                    style={{ marginBottom: '4em' }}
                >
                    <Input disabled />
                </Form.Item>
            </Form>
        </div>
    )
}

export default Done;