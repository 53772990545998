import React from "react";

const LinkText = (props: { to?: string, title?: string, children?: any, target?: string }) => {
    const { to, title, children, target = '_blank' } = props;

    return (
        <a href={to} target={target} rel="noreferrer" title={title}>
            {children}
        </a>
    );
};

export default LinkText;