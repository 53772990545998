import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

import { Alert, Divider, Tag } from "antd";

import BasePage from "../BasePage";
import Header from "./components/Header";
import FeedItem from "./components/FeedItem";

import { ActivityFeedData } from "../../app/interface";
import { useNotifications } from "./hooks";
import { getSession } from "../../helpers/session";

import styles from './index.module.scss';

const ActivityFeed = () => {
    const { t } = useTranslation();
    const session = getSession();
    const [refetch, setRefetch] = useState<boolean>(false);
    const data = useNotifications(refetch);
    const [groupedData, setGroupedData] = useState<ActivityFeedData[][]>(groupByDate(data?.data?.results || []));

    useEffect(() => {
        const ws = new WebSocket(`wss://ethics-stage.sanctiontrace.com/ws/users/${session.user?.id}/`);

        ws.onopen = () => {
            ws.send(session.socketToken);
        };

        ws.addEventListener("message", () => {
            setRefetch((refetch) => !refetch);
        });
    }, []);


    function groupByDate(arr: ActivityFeedData[]): ActivityFeedData[][] {
        const groupedObj = Object.entries(
            arr.reduce((groups: { [key: string]: ActivityFeedData[] }, item) => {
                const date = item.createdDate;
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(item);
                return groups;
            }, {})
        );

        return groupedObj.map(([_, value]) => value);
    }

    useEffect(() => {
        const newData = groupByDate(data.data?.results || []);
        setGroupedData(newData);
    }, [data.data?.results?.length]);

    return (
        <BasePage
            content={
                <div>
                    <Header disableButton={groupedData?.length === 0} />
                    <Divider />
                    <>
                        {groupedData?.length === 0 ?
                            (
                                <div className={styles.Alert} >
                                    <Alert message={t('activityFeed.noNotification')} type="info" showIcon />
                                </div>
                            )
                            : (

                                groupedData?.map((group, index) => (
                                    <div key={index} className={styles.FeedItem}>
                                        <div key={index} className={styles.FeedItem} >
                                            <Tag color="#0042EF">{dayjs(group[0].createdDate).format('DD/MM/YYYY HH:MM')}</Tag>
                                            {
                                                group?.map((item: ActivityFeedData) => (
                                                    <FeedItem item={item} />
                                                ))
                                            }
                                        </div>
                                        <Divider />
                                    </div>
                                )))

                        }
                    </>
                </div >
            }
            header={null}
            isTop
        />
    )
}

export default ActivityFeed;