import apiClient from "../api";
import { IncidentNoteData } from "../interface";

const BASE_URL = `${process.env.REACT_APP_API_URL}/incidents`;

export const getIncidents = (page?: number, page_size?: number) => {
    const params = {
        page: page,
        page_size: page_size
    };
    return apiClient.get(`${BASE_URL}/`, { params });
}

export const getIncidentDetail = (id: string) => {
    return apiClient.get(`${BASE_URL}/${id}/`);
}

export const updateIncident = (id: string, data: {}) => {
    return apiClient.patch(`${BASE_URL}/${id}/`, data);
}

export const deleteInciden = (incidentId: string, token: string) => {
    return apiClient.delete(`${BASE_URL}/${incidentId}/`, { headers: { Authorization: `Token ${token}` } });
}

export const getIncidentFiles = (incidentId: string, page?: number, page_size?: number) => {
    const params = {
        page: page,
        page_size: page_size
    };
    return apiClient.get(`${BASE_URL}/${incidentId}/files/`, { params });
}

export const downloadIncidentFiles = (incidentId: string, fileId: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/files/${fileId}/download/`, { responseType: "blob" });
}

export const uploadIncidentFile = (incidentId: string, file: any) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/files/upload/`, file, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export const getIncidentLogs = (incidentId: string, page?: number, page_size?: number) => {
    const params = {
        page: page,
        page_size: page_size
    };
    return apiClient.get(`${BASE_URL}/${incidentId}/logs/`, { params });
}

export const getIncidentLogDetail = (incidentId: string, logId: string, token: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/logs/${logId}`, { headers: { Authorization: `Token ${token}` } });
}

export const getIncidentNotes = (incidentId: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/notes/`);
}

export const getIncidentNoteDetail = (incidentId: string, noteId: string, token: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/notes/${noteId}/`, { headers: { Authorization: `Token ${token}` } });
}

export const uploadIncidentNote = (incidentId: string, data: any) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/notes/`, data);
}

export const updateIncidentNote = (incidentId: string, noteId: string, data: IncidentNoteData, token: string) => {
    return apiClient.patch(`${BASE_URL}/${incidentId}/notes/${noteId}/`, data, { headers: { Authorization: `Token ${token}` } });
}

export const deleteIncidentNote = (incidentId: string, noteId: string, token: string) => {
    return apiClient.delete(`${BASE_URL}/${incidentId}/notes/${noteId}/`, { headers: { Authorization: `Token ${token}` } });
}

export const getIncidentQuestions = (incidentId: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/questions/`);
}

export const sendIncidentQuestion = (incidentId: string, data: any) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/questions/`, data);
}

export const uploadIncidentQuestionFiles = (incidentId: string, questionId: string, data: any) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/questions/${questionId}/files/upload/`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export const resolveIncident = (incidentId: string, data: any) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/resolve/`, data);
}

export const getIncidentUsers = (incidentId: string) => {
    const params = {
        page: 1,
        page_size: 999
    };
    return apiClient.get(`${BASE_URL}/${incidentId}/users/`, { params });
}

export const getIncidentUser = (incidentId: string, userId: string) => {
    return apiClient.get(`${BASE_URL}/${incidentId}/users/${userId}`);
}


export const getIncidentAuthorities = (incidentId: string) => {
    const params = {
        page: 1,
        page_size: 999
    };
    return apiClient.get(`${BASE_URL}/${incidentId}/authorities/`, { params });
}

export const updateIncidentAuthorities = (incidentId: string, data: {}) => {
    return apiClient.post(`${BASE_URL}/${incidentId}/authorities/`, data);
}

export const removeIncidentAuthorities = (incidentId: string, authorityId: number) => {
    return apiClient.delete(`${BASE_URL}/${incidentId}/authorities/${authorityId}`);
}