import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Menu } from 'antd';
import type { MenuProps } from 'antd';

import routes, { RouteData } from '../../../routes';

type MenuItem = Required<MenuProps>['items'][number];

const SideMenu = (props: { isAdmin: boolean }) => {
	const { t } = useTranslation();

	const getMenuItem = (route: RouteData): MenuItem => {
		return {
			children: route.children?.filter((routeChild) => routeChild.showInSideMenu).map((routeChild) => getMenuItem(routeChild)),
			icon: route.icon,
			label: <Link to={route.path}>{t(`routes.${route.label}`)}</Link>,
			key: route.path
		} as MenuItem;
	};

	return (
		<Menu
			theme="light"
			defaultSelectedKeys={['/']}
			mode="inline"
			items={routes.filter((route) => route.showInSideMenu && route.isAdmin === props.isAdmin).map((route) => getMenuItem(route))}
		/>
	);
};

export default SideMenu;
