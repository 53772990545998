import React from 'react';

import { DatePicker, Input, Select } from 'antd';

import { FilterData } from '../../../app/interface';

const Filter = (props: FilterData) => {
	switch (props.type) {
		case 'date-range': {
			return <DatePicker.RangePicker placeholder={props.placeholder} style={props.style} />;
		}

		case 'dropdown': {
			return <Select options={props.options} placeholder={props.placeholder} style={props.style} />;
		}

		case 'input': {
			return <Input placeholder={props.placeholder} style={props.style} />;
		}
	}
};

export default Filter;
