import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Col, Row, Button } from "antd";

import { createIncident } from "../../app/apis/whistleblower";
import { useAppDispatch } from "../../app/hooks";
import { setReportKey } from "../../app/slices/userSlice";
import i18n from "../../app/i18n";

import styles from './index.module.scss';

const CompanyHeader = (props: { type: number }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onStartClick = () => {
        createIncident()
            .then((response) => {
                dispatch(setReportKey(response.data.id));
                navigate('/form');
            })
            .catch((error) => { });
    };

    switch (props.type) {
        case 1:
            return (
                <>
                    <div style={{ background: '#fff' }}>
                        <Row align="middle" className={styles.CompanyHeaderTemsa} >
                            <Col flex='auto'>
                                <img src="/static/images/temsa-logo.png" alt='logo' width="auto" height="48" />
                            </Col>
                            <Col flex='auto' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Row gutter={[12, 12]}>
                                    <Button
                                        type='text'
                                        target='_blank'
                                        href={i18n.language === 'tr' ?
                                            `${process.env.REACT_APP_FILE_URL}TR-Temsa-Genel-Davranış-Kuralları.pdf` :
                                            i18n.language === 'fr' ?
                                                `${process.env.REACT_APP_FILE_URL}FR-Temsa-Genel Davranış-Kuralları.pdf` :
                                                i18n.language === 'de' &&
                                                `${process.env.REACT_APP_FILE_URL}DE-Temsa-Genel-Davranış-Kuralları.pdf` ||
                                                `${process.env.REACT_APP_FILE_URL}EN-Temsa-Genel-Davranış-Kuralları.pdf`
                                        }
                                    >
                                        {t('index.generalCodeofConduct')}
                                    </Button>
                                    <Button type='text' onClick={() => navigate('/faq')}>
                                        {t('index.faq')}
                                    </Button>
                                    <Button type='text' onClick={onStartClick}>
                                        {t('index.startIncident')}
                                    </Button>
                                    <Button type='text' onClick={() => navigate('/login')}>
                                        {t('index.followUpPage')}
                                    </Button>
                                </Row>
                            </Col>
                        </Row >
                    </div >
                    <div style={{ flexGrow: 1, background: '#001529', height: 20 }} />
                </>
            )
        case 2:
            return (
                <div style={{ background: '#fff' }}>
                    <Row align="middle" className={styles.CompanyHeaderKPMG} >
                        <Col flex='auto'>
                            <img src="/static/images/kpmg-logo.png" alt='logo' width="auto" height="48" />
                        </Col>
                    </Row>
                </div>
            )
        default:
            return null;
    }

};

export default CompanyHeader;