import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Col, Form, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import AddNoteModal from '../../../modals/AddNoteModal';
import AddDocumentModal from '../../../modals/AddDocumentModal';
import WhistleblowerQuestionModal from '../../../modals/WhistleblowerQuestionModal';

import { IncidentData, SimpleOrganizationUserData } from '../../../app/interface';
import { removeIncidentAuthorities, sendIncidentQuestion, updateIncident, updateIncidentAuthorities, uploadIncidentFile, uploadIncidentNote, uploadIncidentQuestionFiles } from '../../../app/apis/incidents';
import formatDate from '../../../utils/formatDate';
import { useCategories } from '../../../hooks/useCategories';
import { usePossibleUsers } from '../hooks';
import { getCategory } from '../../../app/apis/categories';
import messages from '../../../utils/message';

import styles from '../index.module.scss';
import { useIncidentAuthorities } from '../hooks';

const IncidentOverview = (props: { incident: IncidentData, onUpdatePriority: (value: number) => void }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { incidentId } = useParams();
	const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false);
	const [isNotesModalOpen, setNotesModalOpen] = useState<boolean>(false);
	const [isQuestionToWhistleblowerModalOpen, setQuestionToWhistleblowerModalOpen] = useState<boolean>(false);
	const [toggle, setToggle] = useState<boolean>(false);

	const possibleAuthoritiesQuery = usePossibleUsers(incidentId!);
	const authorizedUsers = useIncidentAuthorities(incidentId!, toggle);
	const categories = useCategories();

	const [updatedCategory, setUpdatedCategory] = useState<{ id: number, name: string }>();
	const [authorityUsersState, setAuthorityUsersState] = useState<Array<number>>([]);

	function findDiff(arr1: number[], arr2: number[]): number | undefined {
		const missingValues = arr1.filter((id) => !arr2.includes(id))
		return missingValues.length > 0 ? missingValues[0] : undefined;
	}

	const handleSubmitNote = async (payload: {}) => {
		uploadIncidentNote(incidentId!, payload).then(() => {
			messages.showSuccesMessage();
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	const handleSubmitDocument = (payload: []) => {
		payload.map(async (file: { originFileObj: File }) => {
			const data = new FormData();
			data.append('file', file.originFileObj);
			uploadIncidentFile(incidentId!, data).then(() => {
				messages.showSuccesMessage();
			}).catch((error) => {
				messages.showErrorMessage(error?.response?.data);
			})
		})
	}

	const handleSubmitQuestionDocument = (questionId: string, payload: []) => {
		payload.map(async (file: { originFileObj: File }) => {
			const data = new FormData();
			data.append('file', file.originFileObj);
			await uploadIncidentQuestionFiles(incidentId!, questionId!, data)
		})
	}

	const handleSubmitQuestion = (payload: { content: string, files: [] }) => {
		sendIncidentQuestion(incidentId!, payload).then((response) => {
			const questionId = response.data?.id;
			handleSubmitQuestionDocument(questionId, payload.files);
			messages.showSuccesMessage();
			setQuestionToWhistleblowerModalOpen(false);
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	const handleCategoryChange = (value: number) => {
		const data = {
			category_id: value
		};
		updateIncident(incidentId!, data).then(() => {
			messages.showSuccesMessage(t('messages.categoryUpdateSuccess')!);
			getCategory(value).then((response) => {
				setUpdatedCategory({
					id: value,
					name: response.data.name
				})
			})
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	const handlePriorityChange = (value: any) => {
		const data = {
			priority: value
		};
		updateIncident(incidentId!, data).then(() => {
			messages.showSuccesMessage(t('messages.priorityUpdateSuccess')!);
			props.onUpdatePriority(value);
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	const handleAuthorityChange = (value: Array<number>) => {
		if (value.length > authorityUsersState.length) {
			const newUserId = findDiff(value, authorityUsersState);
			updateIncidentAuthorities(incidentId!, { userId: newUserId }).then(() => {
				setToggle((toggle) => !toggle);
				messages.showSuccesMessage();
			}).catch((error) => {
				const errors = Object.values(error?.response?.data);
				errors?.forEach((message: any) => {
					messages.showErrorMessage(message)
				})
			})

		} else if (authorityUsersState.length > value.length) {
			const deletedUserId = findDiff(authorityUsersState, value);
			const deletedAuthId = authorizedUsers?.data?.results?.find((result: { id: number, user: { id: number } }) => result?.user?.id === deletedUserId).id;
			removeIncidentAuthorities(incidentId!, deletedAuthId!).then(() => {
				setToggle((toggle) => !toggle);
				messages.showSuccesMessage();
			}).catch((error) => {
				const errors = Object.values(error?.response?.data);
				errors?.forEach((message: any) => {
					messages.showErrorMessage(message)
				})
			})
		}
	}

	useEffect(() => {
		setAuthorityUsersState(authorizedUsers?.data?.results?.map((result: { user: { id: number } }) => result?.user?.id))
	}, [authorizedUsers?.data?.results]);

	return (
		<div className={styles.Tabs}>
			<div className={styles.Overview}>
				<Form labelAlign="left" labelCol={{ xs: 12, md: 6 }} >
					<Form.Item label={t('incident.details.overview.company')}>{props.incident?.company?.name}</Form.Item>
					<Form.Item label={t('incident.details.overview.country')}>{props.incident?.incidentcountry}</Form.Item>
					<Form.Item label={t('incident.details.overview.city')}>{props.incident.incidentcity}</Form.Item>
					<Form.Item label={t('incident.details.overview.locationcountry')}>{props.incident?.locationcountry}</Form.Item>
					<Form.Item label={t('incident.details.overview.category')}>{updatedCategory ? updatedCategory.name : props.incident?.category?.name}</Form.Item>
					<Form.Item label={t('incident.details.overview.createdDate')}>{formatDate(props.incident.createdDate)}</Form.Item>
					<Form.Item label={t('incident.details.overview.dueDate')}>{props.incident.resolution?.createdDate ? formatDate(props.incident.resolution?.createdDate) : '-'}</Form.Item>
				</Form>
				<Row className={styles.Buttons} gutter={[16, 16]}>
					<Col md={6} xs={12}>
						<Button
							className={styles.Button}
							type="primary"
							block
							onClick={() => {
								navigate(`/panel/incidents/${incidentId}/form`);
							}}
						>
							<p className={styles.ButtonLabel}>{t('incident.details.overview.seeForm')}</p>
						</Button>
					</Col>
					<Col md={6} xs={12}>
						<Button
							className={styles.Button}
							onClick={() => {
								setQuestionToWhistleblowerModalOpen(true);
							}}
							type="default"
							block
						>
							<p className={styles.ButtonLabel}>{t('incident.details.overview.questionToWhistleblower')}</p>
						</Button>
					</Col>
					<Col md={6} xs={12}>
						<Button
							block
							className={styles.Button}
							icon={<PlusOutlined />}
							onClick={() => {
								setDocumentModalOpen(true);
							}}
						>
							<p className={styles.ButtonLabel}>{t('incident.details.overview.addDocument')}</p>
						</Button>
					</Col>
					<Col md={6} xs={12}>
						<Button
							block
							className={styles.Button}
							icon={<PlusOutlined />}
							onClick={() => {
								setNotesModalOpen(true);
							}}
						>
							<p className={styles.ButtonLabel}>{t('incident.details.overview.addNote')}</p>
						</Button>
					</Col>
				</Row>
				<Form initialValues={{ category: props.incident?.category?.id, priority: props.incident.priority }} layout="vertical">
					<Row gutter={24}>
						<Col span={8}>
							<div className={styles.AssignAuthority}>
								<Form.Item label={t('incident.details.overview.assignAuthority')}>
									<Select
										showSearch
										maxTagCount={0}
										mode='multiple'
										style={{ width: '100%' }}
										placeholder={t('globals.pleaseSelect')}
										onChange={handleAuthorityChange}
										value={authorityUsersState}
										options={
											possibleAuthoritiesQuery.data != null
												? possibleAuthoritiesQuery?.data?.results?.map((authority: SimpleOrganizationUserData) => ({ label: authority.name || authority.email, value: authority.id }))
												: []
										}
										filterOption={(input, option) =>
											(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
										}
									/>
								</Form.Item>
							</div>
						</Col>
						<Col span={10}>
							<div className={styles.ChangeCategory}>
								<Form.Item label={t('incident.details.overview.changeCategory')} name="category" >
									<Select
										style={{ width: '100%' }}
										showSearch
										placeholder={t('globals.pleaseSelect')}
										onChange={handleCategoryChange}
										options={
											categories.data?.results?.map((result: { id: number, name: string }) => {
												return { value: result.id, label: result.name }
											}) || []
										}
										filterOption={(input, option) =>
											(option?.name! || option?.label).toLowerCase().includes(input.toLowerCase())
										}
									/>
								</Form.Item>
							</div>
						</Col>
						<Col span={6}>
							<div className={styles.Priority}>
								<Form.Item label={t('incident.details.overview.priority')} name="priority">
									<Select
										style={{ width: '100%' }}
										showSearch
										placeholder={t('globals.pleaseSelect')}
										onChange={handlePriorityChange}
										options={[
											{ label: t('priority.low'), value: 1 },
											{ label: t('priority.medium'), value: 2 },
											{ label: t('priority.high'), value: 3 }
										]}
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
									/>
								</Form.Item>
							</div>
						</Col>
					</Row>
				</Form>
			</div>
			<AddNoteModal
				isOpen={isNotesModalOpen}
				setOpen={(value: boolean) => setNotesModalOpen(value)}
				onSubmit={handleSubmitNote}
			/>
			<AddDocumentModal
				isOpen={isDocumentModalOpen}
				setOpen={(value: boolean) => setDocumentModalOpen(value)}
				onSubmit={handleSubmitDocument}
			/>
			<WhistleblowerQuestionModal
				isOpen={isQuestionToWhistleblowerModalOpen}
				setOpen={(value: boolean) => setQuestionToWhistleblowerModalOpen(value)}
				onSubmit={handleSubmitQuestion}
			/>

		</div>
	);
};

export default IncidentOverview;
