const temsaCompanies = {
  SKODA: 'skoda',
  NORTH: 'north',
  SAS: 'sas',
  DEUTCH: 'deutch',
  1: 'skoda',
  2: 'north',
  3: 'sas',
  4: 'deutch'
};

export default temsaCompanies;
