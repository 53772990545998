import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined, CaretRightOutlined } from '@ant-design/icons';

import { PriorityData } from "../../../app/interface";
import monitorSVG from '../assets/monitor.svg';

import styles from './index.module.scss';


const Priority = (props: { priorities: Array<PriorityData> }) => {
    const { t } = useTranslation();
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        props.priorities?.forEach((priority) => {
            setTotal((total) => total + priority.count)
        })
    }, [props.priorities]);

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '24px',
            }}
        >
            <Row gutter={[10, 16]}>
                <Col>
                    <div className={styles.Icon}>
                        <img
                            src={monitorSVG}
                            alt="svg icon"
                        />
                    </div>
                </Col>
                <Col>
                    <Row gutter={[10, 0]} style={{ marginBottom: 3, display: 'flex' }}>
                        <Col>
                            <h4 className={styles.Title}>{t('dashboard.priority.title')}</h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.priority.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 3 }}>
                        <Col className={styles.InfoText}>
                            {total}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row gutter={[12, 0]}>
                                <Col>
                                    <Row gutter={[4, 0]}>
                                        <Col>{t('dashboard.priority.high')}</Col>
                                        <Col>
                                            <CaretRightOutlined style={{ color: '#0042EF' }} />
                                        </Col>
                                        <Col>{props.priorities?.find((p) => p.id === 3)?.count}</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row gutter={[4, 0]}>
                                        <Col>{t('dashboard.priority.medium')}</Col>
                                        <Col>
                                            <CaretRightOutlined style={{ color: '#8FA9ED' }} />
                                        </Col>
                                        <Col>{props.priorities?.find((p) => p.id === 2)?.count}</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row gutter={[4, 0]}>
                                        <Col>{t('dashboard.priority.low')}</Col>
                                        <Col>
                                            <CaretRightOutlined style={{ color: '#8FA9ED' }} />
                                        </Col>
                                        <Col>{props.priorities?.find((p) => p.id === 1)?.count}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
};

export default Priority;