import React from "react";

import { Form, Input, } from 'antd';

import styles from '../index.module.scss';

interface QuestionData {
    content: string,
    id: number,
    type: number,
    slug: string,
    isRequired: boolean,
    row: number,
    options: Array<{ id: number, label: string, value: number, explanation?: string }>
}

const QuestionPreview = (props: { question: QuestionData, parentSlug?: string }) => {
    const { question, parentSlug } = props;

    const form = Form.useFormInstance();

    switch (question.type) {
        case 1:
            return (
                <Form.Item
                    className={styles.QuestionPreivew}
                    label={question.content}
                    name={question.slug || parentSlug}
                >
                    {question.row === 1 ? <Input readOnly /> : <Input.TextArea readOnly rows={question.row} />}
                </Form.Item>
            )
        case 3:
            return null
        case 4:
        case 5:
            return (
                <>
                    <Form.Item
                        className={styles.QuestionPreivew}
                        label={question.content}
                        name={question.slug || parentSlug}
                    >
                        {question.options.find((opt) => opt.value === form.getFieldValue(question.slug))?.label}
                    </Form.Item>
                </>
            )
        default:
            return null;
    }
}

export default QuestionPreview;