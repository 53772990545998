export const countries = [
    {
      id: 4,
      name: 'Afghanistan',
      alpha2: 'af',
      alpha3: 'afg',
      translations: {
        en: 'Afghanistan',
        tr: 'Afganistan',
      },
    },
    {
      id: 248,
      name: 'Åland Islands',
      alpha2: 'ax',
      alpha3: 'ala',
      translations: {
        en: 'Åland Islands',
        tr: 'Åland Adaları',
      },
    },
    {
      id: 8,
      name: 'Albania',
      alpha2: 'al',
      alpha3: 'alb',
      translations: {
        en: 'Albania',
        tr: 'Arnavutluk',
      },
    },
    {
      id: 12,
      name: 'Algeria',
      alpha2: 'dz',
      alpha3: 'dza',
      translations: {
        en: 'Algeria',
        tr: 'Cezayir',
      },
    },
    {
      id: 16,
      name: 'American Samoa',
      alpha2: 'as',
      alpha3: 'asm',
      translations: {
        en: 'American Samoa',
        tr: 'Amerikan Samoası',
      },
    },
    {
      id: 20,
      name: 'Andorra',
      alpha2: 'ad',
      alpha3: 'and',
      translations: {
        en: 'Andorra',
        tr: 'Andorra',
      },
    },
    {
      id: 24,
      name: 'Angola',
      alpha2: 'ao',
      alpha3: 'ago',
      translations: {
        en: 'Angola',
        tr: 'Angola',
      },
    },
    {
      id: 660,
      name: 'Anguilla',
      alpha2: 'ai',
      alpha3: 'aia',
      translations: {
        en: 'Anguilla',
        tr: 'Anguilla',
      },
    },
    {
      id: 10,
      name: 'Antarctica',
      alpha2: 'aq',
      alpha3: 'ata',
      translations: {
        en: 'Antarctica',
        tr: 'Antarktika',
      },
    },
    {
      id: 28,
      name: 'Antigua and Barbuda',
      alpha2: 'ag',
      alpha3: 'atg',
      translations: {
        en: 'Antigua and Barbuda',
        tr: 'Antigua ve Barbuda',
      },
    },
    {
      id: 32,
      name: 'Argentina',
      alpha2: 'ar',
      alpha3: 'arg',
      translations: {
        en: 'Argentina',
        tr: 'Arjantin',
      },
    },
    {
      id: 51,
      name: 'Armenia',
      alpha2: 'am',
      alpha3: 'arm',
      translations: {
        en: 'Armenia',
        tr: 'Ermenistan',
      },
    },
    {
      id: 533,
      name: 'Aruba',
      alpha2: 'aw',
      alpha3: 'abw',
      translations: {
        en: 'Aruba',
        tr: 'Aruba',
      },
    },
    {
      id: 36,
      name: 'Australia',
      alpha2: 'au',
      alpha3: 'aus',
      translations: {
        en: 'Australia',
        tr: 'Avustralya',
      },
    },
    {
      id: 40,
      name: 'Austria',
      alpha2: 'at',
      alpha3: 'aut',
      translations: {
        en: 'Austria',
        tr: 'Avusturya',
      },
    },
    {
      id: 31,
      name: 'Azerbaijan',
      alpha2: 'az',
      alpha3: 'aze',
      translations: {
        en: 'Azerbaijan',
        tr: 'Azerbaycan',
      },
    },
    {
      id: 44,
      name: 'Bahamas',
      alpha2: 'bs',
      alpha3: 'bhs',
      translations: {
        en: 'Bahamas',
        tr: 'Bahamalar',
      },
    },
    {
      id: 48,
      name: 'Bahrain',
      alpha2: 'bh',
      alpha3: 'bhr',
      translations: {
        en: 'Bahrain',
        tr: 'Bahreyn',
      },
    },
    {
      id: 50,
      name: 'Bangladesh',
      alpha2: 'bd',
      alpha3: 'bgd',
      translations: {
        en: 'Bangladesh',
        tr: 'Bangladeş',
      },
    },
    {
      id: 52,
      name: 'Barbados',
      alpha2: 'bb',
      alpha3: 'brb',
      translations: {
        en: 'Barbados',
        tr: 'Barbados',
      },
    },
    {
      id: 112,
      name: 'Belarus',
      alpha2: 'by',
      alpha3: 'blr',
      translations: {
        en: 'Belarus',
        tr: 'Belarus',
      },
    },
    {
      id: 56,
      name: 'Belgium',
      alpha2: 'be',
      alpha3: 'bel',
      translations: {
        en: 'Belgium',
        tr: 'Belçika',
      },
    },
    {
      id: 84,
      name: 'Belize',
      alpha2: 'bz',
      alpha3: 'blz',
      translations: {
        en: 'Belize',
        tr: 'Belize',
      },
    },
    {
      id: 204,
      name: 'Benin',
      alpha2: 'bj',
      alpha3: 'ben',
      translations: {
        en: 'Benin',
        tr: 'Benin',
      },
    },
    {
      id: 60,
      name: 'Bermuda',
      alpha2: 'bm',
      alpha3: 'bmu',
      translations: {
        en: 'Bermuda',
        tr: 'Bermuda',
      },
    },
    {
      id: 64,
      name: 'Bhutan',
      alpha2: 'bt',
      alpha3: 'btn',
      translations: {
        en: 'Bhutan',
        tr: 'Bhutan',
      },
    },
    {
      id: 68,
      name: 'Bolivia (Plurinational State of)',
      alpha2: 'bo',
      alpha3: 'bol',
      translations: {
        en: 'Bolivia (Plurinational State of)',
        tr: 'Bolivya Çokuluslu Devleti',
      },
    },
    {
      id: 535,
      name: 'Bonaire, Sint Eustatius and Saba',
      alpha2: 'bq',
      alpha3: 'bes',
      translations: {
        en: 'Bonaire, Sint Eustatius and Saba',
        tr: 'Bonaire, Sint Eustatius ve Saba',
      },
    },
    {
      id: 70,
      name: 'Bosnia and Herzegovina',
      alpha2: 'ba',
      alpha3: 'bih',
      translations: {
        en: 'Bosnia and Herzegovina',
        tr: 'Bosna-Hersek',
      },
    },
    {
      id: 72,
      name: 'Botswana',
      alpha2: 'bw',
      alpha3: 'bwa',
      translations: {
        en: 'Botswana',
        tr: 'Botsvana',
      },
    },
    {
      id: 74,
      name: 'Bouvet Island',
      alpha2: 'bv',
      alpha3: 'bvt',
      translations: {
        en: 'Bouvet Island',
        tr: 'Bouvet Adası',
      },
    },
    {
      id: 76,
      name: 'Brazil',
      alpha2: 'br',
      alpha3: 'bra',
      translations: {
        en: 'Brazil',
        tr: 'Brezilya',
      },
    },
    {
      id: 86,
      name: 'British Indian Ocean Territory',
      alpha2: 'io',
      alpha3: 'iot',
      translations: {
        en: 'British Indian Ocean Territory',
        tr: 'Britanya Hint Okyanusu Toprakları',
      },
    },
    {
      id: 96,
      name: 'Brunei Darussalam',
      alpha2: 'bn',
      alpha3: 'brn',
      translations: {
        en: 'Brunei Darussalam',
        tr: 'Brunei Krallığı',
      },
    },
    {
      id: 100,
      name: 'Bulgaria',
      alpha2: 'bg',
      alpha3: 'bgr',
      translations: {
        en: 'Bulgaria',
        tr: 'Bulgaristan',
      },
    },
    {
      id: 854,
      name: 'Burkina Faso',
      alpha2: 'bf',
      alpha3: 'bfa',
      translations: {
        en: 'Burkina Faso',
        tr: 'Burkina Faso',
      },
    },
    {
      id: 108,
      name: 'Burundi',
      alpha2: 'bi',
      alpha3: 'bdi',
      translations: {
        en: 'Burundi',
        tr: 'Burundi',
      },
    },
    {
      id: 132,
      name: 'Cabo Verde',
      alpha2: 'cv',
      alpha3: 'cpv',
      translations: {
        en: 'Cabo Verde',
        tr: 'Yeşil Burun Adaları',
      },
    },
    {
      id: 116,
      name: 'Cambodia',
      alpha2: 'kh',
      alpha3: 'khm',
      translations: {
        en: 'Cambodia',
        tr: 'Kamboçya',
      },
    },
    {
      id: 120,
      name: 'Cameroon',
      alpha2: 'cm',
      alpha3: 'cmr',
      translations: {
        en: 'Cameroon',
        tr: 'Kamerun',
      },
    },
    {
      id: 124,
      name: 'Canada',
      alpha2: 'ca',
      alpha3: 'can',
      translations: {
        en: 'Canada',
        tr: 'Kanada',
      },
    },
    {
      id: 136,
      name: 'Cayman Islands',
      alpha2: 'ky',
      alpha3: 'cym',
      translations: {
        en: 'Cayman Islands',
        tr: 'Cayman Adaları',
      },
    },
    {
      id: 140,
      name: 'Central African Republic',
      alpha2: 'cf',
      alpha3: 'caf',
      translations: {
        en: 'Central African Republic',
        tr: 'Orta Afrika Cumhuriyeti',
      },
    },
    {
      id: 148,
      name: 'Chad',
      alpha2: 'td',
      alpha3: 'tcd',
      translations: {
        en: 'Chad',
        tr: 'Çad',
      },
    },
    {
      id: 152,
      name: 'Chile',
      alpha2: 'cl',
      alpha3: 'chl',
      translations: {
        en: 'Chile',
        tr: 'Şili',
      },
    },
    {
      id: 156,
      name: 'China',
      alpha2: 'cn',
      alpha3: 'chn',
      translations: {
        en: 'China',
        tr: 'Çin',
      },
    },
    {
      id: 162,
      name: 'Christmas Island',
      alpha2: 'cx',
      alpha3: 'cxr',
      translations: {
        en: 'Christmas Island',
        tr: 'Christmas Adası',
      },
    },
    {
      id: 166,
      name: 'Cocos (Keeling) Islands',
      alpha2: 'cc',
      alpha3: 'cck',
      translations: {
        en: 'Cocos (Keeling) Islands',
        tr: 'Cocos (Keeling) Adaları',
      },
    },
    {
      id: 170,
      name: 'Colombia',
      alpha2: 'co',
      alpha3: 'col',
      translations: {
        en: 'Colombia',
        tr: 'Kolombiya',
      },
    },
    {
      id: 174,
      name: 'Comoros',
      alpha2: 'km',
      alpha3: 'com',
      translations: {
        en: 'Comoros',
        tr: 'Komorlar',
      },
    },
    {
      id: 178,
      name: 'Congo',
      alpha2: 'cg',
      alpha3: 'cog',
      translations: {
        en: 'Congo',
        tr: 'Kongo',
      },
    },
    {
      id: 180,
      name: 'Congo, Democratic Republic of the',
      alpha2: 'cd',
      alpha3: 'cod',
      translations: {
        en: 'Congo, Democratic Republic of the',
        tr: 'Kongo Demokratik Cumhuriyeti',
      },
    },
    {
      id: 184,
      name: 'Cook Islands',
      alpha2: 'ck',
      alpha3: 'cok',
      translations: {
        en: 'Cook Islands',
        tr: 'Cook Adaları',
      },
    },
    {
      id: 188,
      name: 'Costa Rica',
      alpha2: 'cr',
      alpha3: 'cri',
      translations: {
        en: 'Costa Rica',
        tr: 'Kosta Rika',
      },
    },
    {
      id: 384,
      name: "Côte d'Ivoire",
      alpha2: 'ci',
      alpha3: 'civ',
      translations: {
        en: "Côte d'Ivoire",
        tr: 'Fildişi Sahili',
      },
    },
    {
      id: 191,
      name: 'Croatia',
      alpha2: 'hr',
      alpha3: 'hrv',
      translations: {
        en: 'Croatia',
        tr: 'Hırvatistan',
      },
    },
    {
      id: 192,
      name: 'Cuba',
      alpha2: 'cu',
      alpha3: 'cub',
      translations: {
        en: 'Cuba',
        tr: 'Küba',
      },
    },
    {
      id: 531,
      name: 'Curaçao',
      alpha2: 'cw',
      alpha3: 'cuw',
      translations: {
        en: 'Curaçao',
        tr: 'Curaçao',
      },
    },
    {
      id: 196,
      name: 'Cyprus',
      alpha2: 'cy',
      alpha3: 'cyp',
      translations: {
        en: 'Cyprus',
        tr: 'Kıbrıs',
      },
    },
    {
      id: 203,
      name: 'Czechia',
      alpha2: 'cz',
      alpha3: 'cze',
      translations: {
        en: 'Czechia',
        tr: 'Çekya',
      },
    },
    {
      id: 208,
      name: 'Denmark',
      alpha2: 'dk',
      alpha3: 'dnk',
      translations: {
        en: 'Denmark',
        tr: 'Danimarka',
      },
    },
    {
      id: 262,
      name: 'Djibouti',
      alpha2: 'dj',
      alpha3: 'dji',
      translations: {
        en: 'Djibouti',
        tr: 'Cibuti',
      },
    },
    {
      id: 212,
      name: 'Dominica',
      alpha2: 'dm',
      alpha3: 'dma',
      translations: {
        en: 'Dominica',
        tr: 'Dominika',
      },
    },
    {
      id: 214,
      name: 'Dominican Republic',
      alpha2: 'do',
      alpha3: 'dom',
      translations: {
        en: 'Dominican Republic',
        tr: 'Dominik Cumhuriyeti',
      },
    },
    {
      id: 218,
      name: 'Ecuador',
      alpha2: 'ec',
      alpha3: 'ecu',
      translations: {
        en: 'Ecuador',
        tr: 'Ekvador',
      },
    },
    {
      id: 818,
      name: 'Egypt',
      alpha2: 'eg',
      alpha3: 'egy',
      translations: {
        en: 'Egypt',
        tr: 'Mısır',
      },
    },
    {
      id: 222,
      name: 'El Salvador',
      alpha2: 'sv',
      alpha3: 'slv',
      translations: {
        en: 'El Salvador',
        tr: 'El Salvador',
      },
    },
    {
      id: 226,
      name: 'Equatorial Guinea',
      alpha2: 'gq',
      alpha3: 'gnq',
      translations: {
        en: 'Equatorial Guinea',
        tr: 'Ekvator Ginesi',
      },
    },
    {
      id: 232,
      name: 'Eritrea',
      alpha2: 'er',
      alpha3: 'eri',
      translations: {
        en: 'Eritrea',
        tr: 'Eritre',
      },
    },
    {
      id: 233,
      name: 'Estonia',
      alpha2: 'ee',
      alpha3: 'est',
      translations: {
        en: 'Estonia',
        tr: 'Estonya',
      },
    },
    {
      id: 748,
      name: 'Eswatini',
      alpha2: 'sz',
      alpha3: 'swz',
      translations: {
        en: 'Eswatini',
        tr: 'Eswatini',
      },
    },
    {
      id: 231,
      name: 'Ethiopia',
      alpha2: 'et',
      alpha3: 'eth',
      translations: {
        en: 'Ethiopia',
        tr: 'Etiyopya',
      },
    },
    {
      id: 238,
      name: 'Falkland Islands (Malvinas)',
      alpha2: 'fk',
      alpha3: 'flk',
      translations: {
        en: 'Falkland Islands (Malvinas)',
        tr: 'Falkland Adaları (Malvinas)',
      },
    },
    {
      id: 234,
      name: 'Faroe Islands',
      alpha2: 'fo',
      alpha3: 'fro',
      translations: {
        en: 'Faroe Islands',
        tr: 'Faroe Adaları',
      },
    },
    {
      id: 242,
      name: 'Fiji',
      alpha2: 'fj',
      alpha3: 'fji',
      translations: {
        en: 'Fiji',
        tr: 'Fiji',
      },
    },
    {
      id: 246,
      name: 'Finland',
      alpha2: 'fi',
      alpha3: 'fin',
      translations: {
        en: 'Finland',
        tr: 'Finlandiya',
      },
    },
    {
      id: 250,
      name: 'France',
      alpha2: 'fr',
      alpha3: 'fra',
      translations: {
        en: 'France',
        tr: 'Fransa',
      },
    },
    {
      id: 254,
      name: 'French Guiana',
      alpha2: 'gf',
      alpha3: 'guf',
      translations: {
        en: 'French Guiana',
        tr: 'Fransız Guyanası',
      },
    },
    {
      id: 258,
      name: 'French Polynesia',
      alpha2: 'pf',
      alpha3: 'pyf',
      translations: {
        en: 'French Polynesia',
        tr: 'Fransız Polinezyası',
      },
    },
    {
      id: 260,
      name: 'French Southern Territories',
      alpha2: 'tf',
      alpha3: 'atf',
      translations: {
        en: 'French Southern Territories',
        tr: 'Fransız Güney Bölgeleri',
      },
    },
    {
      id: 266,
      name: 'Gabon',
      alpha2: 'ga',
      alpha3: 'gab',
      translations: {
        en: 'Gabon',
        tr: 'Gabon',
      },
    },
    {
      id: 270,
      name: 'Gambia',
      alpha2: 'gm',
      alpha3: 'gmb',
      translations: {
        en: 'Gambia',
        tr: 'Gambiya',
      },
    },
    {
      id: 268,
      name: 'Georgia',
      alpha2: 'ge',
      alpha3: 'geo',
      translations: {
        en: 'Georgia',
        tr: 'Gürcistan',
      },
    },
    {
      id: 276,
      name: 'Germany',
      alpha2: 'de',
      alpha3: 'deu',
      translations: {
        en: 'Germany',
        tr: 'Almanya',
      },
    },
    {
      id: 288,
      name: 'Ghana',
      alpha2: 'gh',
      alpha3: 'gha',
      translations: {
        en: 'Ghana',
        tr: 'Gana',
      },
    },
    {
      id: 292,
      name: 'Gibraltar',
      alpha2: 'gi',
      alpha3: 'gib',
      translations: {
        en: 'Gibraltar',
        tr: 'Cebelitarık',
      },
    },
    {
      id: 300,
      name: 'Greece',
      alpha2: 'gr',
      alpha3: 'grc',
      translations: {
        en: 'Greece',
        tr: 'Yunanistan',
      },
    },
    {
      id: 304,
      name: 'Greenland',
      alpha2: 'gl',
      alpha3: 'grl',
      translations: {
        en: 'Greenland',
        tr: 'Grönland',
      },
    },
    {
      id: 308,
      name: 'Grenada',
      alpha2: 'gd',
      alpha3: 'grd',
      translations: {
        en: 'Grenada',
        tr: 'Grenada',
      },
    },
    {
      id: 312,
      name: 'Guadeloupe',
      alpha2: 'gp',
      alpha3: 'glp',
      translations: {
        en: 'Guadeloupe',
        tr: 'Guadeloupe',
      },
    },
    {
      id: 316,
      name: 'Guam',
      alpha2: 'gu',
      alpha3: 'gum',
      translations: {
        en: 'Guam',
        tr: 'Guam',
      },
    },
    {
      id: 320,
      name: 'Guatemala',
      alpha2: 'gt',
      alpha3: 'gtm',
      translations: {
        en: 'Guatemala',
        tr: 'Guatemala',
      },
    },
    {
      id: 831,
      name: 'Guernsey',
      alpha2: 'gg',
      alpha3: 'ggy',
      translations: {
        en: 'Guernsey',
        tr: 'Guernsey',
      },
    },
    {
      id: 324,
      name: 'Guinea',
      alpha2: 'gn',
      alpha3: 'gin',
      translations: {
        en: 'Guinea',
        tr: 'Gine',
      },
    },
    {
      id: 624,
      name: 'Guinea-Bissau',
      alpha2: 'gw',
      alpha3: 'gnb',
      translations: {
        en: 'Guinea-Bissau',
        tr: 'Gine-Bissau',
      },
    },
    {
      id: 328,
      name: 'Guyana',
      alpha2: 'gy',
      alpha3: 'guy',
      translations: {
        en: 'Guyana',
        tr: 'Guyana',
      },
    },
    {
      id: 332,
      name: 'Haiti',
      alpha2: 'ht',
      alpha3: 'hti',
      translations: {
        en: 'Haiti',
        tr: 'Haiti',
      },
    },
    {
      id: 334,
      name: 'Heard Island and McDonald Islands',
      alpha2: 'hm',
      alpha3: 'hmd',
      translations: {
        en: 'Heard Island and McDonald Islands',
        tr: 'Heard Adası ve McDonald Adaları',
      },
    },
    {
      id: 336,
      name: 'Holy See',
      alpha2: 'va',
      alpha3: 'vat',
      translations: {
        en: 'Holy See',
        tr: 'Holy See (Vatikan Şehir Devleti)',
      },
    },
    {
      id: 340,
      name: 'Honduras',
      alpha2: 'hn',
      alpha3: 'hnd',
      translations: {
        en: 'Honduras',
        tr: 'Honduras',
      },
    },
    {
      id: 344,
      name: 'Hong Kong',
      alpha2: 'hk',
      alpha3: 'hkg',
      translations: {
        en: 'Hong Kong',
        tr: 'Hong Kong',
      },
    },
    {
      id: 348,
      name: 'Hungary',
      alpha2: 'hu',
      alpha3: 'hun',
      translations: {
        en: 'Hungary',
        tr: 'Macaristan',
      },
    },
    {
      id: 352,
      name: 'Iceland',
      alpha2: 'is',
      alpha3: 'isl',
      translations: {
        en: 'Iceland',
        tr: 'İzlanda',
      },
    },
    {
      id: 356,
      name: 'India',
      alpha2: 'in',
      alpha3: 'ind',
      translations: {
        en: 'India',
        tr: 'Hindistan',
      },
    },
    {
      id: 360,
      name: 'Indonesia',
      alpha2: 'id',
      alpha3: 'idn',
      translations: {
        en: 'Indonesia',
        tr: 'Endonezya',
      },
    },
    {
      id: 364,
      name: 'Iran',
      alpha2: 'ir',
      alpha3: 'irn',
      translations: {
        en: 'Iran',
        tr: 'İran İslâm Cumhuriyeti',
      },
    },
    {
      id: 368,
      name: 'Iraq',
      alpha2: 'iq',
      alpha3: 'irq',
      translations: {
        en: 'Iraq',
        tr: 'Irak',
      },
    },
    {
      id: 372,
      name: 'Ireland',
      alpha2: 'ie',
      alpha3: 'irl',
      translations: {
        en: 'Ireland',
        tr: 'İrlanda',
      },
    },
    {
      id: 833,
      name: 'Isle of Man',
      alpha2: 'im',
      alpha3: 'imn',
      translations: {
        en: 'Isle of Man',
        tr: 'Man Adası',
      },
    },
    {
      id: 376,
      name: 'Israel',
      alpha2: 'il',
      alpha3: 'isr',
      translations: {
        en: 'Israel',
        tr: 'İsrail',
      },
    },
    {
      id: 380,
      name: 'Italy',
      alpha2: 'it',
      alpha3: 'ita',
      translations: {
        en: 'Italy',
        tr: 'İtalya',
      },
    },
    {
      id: 388,
      name: 'Jamaica',
      alpha2: 'jm',
      alpha3: 'jam',
      translations: {
        en: 'Jamaica',
        tr: 'Jamaika',
      },
    },
    {
      id: 392,
      name: 'Japan',
      alpha2: 'jp',
      alpha3: 'jpn',
      translations: {
        en: 'Japan',
        tr: 'Japonya',
      },
    },
    {
      id: 832,
      name: 'Jersey',
      alpha2: 'je',
      alpha3: 'jey',
      translations: {
        en: 'Jersey',
        tr: 'Jersey',
      },
    },
    {
      id: 400,
      name: 'Jordan',
      alpha2: 'jo',
      alpha3: 'jor',
      translations: {
        en: 'Jordan',
        tr: 'Ürdün',
      },
    },
    {
      id: 398,
      name: 'Kazakhstan',
      alpha2: 'kz',
      alpha3: 'kaz',
      translations: {
        en: 'Kazakhstan',
        tr: 'Kazakistan',
      },
    },
    {
      id: 404,
      name: 'Kenya',
      alpha2: 'ke',
      alpha3: 'ken',
      translations: {
        en: 'Kenya',
        tr: 'Kenya',
      },
    },
    {
      id: 296,
      name: 'Kiribati',
      alpha2: 'ki',
      alpha3: 'kir',
      translations: {
        en: 'Kiribati',
        tr: 'Kiribati',
      },
    },
    {
      id: 408,
      name: 'Korea, North',
      alpha2: 'kp',
      alpha3: 'prk',
      translations: {
        en: 'Korea, North',
        tr: 'Kore Demokratik Halk Cumhuriyeti',
      },
    },
    {
      id: 410,
      name: 'Korea, Republic of',
      alpha2: 'kr',
      alpha3: 'kor',
      translations: {
        en: 'Korea, Republic of',
        tr: 'Kore Cumhuriyeti',
      },
    },
    {
      id: 414,
      name: 'Kuwait',
      alpha2: 'kw',
      alpha3: 'kwt',
      translations: {
        en: 'Kuwait',
        tr: 'Kuveyt',
      },
    },
    {
      id: 417,
      name: 'Kyrgyzstan',
      alpha2: 'kg',
      alpha3: 'kgz',
      translations: {
        en: 'Kyrgyzstan',
        tr: 'Kırgızistan',
      },
    },
    {
      id: 418,
      name: "Lao People's Democratic Republic",
      alpha2: 'la',
      alpha3: 'lao',
      translations: {
        en: "Lao People's Democratic Republic",
        tr: 'Lao Demokratik Halk Cumhuriyeti',
      },
    },
    {
      id: 428,
      name: 'Latvia',
      alpha2: 'lv',
      alpha3: 'lva',
      translations: {
        en: 'Latvia',
        tr: 'Letonya',
      },
    },
    {
      id: 422,
      name: 'Lebanon',
      alpha2: 'lb',
      alpha3: 'lbn',
      translations: {
        en: 'Lebanon',
        tr: 'Lübnan',
      },
    },
    {
      id: 426,
      name: 'Lesotho',
      alpha2: 'ls',
      alpha3: 'lso',
      translations: {
        en: 'Lesotho',
        tr: 'Lesoto',
      },
    },
    {
      id: 430,
      name: 'Liberia',
      alpha2: 'lr',
      alpha3: 'lbr',
      translations: {
        en: 'Liberia',
        tr: 'Liberya',
      },
    },
    {
      id: 434,
      name: 'Libya',
      alpha2: 'ly',
      alpha3: 'lby',
      translations: {
        en: 'Libya',
        tr: 'Libya',
      },
    },
    {
      id: 438,
      name: 'Liechtenstein',
      alpha2: 'li',
      alpha3: 'lie',
      translations: {
        en: 'Liechtenstein',
        tr: 'Lihtenştayn',
      },
    },
    {
      id: 440,
      name: 'Lithuania',
      alpha2: 'lt',
      alpha3: 'ltu',
      translations: {
        en: 'Lithuania',
        tr: 'Litvanya',
      },
    },
    {
      id: 442,
      name: 'Luxembourg',
      alpha2: 'lu',
      alpha3: 'lux',
      translations: {
        en: 'Luxembourg',
        tr: 'Lüksemburg',
      },
    },
    {
      id: 446,
      name: 'Macao',
      alpha2: 'mo',
      alpha3: 'mac',
      translations: {
        en: 'Macao',
        tr: 'Makao',
      },
    },
    {
      id: 450,
      name: 'Madagascar',
      alpha2: 'mg',
      alpha3: 'mdg',
      translations: {
        en: 'Madagascar',
        tr: 'Madagaskar',
      },
    },
    {
      id: 454,
      name: 'Malawi',
      alpha2: 'mw',
      alpha3: 'mwi',
      translations: {
        en: 'Malawi',
        tr: 'Malavi',
      },
    },
    {
      id: 458,
      name: 'Malaysia',
      alpha2: 'my',
      alpha3: 'mys',
      translations: {
        en: 'Malaysia',
        tr: 'Malezya',
      },
    },
    {
      id: 462,
      name: 'Maldives',
      alpha2: 'mv',
      alpha3: 'mdv',
      translations: {
        en: 'Maldives',
        tr: 'Maldivler',
      },
    },
    {
      id: 466,
      name: 'Mali',
      alpha2: 'ml',
      alpha3: 'mli',
      translations: {
        en: 'Mali',
        tr: 'Mali',
      },
    },
    {
      id: 470,
      name: 'Malta',
      alpha2: 'mt',
      alpha3: 'mlt',
      translations: {
        en: 'Malta',
        tr: 'Malta',
      },
    },
    {
      id: 584,
      name: 'Marshall Islands',
      alpha2: 'mh',
      alpha3: 'mhl',
      translations: {
        en: 'Marshall Islands',
        tr: 'Marşal Adaları',
      },
    },
    {
      id: 474,
      name: 'Martinique',
      alpha2: 'mq',
      alpha3: 'mtq',
      translations: {
        en: 'Martinique',
        tr: 'Martinique',
      },
    },
    {
      id: 478,
      name: 'Mauritania',
      alpha2: 'mr',
      alpha3: 'mrt',
      translations: {
        en: 'Mauritania',
        tr: 'Moritanya',
      },
    },
    {
      id: 480,
      name: 'Mauritius',
      alpha2: 'mu',
      alpha3: 'mus',
      translations: {
        en: 'Mauritius',
        tr: 'Mauritius',
      },
    },
    {
      id: 175,
      name: 'Mayotte',
      alpha2: 'yt',
      alpha3: 'myt',
      translations: {
        en: 'Mayotte',
        tr: 'Mayotte',
      },
    },
    {
      id: 484,
      name: 'Mexico',
      alpha2: 'mx',
      alpha3: 'mex',
      translations: {
        en: 'Mexico',
        tr: 'Meksika',
      },
    },
    {
      id: 583,
      name: 'Micronesia (Federated States of)',
      alpha2: 'fm',
      alpha3: 'fsm',
      translations: {
        en: 'Micronesia (Federated States of)',
        tr: 'Mikronezya Federe Devletleri',
      },
    },
    {
      id: 498,
      name: 'Moldova, Republic of',
      alpha2: 'md',
      alpha3: 'mda',
      translations: {
        en: 'Moldova, Republic of',
        tr: 'Moldova Cumhuriyeti',
      },
    },
    {
      id: 492,
      name: 'Monaco',
      alpha2: 'mc',
      alpha3: 'mco',
      translations: {
        en: 'Monaco',
        tr: 'Monako',
      },
    },
    {
      id: 496,
      name: 'Mongolia',
      alpha2: 'mn',
      alpha3: 'mng',
      translations: {
        en: 'Mongolia',
        tr: 'Moğolistan',
      },
    },
    {
      id: 499,
      name: 'Montenegro',
      alpha2: 'me',
      alpha3: 'mne',
      translations: {
        en: 'Montenegro',
        tr: 'Karadağ',
      },
    },
    {
      id: 500,
      name: 'Montserrat',
      alpha2: 'ms',
      alpha3: 'msr',
      translations: {
        en: 'Montserrat',
        tr: 'Montserrat',
      },
    },
    {
      id: 504,
      name: 'Morocco',
      alpha2: 'ma',
      alpha3: 'mar',
      translations: {
        en: 'Morocco',
        tr: 'Fas',
      },
    },
    {
      id: 508,
      name: 'Mozambique',
      alpha2: 'mz',
      alpha3: 'moz',
      translations: {
        en: 'Mozambique',
        tr: 'Mozambik',
      },
    },
    {
      id: 104,
      name: 'Myanmar',
      alpha2: 'mm',
      alpha3: 'mmr',
      translations: {
        en: 'Myanmar',
        tr: 'Myanmar',
      },
    },
    {
      id: 516,
      name: 'Namibia',
      alpha2: 'na',
      alpha3: 'nam',
      translations: {
        en: 'Namibia',
        tr: 'Namibya',
      },
    },
    {
      id: 520,
      name: 'Nauru',
      alpha2: 'nr',
      alpha3: 'nru',
      translations: {
        en: 'Nauru',
        tr: 'Nauru',
      },
    },
    {
      id: 524,
      name: 'Nepal',
      alpha2: 'np',
      alpha3: 'npl',
      translations: {
        en: 'Nepal',
        tr: 'Nepal',
      },
    },
    {
      id: 528,
      name: 'Netherlands',
      alpha2: 'nl',
      alpha3: 'nld',
      translations: {
        en: 'Netherlands',
        tr: 'Hollanda',
      },
    },
    {
      id: 540,
      name: 'New Caledonia',
      alpha2: 'nc',
      alpha3: 'ncl',
      translations: {
        en: 'New Caledonia',
        tr: 'Yeni Kaledonya',
      },
    },
    {
      id: 554,
      name: 'New Zealand',
      alpha2: 'nz',
      alpha3: 'nzl',
      translations: {
        en: 'New Zealand',
        tr: 'Yeni Zelanda',
      },
    },
    {
      id: 558,
      name: 'Nicaragua',
      alpha2: 'ni',
      alpha3: 'nic',
      translations: {
        en: 'Nicaragua',
        tr: 'Nikaragua',
      },
    },
    {
      id: 562,
      name: 'Niger',
      alpha2: 'ne',
      alpha3: 'ner',
      translations: {
        en: 'Niger',
        tr: 'Nijer',
      },
    },
    {
      id: 566,
      name: 'Nigeria',
      alpha2: 'ng',
      alpha3: 'nga',
      translations: {
        en: 'Nigeria',
        tr: 'Nijerya',
      },
    },
    {
      id: 570,
      name: 'Niue',
      alpha2: 'nu',
      alpha3: 'niu',
      translations: {
        en: 'Niue',
        tr: 'Niue',
      },
    },
    {
      id: 574,
      name: 'Norfolk Island',
      alpha2: 'nf',
      alpha3: 'nfk',
      translations: {
        en: 'Norfolk Island',
        tr: 'Norfolk Adası',
      },
    },
    {
      id: 807,
      name: 'North Macedonia',
      alpha2: 'mk',
      alpha3: 'mkd',
      translations: {
        en: 'North Macedonia',
        tr: 'Kuzey Makedonya',
      },
    },
    {
      id: 580,
      name: 'Northern Mariana Islands',
      alpha2: 'mp',
      alpha3: 'mnp',
      translations: {
        en: 'Northern Mariana Islands',
        tr: 'Kuzey Mariana Adaları',
      },
    },
    {
      id: 578,
      name: 'Norway',
      alpha2: 'no',
      alpha3: 'nor',
      translations: {
        en: 'Norway',
        tr: 'Norveç',
      },
    },
    {
      id: 512,
      name: 'Oman',
      alpha2: 'om',
      alpha3: 'omn',
      translations: {
        en: 'Oman',
        tr: 'Umman',
      },
    },
    {
      id: 586,
      name: 'Pakistan',
      alpha2: 'pk',
      alpha3: 'pak',
      translations: {
        en: 'Pakistan',
        tr: 'Pakistan',
      },
    },
    {
      id: 585,
      name: 'Palau',
      alpha2: 'pw',
      alpha3: 'plw',
      translations: {
        en: 'Palau',
        tr: 'Palau',
      },
    },
    {
      id: 275,
      name: 'Palestine, State of',
      alpha2: 'ps',
      alpha3: 'pse',
      translations: {
        en: 'Palestine, State of',
        tr: 'Filistin Devleti',
      },
    },
    {
      id: 591,
      name: 'Panama',
      alpha2: 'pa',
      alpha3: 'pan',
      translations: {
        en: 'Panama',
        tr: 'Panama',
      },
    },
    {
      id: 598,
      name: 'Papua New Guinea',
      alpha2: 'pg',
      alpha3: 'png',
      translations: {
        en: 'Papua New Guinea',
        tr: 'Papua Yeni Gine',
      },
    },
    {
      id: 600,
      name: 'Paraguay',
      alpha2: 'py',
      alpha3: 'pry',
      translations: {
        en: 'Paraguay',
        tr: 'Paraguay',
      },
    },
    {
      id: 604,
      name: 'Peru',
      alpha2: 'pe',
      alpha3: 'per',
      translations: {
        en: 'Peru',
        tr: 'Peru',
      },
    },
    {
      id: 608,
      name: 'Philippines',
      alpha2: 'ph',
      alpha3: 'phl',
      translations: {
        en: 'Philippines',
        tr: 'Filipinler',
      },
    },
    {
      id: 612,
      name: 'Pitcairn',
      alpha2: 'pn',
      alpha3: 'pcn',
      translations: {
        en: 'Pitcairn',
        tr: 'Pitcairn',
      },
    },
    {
      id: 616,
      name: 'Poland',
      alpha2: 'pl',
      alpha3: 'pol',
      translations: {
        en: 'Poland',
        tr: 'Polonya',
      },
    },
    {
      id: 620,
      name: 'Portugal',
      alpha2: 'pt',
      alpha3: 'prt',
      translations: {
        en: 'Portugal',
        tr: 'Portekiz',
      },
    },
    {
      id: 630,
      name: 'Puerto Rico',
      alpha2: 'pr',
      alpha3: 'pri',
      translations: {
        en: 'Puerto Rico',
        tr: 'Porto Riko',
      },
    },
    {
      id: 634,
      name: 'Qatar',
      alpha2: 'qa',
      alpha3: 'qat',
      translations: {
        en: 'Qatar',
        tr: 'Katar',
      },
    },
    {
      id: 638,
      name: 'Réunion',
      alpha2: 're',
      alpha3: 'reu',
      translations: {
        en: 'Réunion',
        tr: 'Réunion',
      },
    },
    {
      id: 642,
      name: 'Romania',
      alpha2: 'ro',
      alpha3: 'rou',
      translations: {
        en: 'Romania',
        tr: 'Romanya',
      },
    },
    {
      id: 643,
      name: 'Russian Federation',
      alpha2: 'ru',
      alpha3: 'rus',
      translations: {
        en: 'Russian Federation',
        tr: 'Rusya Federasyonu',
      },
    },
    {
      id: 646,
      name: 'Rwanda',
      alpha2: 'rw',
      alpha3: 'rwa',
      translations: {
        en: 'Rwanda',
        tr: 'Ruanda',
      },
    },
    {
      id: 652,
      name: 'Saint Barthélemy',
      alpha2: 'bl',
      alpha3: 'blm',
      translations: {
        en: 'Saint Barthélemy',
        tr: 'Saint Barthélemy',
      },
    },
    {
      id: 654,
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      alpha2: 'sh',
      alpha3: 'shn',
      translations: {
        en: 'Saint Helena, Ascension and Tristan da Cunha',
        tr: 'Saint Helena, Ascension ve Tristan da Cunha',
      },
    },
    {
      id: 659,
      name: 'Saint Kitts and Nevis',
      alpha2: 'kn',
      alpha3: 'kna',
      translations: {
        en: 'Saint Kitts and Nevis',
        tr: 'Saint Kitts ve Nevis',
      },
    },
    {
      id: 662,
      name: 'Saint Lucia',
      alpha2: 'lc',
      alpha3: 'lca',
      translations: {
        en: 'Saint Lucia',
        tr: 'Saint Lucia',
      },
    },
    {
      id: 663,
      name: 'Saint Martin (French part)',
      alpha2: 'mf',
      alpha3: 'maf',
      translations: {
        en: 'Saint Martin (French part)',
        tr: 'Saint Martin (Fransız kısmı)',
      },
    },
    {
      id: 666,
      name: 'Saint Pierre and Miquelon',
      alpha2: 'pm',
      alpha3: 'spm',
      translations: {
        en: 'Saint Pierre and Miquelon',
        tr: 'Saint Pierre ve Miquelon',
      },
    },
    {
      id: 670,
      name: 'Saint Vincent and the Grenadines',
      alpha2: 'vc',
      alpha3: 'vct',
      translations: {
        en: 'Saint Vincent and the Grenadines',
        tr: 'Saint Vincent ve Grenadinler',
      },
    },
    {
      id: 882,
      name: 'Samoa',
      alpha2: 'ws',
      alpha3: 'wsm',
      translations: {
        en: 'Samoa',
        tr: 'Samoa',
      },
    },
    {
      id: 674,
      name: 'San Marino',
      alpha2: 'sm',
      alpha3: 'smr',
      translations: {
        en: 'San Marino',
        tr: 'San Marino',
      },
    },
    {
      id: 678,
      name: 'Sao Tome and Principe',
      alpha2: 'st',
      alpha3: 'stp',
      translations: {
        en: 'Sao Tome and Principe',
        tr: 'Sao Tome ve Principe',
      },
    },
    {
      id: 682,
      name: 'Saudi Arabia',
      alpha2: 'sa',
      alpha3: 'sau',
      translations: {
        en: 'Saudi Arabia',
        tr: 'Suudi Arabistan',
      },
    },
    {
      id: 686,
      name: 'Senegal',
      alpha2: 'sn',
      alpha3: 'sen',
      translations: {
        en: 'Senegal',
        tr: 'Senegal',
      },
    },
    {
      id: 688,
      name: 'Serbia',
      alpha2: 'rs',
      alpha3: 'srb',
      translations: {
        en: 'Serbia',
        tr: 'Sırbistan',
      },
    },
    {
      id: 690,
      name: 'Seychelles',
      alpha2: 'sc',
      alpha3: 'syc',
      translations: {
        en: 'Seychelles',
        tr: 'Seyşeller',
      },
    },
    {
      id: 694,
      name: 'Sierra Leone',
      alpha2: 'sl',
      alpha3: 'sle',
      translations: {
        en: 'Sierra Leone',
        tr: 'Sierra Leone',
      },
    },
    {
      id: 702,
      name: 'Singapore',
      alpha2: 'sg',
      alpha3: 'sgp',
      translations: {
        en: 'Singapore',
        tr: 'Singapur',
      },
    },
    {
      id: 534,
      name: 'Sint Maarten (Dutch part)',
      alpha2: 'sx',
      alpha3: 'sxm',
      translations: {
        en: 'Sint Maarten (Dutch part)',
        tr: 'Sint Maarten (Hollanda kısmı)',
      },
    },
    {
      id: 703,
      name: 'Slovakia',
      alpha2: 'sk',
      alpha3: 'svk',
      translations: {
        en: 'Slovakia',
        tr: 'Slovakya',
      },
    },
    {
      id: 705,
      name: 'Slovenia',
      alpha2: 'si',
      alpha3: 'svn',
      translations: {
        en: 'Slovenia',
        tr: 'Slovenya',
      },
    },
    {
      id: 90,
      name: 'Solomon Islands',
      alpha2: 'sb',
      alpha3: 'slb',
      translations: {
        en: 'Solomon Islands',
        tr: 'Solomon Adaları',
      },
    },
    {
      id: 706,
      name: 'Somalia',
      alpha2: 'so',
      alpha3: 'som',
      translations: {
        en: 'Somalia',
        tr: 'Somali',
      },
    },
    {
      id: 706,
      name: 'SomaliaLand',
      alpha2: 'xs',
      alpha3: 'som',
      translations: {
        en: 'SomaliaLand',
        tr: 'Somaliland Cumhuriyeti',
      },
    },
    {
      id: 710,
      name: 'South Africa',
      alpha2: 'za',
      alpha3: 'zaf',
      translations: {
        en: 'South Africa',
        tr: 'Güney Afrika',
      },
    },
    {
      id: 239,
      name: 'South Georgia and the South Sandwich Islands',
      alpha2: 'gs',
      alpha3: 'sgs',
      translations: {
        en: 'South Georgia and the South Sandwich Islands',
        tr: 'Güney Georgia ve Güney Sandwich Adaları',
      },
    },
    {
      id: 728,
      name: 'South Sudan',
      alpha2: 'ss',
      alpha3: 'ssd',
      translations: {
        en: 'South Sudan',
        tr: 'Güney Sudan',
      },
    },
    {
      id: 724,
      name: 'Spain',
      alpha2: 'es',
      alpha3: 'esp',
      translations: {
        en: 'Spain',
        tr: 'İspanya',
      },
    },
    {
      id: 144,
      name: 'Sri Lanka',
      alpha2: 'lk',
      alpha3: 'lka',
      translations: {
        en: 'Sri Lanka',
        tr: 'Sri Lanka',
      },
    },
    {
      id: 729,
      name: 'Sudan',
      alpha2: 'sd',
      alpha3: 'sdn',
      translations: {
        en: 'Sudan',
        tr: 'Sudan',
      },
    },
    {
      id: 740,
      name: 'Suriname',
      alpha2: 'sr',
      alpha3: 'sur',
      translations: {
        en: 'Suriname',
        tr: 'Surinam',
      },
    },
    {
      id: 744,
      name: 'Svalbard and Jan Mayen',
      alpha2: 'sj',
      alpha3: 'sjm',
      translations: {
        en: 'Svalbard and Jan Mayen',
        tr: 'Svalbard ve Jan Mayen',
      },
    },
    {
      id: 752,
      name: 'Sweden',
      alpha2: 'se',
      alpha3: 'swe',
      translations: {
        en: 'Sweden',
        tr: 'İsveç',
      },
    },
    {
      id: 756,
      name: 'Switzerland',
      alpha2: 'ch',
      alpha3: 'che',
      translations: {
        en: 'Switzerland',
        tr: 'İsviçre',
      },
    },
    {
      id: 760,
      name: 'Syrian Arab Republic',
      alpha2: 'sy',
      alpha3: 'syr',
      translations: {
        en: 'Syrian Arab Republic',
        tr: 'Suriye Arap Cumhuriyeti',
      },
    },
    {
      id: 158,
      name: 'Taiwan, Province of China',
      alpha2: 'tw',
      alpha3: 'twn',
      translations: {
        en: 'Taiwan, Province of China',
        tr: 'Tayvan, Çin Eyaleti',
      },
    },
    {
      id: 762,
      name: 'Tajikistan',
      alpha2: 'tj',
      alpha3: 'tjk',
      translations: {
        en: 'Tajikistan',
        tr: 'Tacikistan',
      },
    },
    {
      id: 834,
      name: 'Tanzania, United Republic of',
      alpha2: 'tz',
      alpha3: 'tza',
      translations: {
        en: 'Tanzania, United Republic of',
        tr: 'Tanzanya Birleşik Cumhuriyeti',
      },
    },
    {
      id: 764,
      name: 'Thailand',
      alpha2: 'th',
      alpha3: 'tha',
      translations: {
        en: 'Thailand',
        tr: 'Tayland',
      },
    },
    {
      id: 626,
      name: 'Timor-Leste',
      alpha2: 'tl',
      alpha3: 'tls',
      translations: {
        en: 'Timor-Leste',
        tr: 'Timor-Leste',
      },
    },
    {
      id: 768,
      name: 'Togo',
      alpha2: 'tg',
      alpha3: 'tgo',
      translations: {
        en: 'Togo',
        tr: 'Togo',
      },
    },
    {
      id: 772,
      name: 'Tokelau',
      alpha2: 'tk',
      alpha3: 'tkl',
      translations: {
        en: 'Tokelau',
        tr: 'Tokelau',
      },
    },
    {
      id: 776,
      name: 'Tonga',
      alpha2: 'to',
      alpha3: 'ton',
      translations: {
        en: 'Tonga',
        tr: 'Tonga',
      },
    },
    {
      id: 780,
      name: 'Trinidad and Tobago',
      alpha2: 'tt',
      alpha3: 'tto',
      translations: {
        en: 'Trinidad and Tobago',
        tr: 'Trinidad ve Tobago',
      },
    },
    {
      id: 788,
      name: 'Tunisia',
      alpha2: 'tn',
      alpha3: 'tun',
      translations: {
        en: 'Tunisia',
        tr: 'Tunus',
      },
    },
    {
      id: 792,
      name: 'Turkey',
      alpha2: 'tr',
      alpha3: 'tur',
      translations: {
        en: 'Turkey',
        tr: 'Türkiye',
      },
    },
    {
      id: 795,
      name: 'Turkmenistan',
      alpha2: 'tm',
      alpha3: 'tkm',
      translations: {
        en: 'Turkmenistan',
        tr: 'Türkmenistan',
      },
    },
    {
      id: 796,
      name: 'Turks and Caicos Islands',
      alpha2: 'tc',
      alpha3: 'tca',
      translations: {
        en: 'Turks and Caicos Islands',
        tr: 'Turks ve Caicos Adaları',
      },
    },
    {
      id: 798,
      name: 'Tuvalu',
      alpha2: 'tv',
      alpha3: 'tuv',
      translations: {
        en: 'Tuvalu',
        tr: 'Tuvalu',
      },
    },
    {
      id: 800,
      name: 'Uganda',
      alpha2: 'ug',
      alpha3: 'uga',
      translations: {
        en: 'Uganda',
        tr: 'Uganda',
      },
    },
    {
      id: 804,
      name: 'Ukraine',
      alpha2: 'ua',
      alpha3: 'ukr',
      translations: {
        en: 'Ukraine',
        tr: 'Ukrayna',
      },
    },
    {
      id: 784,
      name: 'United Arab Emirates',
      alpha2: 'ae',
      alpha3: 'are',
      translations: {
        en: 'United Arab Emirates',
        tr: 'Birleşik Arap Emirlikleri',
      },
    },
    {
      id: 826,
      name: 'United Kingdom of Great Britain and Northern Ireland',
      alpha2: 'gb',
      alpha3: 'gbr',
      translations: {
        en: 'United Kingdom of Great Britain and Northern Ireland',
        tr: 'Birleşik Krallık',
      },
    },
    {
      id: 840,
      name: 'United States of America',
      alpha2: 'us',
      alpha3: 'usa',
      translations: {
        en: 'United States of America',
        tr: 'Amerika Birleşik Devletleri',
      },
    },
    {
      id: 581,
      name: 'United States Minor Outlying Islands',
      alpha2: 'um',
      alpha3: 'umi',
      translations: {
        en: 'United States Minor Outlying Islands',
        tr: 'Amerika Birleşik Devletleri Küçük Dış Adaları',
      },
    },
    {
      id: 858,
      name: 'Uruguay',
      alpha2: 'uy',
      alpha3: 'ury',
      translations: {
        en: 'Uruguay',
        tr: 'Uruguay',
      },
    },
    {
      id: 860,
      name: 'Uzbekistan',
      alpha2: 'uz',
      alpha3: 'uzb',
      translations: {
        en: 'Uzbekistan',
        tr: 'Özbekistan',
      },
    },
    {
      id: 548,
      name: 'Vanuatu',
      alpha2: 'vu',
      alpha3: 'vut',
      translations: {
        en: 'Vanuatu',
        tr: 'Vanuatu',
      },
    },
    {
      id: 862,
      name: 'Venezuela (Bolivarian Republic of)',
      alpha2: 've',
      alpha3: 'ven',
      translations: {
        en: 'Venezuela (Bolivarian Republic of)',
        tr: 'Venezuela Bolivar Cumhuriyeti',
      },
    },
    {
      id: 704,
      name: 'Viet Nam',
      alpha2: 'vn',
      alpha3: 'vnm',
      translations: {
        en: 'Viet Nam',
        tr: 'Vietnam',
      },
    },
    {
      id: 92,
      name: 'Virgin Islands (British)',
      alpha2: 'vg',
      alpha3: 'vgb',
      translations: {
        en: 'Virgin Islands (British)',
        tr: 'İngiliz Virgin Adaları',
      },
    },
    {
      id: 850,
      name: 'Virgin Islands (U.S.)',
      alpha2: 'vi',
      alpha3: 'vir',
      translations: {
        en: 'Virgin Islands (U.S.)',
        tr: 'Virgin Adaları, A.B.D.',
      },
    },
    {
      id: 876,
      name: 'Wallis and Futuna',
      alpha2: 'wf',
      alpha3: 'wlf',
      translations: {
        en: 'Wallis and Futuna',
        tr: 'Wallis ve Futuna Adaları',
      },
    },
    {
      id: 732,
      name: 'Western Sahara',
      alpha2: 'eh',
      alpha3: 'esh',
      translations: {
        en: 'Western Sahara',
        tr: 'Batı Sahra',
      },
    },
    {
      id: 887,
      name: 'Yemen',
      alpha2: 'ye',
      alpha3: 'yem',
      translations: {
        en: 'Yemen',
        tr: 'Yemen',
      },
    },
    {
      id: 894,
      name: 'Zambia',
      alpha2: 'zm',
      alpha3: 'zmb',
      translations: {
        en: 'Zambia',
        tr: 'Zambiya',
      },
    },
    {
      id: 716,
      name: 'Zimbabwe',
      alpha2: 'zw',
      alpha3: 'zwe',
      translations: {
        en: 'Zimbabwe',
        tr: 'Zimbabve',
      },
    },
    {
      id: 1500,
      name: 'Kosovo',
      alpha2: 'xk',
      alpha3: 'xkx',
      translations: {
        en: 'Kosovo',
        tr: 'Kosova',
      },
    },
    {
      id: 1501,
      name: 'Crimea',
      alpha2: 'crimea',
      alpha3: 'crimea',
      translations: {
        en: 'Crimea',
        tr: 'Kırım',
      },
    },
    {
      id: 1502,
      name: "Donetsk People's Republic",
      alpha2: "donetsk people's republic",
      alpha3: "donetsk people's republic",
      translations: {
        en: "Donetsk People's Republic",
        tr: 'Donetsk Halk Cumhuriyeti',
      },
    },
    {
      id: 1503,
      name: "Luhansk People's Republic",
      alpha2: "luhansk people's republic",
      alpha3: "luhansk people's republic",
      translations: {
        en: "Luhansk People's Republic",
        tr: 'Luhansk Halk Cumhuriyeti',
      },
    },
  ];
  
  export const countryEnum = countries.reduce((acc: any, country) => {
    acc[country.alpha3] = country;
    return acc;
  }, {});
  