import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Form, Steps } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import BasePage from '../BasePage';
import IncidentChannel from './steps/IncidentChannel';
import GeneralInfo from '../KPMGFormPage/components/GeneralInfo';
import Company from './steps/Company';
import IncidentReport from './steps/IncidentReport';
import Preview from './steps/Preview';
import Done from './steps/Done';

import styles from './index.module.scss';

const StartIncident = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [values, setValues] = useState({});
	const [current, setCurrent] = useState<number>(0);
	const [type, setType] = useState<string>("");

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const onChangeType = (type: string) => {
		setType(type);
	}

	const onSubmit = async (formValues: {}) => {
		setValues(formValues);
		console.log(formValues);
		next();
	};

	const steps = [
		{
			title: t('startIncident.steps.incidentChannel'),
			content: <IncidentChannel
				type={type}
				onChangeType={onChangeType}
			/>,
		},
		{
			title: t('startIncident.steps.instruction'),
			content: <GeneralInfo hideRadioButtons={true} />,
		},
		{
			title: t('startIncident.steps.company'),
			content: <Company />,
		},
		{
			title: t('startIncident.steps.incidentReport'),
			content: <IncidentReport />,
		},
		{
			title: t('startIncident.steps.preview'),
			content: <Preview />,
		},
		{
			title: t('startIncident.steps.done'),
			content: <Done />,
		}
	];

	const items = steps.map((item) => ({ key: item.title, title: item.title }));

	const handleBack = () => {
		navigate(-1);
	}

	return (
		<BasePage
			content={
				<div className={styles.Base}>
					<p style={{ marginBottom: '3em' }}>{t('startIncident.title')}</p>
					<Steps className={styles.Steps} current={current} items={items} />
					<Form
						form={form}
						layout="vertical"
						initialValues={{}}
						onFinish={onSubmit}
					>
						<div className={styles.Content}>
							{steps[current].content}
							<div style={{ marginTop: 24, display: 'flex', justifyContent: 'end' }}>
								{(current > 0 && current !== steps.length - 1) && (
									<Button style={{ margin: '0 8px' }} onClick={() => prev()}>
										<ArrowLeftOutlined />
										{t('globals.previous')}
									</Button>
								)}
								{current < steps.length - 1 && (
									<Button
										type="primary"
										onClick={() => next()}
									>
										{t('globals.next')}
										<ArrowRightOutlined />
									</Button>
								)}
							</div>
						</div>
					</Form>
				</div>
			}
			isTop={false}
			header={
				< div className={styles.DetailHeader} >
					<div className={styles.NavigationButtons} onClick={handleBack}>
						<ArrowLeftOutlined className={styles.Back} />
						<p className={styles.Back} style={{ marginLeft: '3px ' }}>{t('globals.back')}</p>
					</div>
				</div >
			}
		/>
	);
};

export default StartIncident;
