import React from "react";
import { useTranslation } from 'react-i18next';

import { Alert } from 'antd';

import BasePage from '../BasePage';
import Header from './components/Header';
import IncidentNotes from './components/IncidentNotes';
import { useWhistleblowerNotes } from './hooks';

import styles from './index.module.scss';

const Notes = () => {
	const { t } = useTranslation();
	const notesQuery = useWhistleblowerNotes();

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<div>
						<p className={styles.Title}>{t('incident.details.notes.title')}</p>
						{notesQuery.data?.count === 0 ? (
							<div className={styles.Alert}>
								<Alert message={t('incident.details.notes.noNotes')} type="info" showIcon />
							</div>
						) : (
							<IncidentNotes incident={notesQuery.data} />
						)}
					</div>
				</div>
			}
			isTop={false}
			header={notesQuery.data != null ? <Header /> : null}
		/>
	);
};

export default Notes;
