import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Tooltip, Badge, Select } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';

import { OrganizationTotalData, TotalIncidentData } from "../../../app/interface";
import fileSVG from '../assets/file.svg';

import styles from './index.module.scss';

const IncidentStatus = (props: { states: Array<TotalIncidentData>, organizations: Array<OrganizationTotalData> }) => {
    const { t } = useTranslation();
    const [selectedCompany, setSelectedCompany] = useState<number>(0);
    const [options, setOptions] = useState<Array<{ value: number, label: string }>>([{ value: 0, label: t('dashboard.incidentStatus.allCompanies') }]);
    const [data, setData] = useState<{ resolved: number, inProgress: number }>({ resolved: 0, inProgress: 0 })

    const onCompanyChange = (value: number) => {
        setSelectedCompany(value);
    };

    useEffect(() => {
        props.organizations?.forEach((org) => {
            options.push({ value: org.id, label: org.name });
        })
        setOptions((options) => [...options]);
    }, [props.organizations?.length]);

    useEffect(() => {
        switch (selectedCompany && props.states) {
            case 0:
                const localData = {
                    resolved: props.states?.find((s) => s.id === 3)?.count || 0,
                    inProgress: props.states?.find((s) => s.id === 2)?.count || 0
                };
                setData(localData);
                break;

            default:
                break;
        }
    }, [selectedCompany, props.states?.length]);

    const config = useMemo(
        () => ({
            autoFit: true,
            width: 400,
            height: 280,
            appendPadding: 30,
            data: [
                {
                    type: t('dashboard.incidentStatus.resolved'),
                    value: data?.resolved || 0,
                    color: '#0042EF',
                },
                {
                    type: t('dashboard.incidentStatus.inProgress'),
                    value: data?.inProgress || 0,
                    color: '#8FA9ED',
                }
            ],
            angleField: 'value',
            colorField: 'type',
            color: ['#0042EF', '#8FA9ED'],
            radius: 1,
            innerRadius: 0.85,
            startAngle: 0,
            endAngle: Math.PI * 2,
            label: {
                content: '',
            },
            statistic: {
                title: false,
                content: {
                    customHtml: () => (
                        <div className={styles.IconWrapper}>
                            <div style={{ backgroundColor: '#fff' }} className={styles.Icon}>
                                <img src={fileSVG} alt="svg icon" width="auto" />
                            </div>
                        </div>
                    ),
                },
            } as any,
            legend: false as any
        }),
        [t, data]
    );


    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Row
                        justify="space-between"
                        style={{ marginBottom: 3, alignItems: 'center' }}
                    >
                        <Col>
                            <Row gutter={[16, 0]}>
                                <Col>
                                    <h4 className={styles.Title}>
                                        {t('dashboard.incidentStatus.title')}
                                    </h4>
                                </Col>
                                <Col>
                                    <Tooltip title={t('dashboard.incidentStatus.tooltip')}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Select
                                bordered={false}
                                value={selectedCompany}
                                onChange={onCompanyChange}
                                style={{ minWidth: 120 }}
                                placeholder={<span style={{ color: '#0042EF', display: 'flex' }}>{t('dashboard.incidentStatus.allCompanies')}</span>}
                                options={options}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Pie {...config} />
                </Col>
                <Col span={24}>
                    <Row justify="space-between">
                        {Array.isArray(config?.data) &&
                            config.data.map((item) => (
                                <Col key={item?.type}>
                                    <Badge
                                        color={item?.color}
                                        text={
                                            <>
                                                <span style={{ marginRight: 2 }}>{item?.type}</span>
                                                <span style={{ marginLeft: 2 }}>{item?.value}</span>
                                            </>
                                        }
                                    />
                                </Col>
                            ))}
                    </Row>
                </Col>
            </Row >
        </Card >
    )
};

export default IncidentStatus;