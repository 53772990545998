import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReCAPTCHA from "react-google-recaptcha";

import { Space, Typography, Input, Button, Form, Upload, Divider, Row, Col, Card, Checkbox, Layout } from 'antd';
import { UploadOutlined, ArrowRightOutlined, ArrowLeftOutlined, InfoCircleTwoTone, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/es/form/Form';

import Question from './components/Question';
import LinkText from '../../components/LinkText';
import Header from '../../components/Header';

import { selectUser } from '../../app/slices/userSlice';
import { uploadIncidentFile } from '../../app/apis/incidents';
import { sendIncident } from '../../app/apis/whistleblower';
import i18n from '../../app/i18n';
import { useQuestions } from '../../hooks/useQuestions';
import messages from '../../utils/message';

const { Text, Title } = Typography;

const FormPage: React.FC = () => {
    const { t } = useTranslation();
    const [ethicsForm] = useForm();
    const [passwordsForm] = useForm();
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [isConditionChecked, setIsConditionChecked] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const [isVerified, setIsVerified] = useState(true);
    const [page, setPage] = useState(1);
    const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);

    const firstPageQuestionsSlugs = ["company", "locationcountry", "incidentcountry", "incidentcity"];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [values, setValues] = useState<any>({});

    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const incident = user.id;
    const language = user.language;
    const questionsData = useQuestions();
    const firstPageQuestions = questionsData?.data?.results.filter((q: any) => firstPageQuestionsSlugs.includes(q.slug));
    const secondPageQuestions = questionsData?.data?.results.filter((q: any) => !firstPageQuestionsSlugs.includes(q.slug));

    const handleCheckPasswords = () => {
        const passwords = [passwordsForm.getFieldValue('password'), passwordsForm.getFieldValue('passwordConfirm')];

        if (passwords.every((password) => password === passwords[0])) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    };

    const confirmPasswordValidator = (_: any, value: any) => {
        const { getFieldValue } = passwordsForm;
        if (value && value !== getFieldValue('password')) {
            return Promise.reject(new Error(t('form.passwrodsNotMatch') || ''));
        }
        return Promise.resolve();
    };

    const handleFileUpload = (event: any) => {
        setFiles(event.fileList);
    };

    const getFirstPageQuestionAnswers = () => {
        const firstPageQuestions: { [key: string]: any } = {};
        firstPageQuestionsSlugs?.forEach((slug: string) => {
            const answer = ethicsForm.getFieldValue(slug);
            firstPageQuestions[slug] = answer;
        });
        return firstPageQuestions;
    };

    const handleSubmitForm = () => {
        const firstPageAnswers = getFirstPageQuestionAnswers();
        const secondPageAnswers = ethicsForm.getFieldsValue();
        const password = passwordsForm.getFieldValue('password');
        const answers = { ...firstPageAnswers, ...secondPageAnswers };

        sendIncident({ incident, language: language || 'en', answers, password })
            .then(() => {
                navigate('/confirmation');
            })
            .catch(() => { });

    };

    const handleFieldsChange = () => {
        const fieldValues = ethicsForm.getFieldsValue();
        const isFormValid = Object.values(fieldValues).every((value) => value !== undefined && value !== '');
        setNextButtonDisabled(!isFormValid);
    };

    const filterChildQuestions = useCallback((item: any) => {
        if (item.parent) {
            const parentKey = item?.parent;
            const value = ethicsForm.getFieldValue(`${parentKey}`);
            return item.parentRequiredValue === value;
        }
        return true;
    }, [ethicsForm]);

    const onValuesChange = (_: any, allValues: any) => {
        const obj = { ...allValues };

        setValues((prevState: any) => ({ ...prevState, ...obj }));
    };

    useEffect(() => {
        if (!incident) {
            navigate('/');
        }
    }, [incident, navigate]);

    useEffect(() => {
        const element = document.getElementById('form');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }, [page])

    return (
        <Layout>
            <Header showLogout={false} showUser={false} companyType={1} />
            <Content
                className='content'
                id='form'
                style={{
                    background: `#fff`,
                    minHeight: 'calc(100vh - 150px)'
                }}
            >
                <Space direction="vertical" size="middle" style={{ width: '100%', justifyContent: 'center', padding: '3em' }}>
                    <Title level={4}>{t('index.title')} </Title>
                    <Text style={{ fontSize: 18 }}>{`${t('form.step')} ${page}/2`}</Text>
                    {page === 1 && (
                        <>
                            <Text style={{ fontSize: 18 }}>{t('form.paragraph1')}</Text>
                            <Text strong style={{ fontSize: 18 }}>
                                {t('index.paragraph2')}
                            </Text>
                            <Text style={{ fontSize: 18 }}>{t('form.paragraph3')}</Text>
                        </>
                    )}
                    <Divider />
                    <Text style={{ fontSize: 16, fontWeight: 500 }}>{t('form.info')}</Text>
                    {page === 1 ? (
                        <>
                            <Form form={ethicsForm} layout="vertical" name="ethics" onFieldsChange={handleFieldsChange}>
                                {firstPageQuestions?.map((question: any) => (
                                    <Question key={question.slug} question={question} />
                                ))}
                            </Form>
                            <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                    onClick={() => setPage(2)}
                                    disabled={isNextButtonDisabled}
                                    style={{ height: 40, fontSize: 16, maxWidth: 250, marginTop: 10 }}
                                    type="primary"
                                    icon={<ArrowRightOutlined />}
                                >
                                    {t('form.next')}
                                </Button>
                            </Col>
                        </>
                    ) : (
                        < >
                            <Form form={ethicsForm} onValuesChange={onValuesChange} layout="vertical" name="ethics">
                                {secondPageQuestions?.filter(filterChildQuestions).map((question: any) => (
                                    <Question key={question.slug} question={question} />
                                ))}
                            </Form>
                            <Space direction="vertical">
                                <Text>{t('form.uploadFile')}</Text>
                                <Space>
                                    <Upload
                                        customRequest={(options: any) => {
                                            const data = new FormData();
                                            data.append('file', options.file);
                                            uploadIncidentFile(incident!, data)
                                                .then((response) => {
                                                    options.onSuccess(response.data, options.file);
                                                })
                                                .catch((error) => {
                                                    messages.showErrorMessage(error.response.data);
                                                });
                                        }}
                                        onChange={handleFileUpload}
                                    >
                                        <Button icon={<UploadOutlined />}>{t('globals.upload')}</Button>
                                    </Upload>
                                    {files?.length === 0 && <Text>{t('form.noFiles')}</Text>}
                                </Space>
                            </Space>
                            <Card style={{ borderColor: 'black', background: '#FDFDFD' }}>
                                <Row>
                                    <Col span={1}>
                                        <InfoCircleTwoTone />
                                    </Col>
                                    <Col span={23}>
                                        <Text>{t('form.paragraph2')}</Text>
                                    </Col>
                                </Row>
                            </Card>
                            <Form form={passwordsForm} layout="vertical" name="passwords">
                                <Form.Item
                                    label={t('form.password')}
                                    name="password"
                                    rules={[
                                        { required: true, message: t('errors.required')! },
                                        { min: 6, message: t('form.password6')! },
                                        { pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/, message: t('form.passwordRules')! },
                                    ]}
                                >
                                    <Input.Password
                                        type="password"
                                        onChange={() => handleCheckPasswords()}
                                        placeholder={t('form.passwordPlaceholder')!}
                                        size="large"
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t('form.passwordReenter')}
                                    name="passwordConfirm"
                                    rules={[{ required: true, message: t('errors.required')! }, { validator: confirmPasswordValidator }]}
                                >
                                    <Input.Password
                                        type="password"
                                        onChange={() => handleCheckPasswords()}
                                        placeholder={t('form.passwordPlaceholder')!}
                                        size="large"
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                            </Form>
                            <Checkbox checked={isConsentChecked} onChange={(e) => setIsConsentChecked(e.target.checked)} style={{ fontSize: 18 }}>
                                <Trans
                                    i18nKey="form.consent"
                                    components={{
                                        consent: <LinkText
                                            target='_blank'
                                            to={i18n.language === 'tr' ? `${process.env.REACT_APP_FILE_URL}EH-Aydınlatma.pdf` :
                                                i18n.language === 'fr' ? `${process.env.REACT_APP_FILE_URL}FR-EH-Aydınlatma.pdf` :
                                                    i18n.language === 'de' && `${process.env.REACT_APP_FILE_URL}DE-EH-Aydınlatma.pdf` ||
                                                    `${process.env.REACT_APP_FILE_URL}EH-Aydınlatma.pdf`
                                            } />,
                                    }}
                                />
                                <p>{t('form.secondConsent')}</p>
                            </Checkbox>
                            <Checkbox checked={isConditionChecked} onChange={(e) => setIsConditionChecked(e.target.checked)} style={{ fontSize: 18 }}>
                                <Trans i18nKey="form.conditions" components={{ condition: <LinkText to="/" /> }} />
                            </Checkbox>
                            {/*<ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY!} onChange={() => setIsVerified((isVerified) => !isVerified)} />*/}
                            <Row justify="space-between">
                                <Col>
                                    <Button
                                        onClick={() => setPage(1)}
                                        style={{ height: 40, fontSize: 16, maxWidth: 250 }}
                                        icon={<ArrowLeftOutlined />}
                                    >
                                        {t('form.prev')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        block
                                        disabled={isSubmitDisabled || !isVerified || !isConsentChecked || !isConditionChecked}
                                        onClick={() => handleSubmitForm()}
                                        style={{ height: 40, fontSize: 16, maxWidth: 250 }}
                                        type="primary"
                                        icon={<ArrowRightOutlined />}
                                    >
                                        {t('form.submitNotification')}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Space>
            </Content>
        </Layout >
    );
};

export default FormPage;
