import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Avatar, Button, Select, Space } from "antd";
import { Header as AntdHeader } from 'antd/es/layout/layout';
import { GlobalOutlined, LogoutOutlined } from '@ant-design/icons';

import CompanyHeader from "./CompanyHeaders/CompanyHeader";

import { checkIsAdmin, getSession, removeSession } from "../helpers/session";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectUser, setLanguage } from "../app/slices/userSlice";
import { useLanguage } from "../hooks/language";
import i18n from "../app/i18n";

import styles from './index.module.scss';
import { logout } from "../app/apis/auth";


const Header = (props: { showUser: boolean; showLogout: boolean; companyType?: number, showLogo?: boolean }) => {
    const { showLogo = true } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const user = getSession();
    const isAdmin = checkIsAdmin();

    const userLanguage = useAppSelector(selectUser);
    const language = userLanguage.language;
    const languageQuery = useLanguage().data;

    const selectLanguage = (value: string) => {
        dispatch(setLanguage(value));
        i18n.changeLanguage(value);
    };


    const onLogout = () => {
        logout().then(() => {
            removeSession();
            if (isAdmin) {
                navigate('/admin-login');
            }
            else {
                navigate('/login');
            }
        })
    };

    return (
        <>
            <AntdHeader className={styles.TopMenu}>
                {showLogo && (
                    <div className={styles.Logo}>
                        <Link to="/" >
                            <img style={{ marginTop: 24, height: 24 }} alt="Logo" src="/static/images/side-logo.png" />
                        </Link>
                    </div>
                )}
                <div style={{ flexGrow: 1 }}></div>
                {(isAdmin && props.showUser) ? (
                    <Space style={{ color: 'white', marginRight: '2em' }}>
                        <Avatar size="small" style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>
                            {user?.user.name ? user?.user.name.toUpperCase().substring(0, 1) : user?.user.email.toUpperCase().substring(0, 1)}
                        </Avatar>
                        {user?.user.name}
                    </Space>
                ) : props.showUser && (
                    <p style={{ color: 'white', marginRight: '2em' }}>{`#${user.user?.id || '-'}`}</p>
                )}
                <Select
                    style={{ minWidth: 120 }}
                    suffixIcon={<GlobalOutlined />}
                    onSelect={(value: string) => selectLanguage(value)}
                    options={languageQuery?.data?.results.map((l: { name: string, isoCode: string }) => ({ label: l.name, value: l.isoCode }))}
                    value={language || i18n.language}
                />
                {props.showLogout && (
                    <Button
                        type="text"
                        style={{ color: '#FFF' }}
                        icon={<LogoutOutlined />}
                        onClick={onLogout}
                    >
                        {t('globals.logout')}
                    </Button>
                )}
            </AntdHeader>
            {props.companyType && <CompanyHeader type={props.companyType} />}
        </>
    )
}

export default Header;