import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Divider } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const AddNoteModal = (props: { isOpen: boolean; setOpen: any; onSubmit: (props: {}) => void }) => {
    const { t } = useTranslation();
    const [noteValue, setNoteValue] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setNoteValue(value);
    };

    const handleClose = () => {
        props.setOpen(false);
        setNoteValue('');
    };

    const handleSubmitNote = () => {
        props.setOpen(false);
        const payload = {
            content: noteValue
        };
        props.onSubmit(payload);
        handleClose();
    }

    useEffect(() => {
        if (noteValue?.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [noteValue])

    return (
        <Modal
            title={t('globals.noteModal.title')}
            centered open={props.isOpen}
            onOk={handleSubmitNote}
            onCancel={handleClose}
            okText={t('globals.submit')}
            cancelText={t('globals.cancel')}
            okButtonProps={{
                disabled: disabled
            }}
        >
            <Divider />
            <TextArea
                onChange={handleNoteChange}
                value={noteValue}
                rows={4}
                placeholder={t('globals.noteModal.notePlaceholder') || ''}
            />
        </Modal>
    )
}

export default AddNoteModal;