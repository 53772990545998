import React, {
    useState,
    useRef,
    useCallback,
    useEffect,
    useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Modal, Form, Divider, Row, Col, Input, Alert, Statistic, Button, InputRef } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

const { Countdown } = Statistic;

const getCountdownDuration = (timeout: number | null = null) =>
    moment().add(timeout ?? 180, 'seconds');

interface VerificationModalProps {
    pending?: boolean;
    error?: {
        status: number;
    } | null;
    open?: boolean;
    email?: string;
    phone?: string;
    timeout?: number;
    onResend?: () => void;
    onVerify?: (code: string) => Promise<boolean>;
    onCancel?: () => void;
    method?: string;
}

const VerificationModal: React.FC<VerificationModalProps> = ({
    pending = false,
    error = null,
    open = false,
    email = '',
    phone = '',
    timeout = 120,
    onResend = () => { },
    onVerify = () => { },
    onCancel = () => { },
    method = '',
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [code, setCode] = useState<string[]>([]);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const inputRefs = useRef<(InputRef | null)[]>([null]);
    const [lastInput, setLastInput] = useState<HTMLInputElement | undefined>();
    const countdownTimer = useRef<moment.Moment>(getCountdownDuration(timeout));

    const isCodeValid = useMemo(
        () =>
            code.filter((item) => typeof item === 'string' && item.length > 0)
                .length === 6,
        [code],
    );

    const handleKeyDown = useCallback(
        (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
            event.persist();

            const regex = /^[a-zA-Z0-9]+$/;
            const target = event.target as HTMLTextAreaElement;
            const previousInput = inputRefs.current?.[index - 1];
            const currentInput = inputRefs.current?.[index];
            const nextInput = inputRefs.current?.[index + 1];

            if (['Backspace', 'Delete'].includes(event.key) && previousInput) {
                currentInput?.select();
                setTimeout(() => {
                    previousInput?.focus();
                    previousInput?.select();
                }, 50);
            } else if (
                event.key === 'Enter'
                && index < inputRefs.current.length - 1
                && nextInput
            ) {
                setTimeout(() => {
                    nextInput.focus();
                }, 50);
            } else if (regex.test(target.value) && nextInput) {
                if (index > 0) {
                    setTimeout(() => {
                        nextInput.focus();
                        nextInput.select();
                    }, 50);
                } else {
                    currentInput?.select();
                }
            }
        },
        [],
    );

    const handleInputChange = useCallback(
        (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[a-zA-Z0-9]+$/;
            const {
                target: { value = '' },
            } = event;
            const nextInput = inputRefs.current?.[index + 1];

            setCode((prevCode) => {
                const newCode = [...prevCode];
                newCode[index] = value;
                return newCode;
            });

            if (regex.test(value) && nextInput) {
                nextInput.focus();
                nextInput.select();
            }
        },
        [setCode],
    );

    const toggleCountdownActive = useCallback(() => {
        setIsCountdownActive((prevState) => !prevState);
    }, []);

    const resetCountdown = useCallback(() => {
        setCode([]);
        setIsCountdownActive(true);
        setIsSuccess(false);
        form.resetFields();
        countdownTimer.current = getCountdownDuration(timeout);
    }, [form, timeout]);

    const onResendClick = useCallback(async () => {
        setCode([]);
        resetCountdown();
        onResend();
    }, [setCode, resetCountdown, onResend]);

    const onPaste = useCallback(
        (event: ClipboardEvent) => {
            const clipboardText = event.clipboardData?.getData('text')?.trim();

            if (
                open
                && typeof clipboardText === 'string'
                && clipboardText.length === 6
            ) {
                setCode(() => clipboardText.split(''));
                form.resetFields();
                form.setFieldsValue({ code: clipboardText.split('') });
                const index = inputRefs.current?.length;
                const input = inputRefs.current?.[index - 1];

                if (input) {
                    setLastInput(input as any);
                }
            }
        },
        [open, form],
    );

    const onFinish = async () => {
        const isVerificationSuccess = await onVerify(code.join(''));
        if (isVerificationSuccess) {
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
        }
    };

    const descriptionChange = () => {
        let description;
        const minute = Math.floor(timeout / 60);
        if (method === 'sms' && phone.trim().length === 0) {
            description = t('globals.verificationModal.descriptionSms', { minute });
        } else if (method === 'email' && email.trim().length === 0) {
            description = t('globals.verificationModal.descriptionMail', { minute });
        } else {
            description = t('globals.verificationModal.description', { option: method === 'sms' ? phone : email, minute });
        }
        return description;
    };

    const onEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onFinish();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onEnter);

        return () => {
            document.removeEventListener('keydown', onEnter);
        };
    }, [onEnter]);

    useEffect(() => {
        resetCountdown();

        return () => {
            resetCountdown();
        };
    }, [open, resetCountdown]);

    useEffect(() => {
        document.addEventListener('paste', onPaste);

        return () => {
            document.removeEventListener('paste', onPaste);
        };
    }, [onPaste]);

    useEffect(() => {
        if (lastInput) {
            lastInput.focus();
            lastInput.setSelectionRange(lastInput.value?.length, lastInput.value?.length);
        }
    }, [lastInput]);

    return (
        <Modal
            open={open}
            title={t('globals.verificationModal.title')}
            destroyOnClose
            footer={null}
            onCancel={() => {
                resetCountdown();
                form.resetFields();
                onCancel();
            }}
        >
            <Form
                form={form}
                preserve={false}
                initialValues={{ code: [code] }}
                onFinish={onFinish}
            >
                {descriptionChange()}
                <Row justify="center" style={{ marginTop: '2em', marginBottom: '1em' }} >
                    <Col>
                        <Countdown
                            className={styles.Countdown}
                            format="mm:ss"
                            value={isCountdownActive ? countdownTimer.current as any : 0}
                            onFinish={toggleCountdownActive}
                        />
                    </Col>
                </Row>
                <Form.List
                    name="code"
                >
                    {(fields) => (
                        <Row gutter={[12, 0]} style={{ marginTop: '4px' }}>
                            {Array.from({ length: 6 }, (_, index) => (
                                <Col key={`${index}`} span={4}>
                                    <Form.Item
                                        {...fields[index]}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: 'Bu alanı doldurun.',
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input
                                            key={index}
                                            className={styles.CodeInput}
                                            tabIndex={index + 1}
                                            maxLength={1}
                                            ref={(el) => {
                                                inputRefs.current[index] = el;
                                            }}
                                            onFocus={() => {
                                                const currentInput = inputRefs?.current?.[index];
                                                if (currentInput?.select) {
                                                    currentInput.select();
                                                }
                                            }}
                                            onKeyDown={handleKeyDown(index)}
                                            onChange={handleInputChange(index)}
                                        />
                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    )}
                </Form.List>
                {!isCountdownActive && (
                    <Row style={{ marginTop: 3 }}>
                        <Col span={24}>
                            <Alert
                                showIcon
                                type="error"
                                message={t('globals.verificationModal.errors.timeExpired')}
                            />
                        </Col>
                    </Row>
                )}
                {(error) && (
                    <Row style={{ marginTop: 3 }}>
                        <Col span={24}>
                            <Alert
                                showIcon
                                type="error"
                                message={t([
                                    `globals.verificationModal.errors.${error?.status}`,
                                    'globals.verificationModal.errors.default',
                                ])}
                            />
                        </Col>
                    </Row>
                )}
                <Button
                    disabled={isCountdownActive}
                    type="link"
                    className={styles.ResendButton}
                    onClick={onResendClick}
                >
                    {t('globals.verificationModal.resendCode')}
                </Button>
                <Divider />
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Button
                        loading={pending}
                        disabled={!isCountdownActive || !isCodeValid || isSuccess}
                        htmlType="submit"
                        className={isSuccess ? styles.BtnVerified : ''}
                        icon={
                            isSuccess ? (
                                <CheckOutlined className={styles.VerifiedIcon} />
                            ) : null
                        }
                        type="primary"
                        block
                    >
                        {isSuccess
                            ? t('globals.verificationModal.verified')
                            : t('globals.verificationModal.verify')}
                    </Button>
                </Col>
            </Form>
        </Modal>
    );
};

export default VerificationModal;
