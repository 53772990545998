import apiClient from "../api";

const BASE_URL = `${process.env.REACT_APP_API_URL}/categories/`;

export const getCategories = () => {
    return apiClient.get(`${BASE_URL}`);
};

export const getCategory = (id: number) => {
    return apiClient.get(`${BASE_URL}${id}/`);
};