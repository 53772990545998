import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'antd';

import BasePage from '../BasePage';
import Header from './components/Header';
import IncidentDocuments from './components/IncidentDocuments';

import { useIncidentDetail } from './hooks';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../app/slices/userSlice';

import styles from './index.module.scss';

const Documents = () => {
	const { t } = useTranslation();
	const user = useAppSelector(selectUser);
	const { id, token } = user;

	const [isRefresh, setRefresh] = useState<boolean>(false);

	const query = useIncidentDetail(id!, token!, isRefresh);
	const documents = query?.data?.files || [];

	const handleRefresh = () => setRefresh((refresh) => !refresh);

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<p className={styles.Title}>{t('incident.details.documents.title')}</p>
					{documents.length === 0 ? (
						<div className={styles.Alert}>
							<Alert message={t('incident.details.documents.noDocument')} type="info" showIcon />
						</div>
					) : (
						<IncidentDocuments incident={query.data} onRefresh={handleRefresh} />
					)}
				</div>
			}
			isTop={false}
			header={query.data != null ? <Header /> : null}
		/>
	);
};

export default Documents;
