import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Button, Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import TextArea from 'antd/es/input/TextArea';

import { resolveIncident } from '../../../app/apis/incidents';
import messages from '../../../utils/message';

import styles from '../index.module.scss';

const Resolve = (props: { incident: any, onUpdateStatus: (value: { name: string; colour: "default" | "success" | "processing" | "error" | "warning" | undefined }) => void }) => {
	const { t } = useTranslation();
	const { incidentId } = useParams();

	const [isInternalCompanyCorrespondenceActive, setInternalCompanyCorrespondenceActive] = useState<boolean>(false);
	const [isResolved, setResolved] = useState<boolean>(props.incident.data.status.value === 3);

	const handleResolve = (values: any) => {
		let payload = { ...values };
		if (!values.internal_note) {
			payload.internal_note = null;
		}

		resolveIncident(incidentId!, payload).then(() => {
			const value = {
				name: 'İncelendi',
				colour: "success" as "success"
			};
			props.onUpdateStatus(value);
			messages.showSuccesMessage(t('messages.defaultSuccess')!);
			setResolved(true);
		}).catch((error) => {
			messages.showErrorMessage(error?.response?.data);
		})
	}

	return (
		<div className={styles.Tabs}>
			<div className={styles.Resolve}>
				<Alert
					description=""
					message={isResolved ? t('incident.details.resolveIncident.resolvedInfo') : t('incident.details.resolveIncident.info')}
					type="info"
				/>
				<Form
					layout="vertical"
					initialValues={{ content: props.incident?.data?.resolution?.content || '', internal_note: props.incident?.data?.resolution?.internalNote || null }}
					onFinish={handleResolve}
				>
					<Form.Item
						className={styles.ResolveFormItem}
						label={t('incident.details.resolveIncident.outcomeLabel')}
						name="content"
					>
						<TextArea
							placeholder={t('incident.details.resolveIncident.outcomePlaceholder')!}
							disabled={isResolved}
						/>
					</Form.Item>
					<Checkbox
						checked={props.incident?.data?.resolution?.internalNote || isInternalCompanyCorrespondenceActive}
						disabled={isResolved}
						onChange={(e: CheckboxChangeEvent) => setInternalCompanyCorrespondenceActive(e.target.checked)}
					>
						{t('incident.details.resolveIncident.internalCompanyCorrespondence')}
					</Checkbox>
					{props.incident?.data?.resolution?.internalNote || isInternalCompanyCorrespondenceActive ? (
						<Form.Item
							className={styles.ResolveFormItem}
							label={t('incident.details.resolveIncident.detailsLabel')}
							name="internal_note"
						>
							<TextArea
								placeholder={t('incident.details.resolveIncident.detailsPlaceholder')!}
								disabled={isResolved}
							/>
						</Form.Item>
					) : null}
					<Form.Item className={styles.ResolveFormItem} style={{ marginTop: '3vh', textAlign: 'right' }}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={isResolved}
						>
							{t('globals.submit')}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default Resolve;
