import Detail from './Detail';
import IncomingQuestions from './IncomingQuestions';
import Documents from './Documents';
import Notes from './Notes';

const Incidents = {
	Detail,
	IncomingQuestions,
	Documents,
	Notes
};

export default Incidents;
