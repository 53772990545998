import { useQuery } from 'react-query';
import { getWhistleblowerIncidentCheck, getWhistleblowerNotes, getWhistleblowerQuestions, } from '../../../app/apis/whistleblower';

export const useIncidentDetail = (incidentId: string, token: string, isRefresh?: boolean) => {
	return useQuery(
		['incidentDetail', incidentId, token, isRefresh],
		async () => {
			const response: any = await getWhistleblowerIncidentCheck(token);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};

export const useWhistleblowerQuestions = (toggle: boolean) => {
	return useQuery(
		['whistleblowerQuestions', toggle],
		async () => {
			const response: any = await getWhistleblowerQuestions();

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};

export const useWhistleblowerNotes = () => {
	return useQuery(
		['whistleblowerNotes'],
		async () => {
			const response: any = await getWhistleblowerNotes();

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};
