import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Alert, Button, Divider } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import BasePage from '../BasePage';
import Header from './components/Header';

import { useWhistleblowerQuestions } from './hooks';
import { IncidentAdditionalQuestionData } from '../../app/interface';
import { whistleblowerReplyQuestion } from '../../app/apis/whistleblower';

import styles from './index.module.scss';
import messages from '../../utils/message';

const IncomingQuestions = () => {
	const { t } = useTranslation();
	const [toggle, setToggle] = useState<boolean>(false);

	const query = useWhistleblowerQuestions(toggle);

	const questions = query.data;

	const IncomingQuestion = (props: { question: IncidentAdditionalQuestionData, index: number }) => {
		const { question } = props;
		const [value, setValue] = useState(question.answer);
		const [loading, setLoading] = useState<boolean>(false);

		const handleSubmitQuestion = () => {
			setLoading(true);
			const payload = {
				content: value!,
				question_id: props.question.id,
			};
			whistleblowerReplyQuestion(payload).then(() => {
				setToggle((toggle) => !toggle);
				setLoading(false);
				setValue('');
				messages.showSuccesMessage();
			}).catch((error) => {
				setLoading(false);
				messages.showErrorMessage(error?.response?.data);
			});
		}

		return (
			<div key={props.index}>
				<p>{t('incident.details.incomingQuestions.questionAsked')}</p>
				<p className={styles.NoteInfo} >{`${question.user?.name || question.user?.email || '-'} - ${dayjs(question.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
				<p>{question.content}</p>
				<Divider />
				{question.replies && question.replies?.length > 0 ? (
					question.replies.map((reply: { content: string }, index: number) => (
						<div style={{ marginLeft: 25 }} key={index}>
							<p>{t('incident.details.incomingQuestions.answered')}</p>
							<p className={styles.NoteInfo} >{`${dayjs(question.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
							<p style={{ marginTop: 20 }}>
								{reply.content}
							</p>
						</div>
					))
				) : (
					<>
						<TextArea
							style={{ marginTop: 20 }}
							onChange={(e) => { setValue(e.target.value) }}
							value={value || ''}
							rows={4}
							placeholder={t('incident.details.incomingQuestions.answerQuestionPlaceholder') || ''}
						/>
						<Button
							type='primary'
							loading={loading}
							onClick={handleSubmitQuestion}
							style={{ marginTop: 15 }}
						>
							{t('globals.submit')}
						</Button>
					</>
				)}
				<Divider />
			</div>
		)
	}

	return (
		<BasePage
			content={
				<div className={styles.Detail}>
					<p className={styles.Title}>{t('incident.details.incomingQuestions.title')}</p>
					{questions?.count === 0 ? (
						<div className={styles.Alert}>
							<Alert message={t('incident.details.incomingQuestions.noQuestions')} type="info" showIcon />
						</div>
					) : (
						<>
							<Alert message={t('incident.details.incomingQuestions.answerQuestion')} type="info" showIcon />
							<Divider />
							{questions?.results?.map((question: IncidentAdditionalQuestionData, index: number) => (
								<IncomingQuestion question={question} index={index} />
							)
							)}
						</>
					)}
				</div>
			}
			isTop={false}
			header={query.data != null ? <Header /> : null}
		/>
	);
};

export default IncomingQuestions;
