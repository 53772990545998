import { UserData } from "./interface";

export const DEBUG_STATE: UserData = {
	language: null,
	token: null,
	id: null,
	isAdmin: false
};

export const DEFAULT_STATE: UserData = {
	language: null,
	token: null,
	id: null,
	isAdmin: false
};
