import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Tooltip, Badge } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';

import { WhistleblowerRelationshipData } from "../../../app/interface";
import fileSVG from '../assets/file.svg';

import styles from './index.module.scss';


const colors: Array<string> = ['#0042EF', '#722ED1', '#EB2F96', '#FA541C', '#13C2C2', '#FADB14', '#F5222D'];

const WhistleblowerRelationship = (props: { relationships: Array<WhistleblowerRelationshipData> }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<Array<{ type: string, value: number, color: string }>>([]);

    useEffect(() => {
        props.relationships?.forEach((r, index) => {
            data?.push({
                type: r.name,
                value: r.count,
                color: colors[index]
            });
        });
        setData((data) => [...data]);
    }, [props.relationships?.length]);

    const config = useMemo(
        () => ({
            autoFit: true,
            width: 400,
            height: 280,
            appendPadding: 30,
            data: data,
            angleField: 'value',
            colorField: 'type',
            color: colors,
            radius: 1,
            innerRadius: 0.85,
            startAngle: 0,
            endAngle: Math.PI * 2,
            label: {
                content: '',
            },
            statistic: {
                title: false,
                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: () => (
                        <div className={styles.IconWrapper}>
                            <div style={{ backgroundColor: '#fff' }} className={styles.Icon}>
                                <img src={fileSVG} alt="svg icon" width="auto" />
                            </div>
                        </div>
                    ),
                },
            } as any,
            legend: false as any
        }),
        [data]
    );

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Row gutter={[10, 0]} style={{ marginBottom: 3 }}>
                        <Col>
                            <h4 className={styles.Title}>
                                {t('dashboard.whistleblowerRelationship.title')}
                            </h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.whistleblowerRelationship.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Pie {...config} />
                </Col>
                <Row justify="start" gutter={[16, 16]}>
                    {Array.isArray(config?.data) &&
                        config.data.map((item) => (
                            <Col key={item?.type} span={8}>
                                <Badge
                                    color={item?.color}
                                    text={
                                        <span style={{ marginRight: 2 }}>{item?.type}</span>
                                    }
                                />
                            </Col>
                        ))}
                </Row>
            </Row>
        </Card>
    )
};

export default WhistleblowerRelationship;