
import { useQuery } from "react-query";

import { getCategories } from "../app/apis/categories";

export const useCategories = () => {
    return useQuery(
        ['categories'],
        async () => {
            const response = await getCategories();

            return response.data;
        },
        {
            initialData: null,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );
};