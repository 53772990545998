import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Layout, { Content } from "antd/es/layout/layout";
import { Col } from "antd";
import Title from "antd/es/typography/Title";

import Header from "../../components/Header";
import i18n from "../../app/i18n";

import styles from './index.module.scss';

const FAQPage = () => {
    const { t } = useTranslation();

    const TITLES = [
        {
            key: 'about',
            label: t('faq.about'),
            questions: [{
                subtitle: t('faq.aboutQ.subtitle1'),
                answer: t('faq.aboutQ.answer1'),
            }],
        },
        {
            key: 'general',
            label: t('faq.general'),
            questions: [{
                subtitle: t('faq.generalQ.subtitle1'),
                answer: <Trans
                    i18nKey={t('faq.generalQ.answer1')}
                    components={[
                        <a
                            href={
                                i18n.language === 'tr' ?
                                    `${process.env.REACT_APP_FILE_URL}TR-Temsa-Genel-Davranış-Kuralları.pdf` :
                                    i18n.language === 'fr' ?
                                        `${process.env.REACT_APP_FILE_URL}FR-Temsa-Genel Davranış-Kuralları.pdf` :
                                        i18n.language === 'de' &&
                                        `${process.env.REACT_APP_FILE_URL}DE-Temsa-Genel-Davranış-Kuralları.pdf` ||
                                        `${process.env.REACT_APP_FILE_URL}EN-Temsa-Genel-Davranış-Kuralları.pdf`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>,
                    ]}
                />,
            },
            {
                subtitle: t('faq.generalQ.subtitle2'),
                answer: t('faq.generalQ.answer2'),
            },
            {
                subtitle: t('faq.generalQ.subtitle3'),
                answer: t('faq.generalQ.answer3'),
            },
            {
                subtitle: t('faq.generalQ.subtitle4'),
                answer: t('faq.generalQ.answer4'),
            },
            {
                subtitle: t('faq.generalQ.subtitle5'),
                answer: <Trans
                    i18nKey={t('faq.generalQ.answer5')}
                    components={[
                        <a
                            href={i18n.language === 'tr' ? 'https://www.temsa.com/tr/tr/etik-ve-uyum' : 'https://www.temsa.com/tr/tr/etik-ve-uyum'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>,
                    ]}
                />,
            },
            {
                subtitle: t('faq.generalQ.subtitle6'),
                answer: <Trans
                    i18nKey={t('faq.generalQ.answer6')}
                    components={[
                        <a
                            href="https://sanctiontrace.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>,
                    ]}
                />,
            },
            {
                subtitle: t('faq.generalQ.subtitle7'),
                answer: t('faq.generalQ.answer7'),
            },
            {
                subtitle: t('faq.generalQ.subtitle8'),
                answer: t('faq.generalQ.answer8'),
            }],
        },
        {
            key: 'reporting',
            label: t('faq.reporting'),
            questions: [{
                subtitle: t('faq.reportingQ.subtitle1'),
                answer: t('faq.reportingQ.answer1'),
            },
            {
                subtitle: t('faq.reportingQ.subtitle2'),
                answer: t('faq.reportingQ.answer2'),
            },
            {
                subtitle: t('faq.reportingQ.subtitle3'),
                answer: t('faq.reportingQ.answer3'),
            },
            {
                subtitle: t('faq.reportingQ.subtitle4'),
                answer: t('faq.reportingQ.answer4'),
            },
            {
                subtitle: t('faq.reportingQ.subtitle5'),
                answer: t('faq.reportingQ.answer5'),
            },
            {
                subtitle: t('faq.reportingQ.subtitle6'),
                answer: t('faq.reportingQ.answer6'),
            }],
        },
        {
            key: 'tips',
            label: t('faq.tips'),
            questions: [{
                subtitle: t('faq.tipsQ.subtitle1'),
                answer: t('faq.tipsQ.answer1'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle2'),
                answer: t('faq.tipsQ.answer2'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle3'),
                answer: t('faq.tipsQ.answer3'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle4'),
                answer: t('faq.tipsQ.answer4'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle5'),
                answer: t('faq.tipsQ.answer5'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle6'),
                answer: t('faq.tipsQ.answer6'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle7'),
                answer: t('faq.tipsQ.answer7'),
            },
            {
                subtitle: t('faq.tipsQ.subtitle8'),
                answer: t('faq.tipsQ.answer8'),
            }],
        },
    ];

    return (
        <Layout>
            <Header showLogout={false} showUser={false} showLogo companyType={1} />
            <Content
                className='content'
                style={{
                    background: `#fff`,
                    minHeight: 'calc(100vh - 150px)'
                }}
            >
                <Title>{t('faq.title')}</Title>
                {TITLES.map((item) => (
                    <Col span={24}>
                        <p id={item.key} className={styles['faq-title']}>{item.label}</p>
                        {item.questions.map((question) => (
                            <>
                                <p className={styles['faq-subtitle']}>{question.subtitle}</p>
                                <p>{question.answer}</p>

                            </>
                        ))}
                    </Col>
                ))}
            </Content>
        </Layout>
    )
};

export default FAQPage;