import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Col, Collapse, Form, Layout, RadioChangeEvent, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { FormInstance, useForm } from "antd/es/form/Form";

import ProgressCard from "./components/ProgressCard";
import GeneralInfo from "./components/GeneralInfo";
import Header from "../../components/Header";
import Question from "./components/Question";
import ReviewReportInfo from "./components/ReviewReportInfo";
import QuestionPreview from "./components/QuestionPreview";
import FormSubmitSuccess from "./components/FormSubmitSuccess";

import styles from './index.module.scss';

const KPMGFormPage = () => {
    const { t } = useTranslation();
    const [kpmgForm] = useForm();
    const kpmgFormRef: React.Ref<FormInstance> = useRef(null);

    const [accepted, setAccepted] = useState(null);
    const [pageQuestions, setPageQuestions] = useState<any[]>([]);
    const [percent, setPercent] = useState<number>(0);
    const [step, setStep] = useState<number>(1);

    const onChange = (e: RadioChangeEvent) => {
        setAccepted(e.target.value);
    };

    const onNext = () => {
        if (step === 1) {
            kpmgForm.validateFields().then(() => {
                setStep((step) => step + 1);
            }).catch((err) => {
                if (err.errorFields && err.errorFields.length > 0) {
                    const firstErrorField = err.errorFields[0].name;
                    const element = document.getElementById(firstErrorField);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                    }
                }
            })
        }
        else if (step < 3) {
            setStep((step) => step + 1);
        }
    }

    const onPrev = () => {
        if (step > 1) {
            setStep((step) => step - 1);
        }
    }

    const QUESTIONS = [
        {
            page: 1,
            label: "Tell Us What Happened",
            questions: [
                {
                    id: 1,
                    title: "Content of the Event",
                    subquestions: [
                        {
                            id: 1,
                            type: 1,
                            isRequired: true,
                            row: 1,
                            content: "Please indicate the company about which you will notify.",
                            slug: "company"
                        },
                        {
                            id: 2,
                            type: 1,
                            isRequired: true,
                            row: 5,
                            content: "Please specify the subject you want to report with all the details.",
                            slug: "subject"
                        }
                    ]
                },
                {
                    id: 2,
                    title: "Place and Time of the Event",
                    subquestions: [
                        {
                            id: 1,
                            type: 1,
                            isRequired: true,
                            row: 1,
                            content: "Please specify the place where the event subject to the notification took place.",
                            slug: "incidentCountry"
                        },
                        {
                            id: 2,
                            type: 1,
                            isRequired: true,
                            row: 1,
                            content: "Please indicate when the event occurred.",
                            slug: "when"
                        }
                    ]
                },
                {
                    id: 3,
                    title: "Attach Document",
                    subquestions: [
                        {
                            id: 1,
                            type: 3,
                            isRequired: false,
                            content: "You can share all documents, photographs and other documents that are in the nature of proof with us using the field below.",
                            slug: "documents"
                        }
                    ]
                },
                {
                    id: 4,
                    title: "Please Introduce Yourself",
                    isRequired: true,
                    slug: "personelInfo",
                    isQuestion: true,
                    type: 5,
                    options: [
                        {
                            id: 1,
                            value: 1,
                            label: "I would like my name and contact information to be shared with the Company management.",
                            explanation: "In this case, your name and contact information will be shared with the company you have notified."
                        },
                        {
                            id: 2,
                            value: 2,
                            label: "I would like my name and contact information to be shared only with KPMG.",
                            explanation: "In this case, your name and contact information will not be shared with the company you have notified and will only be known by KPMG. However, notification will be shared with the court if requested by the court."
                        },
                        {
                            id: 3,
                            value: 3,
                            label: "I do not want to share my name and contact information.",
                            explanation: "If you do not want to share your name and contact information, your notification will not be available to you when it is necessary to get feedback from you."
                        }
                    ]
                },
                {
                    id: 5,
                    title: "Please, indicate your affiliation with the company.",
                    isRequired: true,
                    slug: "affiliation",
                    isQuestion: true,
                    type: 4,
                    options: [
                        {
                            id: 1,
                            value: 1,
                            label: "I am a Company employee."
                        },
                        {
                            id: 2,
                            value: 2,
                            label: "I am a former Company employee."
                        },
                        {
                            id: -1,
                            value: -1,
                            label: "Other (Ex: Customer, Supplier, Consultant etc.)"
                        }
                    ]
                },
                {
                    id: 6,
                    title: "How did you become aware of the KPMG Ethics Hotline?",
                    isRequired: true,
                    slug: "howdidyoufindus",
                    isQuestion: true,
                    type: 4,
                    options: [
                        {
                            id: 1,
                            value: 1,
                            label: "Training"
                        },
                        {
                            id: 2,
                            value: 2,
                            label: "Email"
                        },
                        {
                            id: 3,
                            value: 3,
                            label: "Poster"
                        },
                        {
                            id: -1,
                            value: -1,
                            label: "Other"
                        }
                    ]
                }
            ]
        },
        {
            page: 2,
            label: "Review Your Report",
        },
        {
            page: 3,
            label: "Confirm Your Report"
        }
    ]

    useEffect(() => {
        const pageData = QUESTIONS?.find((item) => item.page === 1);
        setPageQuestions(pageData?.questions || []);
    }, [step]);

    useEffect(() => {
        if (step === 1 && accepted) {
            setPercent(20);
        } else if (step === 2) {
            setPercent(60);
        } else if (step === 3) {
            setPercent(100);
        } else {
            setPercent(0);
        }
    }, [accepted, step]);

    return (
        <Layout>
            <Header showLogout={false} showUser={false} companyType={2} />
            <Content
                className='content'
                id='kpmg-form'
                style={{
                    background: `#f2f2f2`,
                    minHeight: 'calc(100vh - 150px)',
                    marginBottom: '5em'
                }}
            >
                <Row gutter={[12, 12]}>
                    <Col xs={24} lg={8} style={{ position: 'relative' }}>
                        <ProgressCard
                            activeId={step}
                            percent={percent}
                            steps={QUESTIONS.map((elem) => ({ id: elem.page, label: elem.label }))}
                        />
                    </Col>
                    <Col xs={24} lg={16}>
                        {step === 1 && (
                            <>
                                <GeneralInfo accepted={accepted} onChange={onChange} />
                                <Form ref={kpmgFormRef} form={kpmgForm} layout="vertical" name="kpmg">
                                    {
                                        accepted && pageQuestions?.map((question) => (
                                            question.isQuestion ? (
                                                <div key={question.id} style={{ padding: '1em' }}>
                                                    <Collapse defaultActiveKey={question.id} accordion expandIconPosition="end" style={{ background: '#fff' }}>
                                                        <Collapse.Panel style={{ fontSize: '1.5em' }} header={question.title} key={question.id}>
                                                            <div id={question.slug}>
                                                                <Question question={question} parentSlug={question.slug} />
                                                            </div>
                                                        </Collapse.Panel>
                                                    </Collapse>
                                                </div>
                                            ) : (
                                                <div key={question.id} style={{ padding: '1em' }}>
                                                    <Collapse defaultActiveKey={question.id} accordion expandIconPosition="end" style={{ background: '#fff' }}>
                                                        <Collapse.Panel style={{ fontSize: '1.5em' }} header={question.title} key={question.id}>
                                                            {question?.subquestions?.map((option: any) =>
                                                                <div id={option.slug}>
                                                                    <Question question={option} parentSlug={question.slug} />
                                                                </div>
                                                            )}
                                                        </Collapse.Panel>
                                                    </Collapse>
                                                </div>
                                            )
                                        ))
                                    }
                                </Form>
                            </>
                        )}
                        {
                            step === 2 && (
                                <div>
                                    <ReviewReportInfo />
                                    <Form ref={kpmgFormRef} form={kpmgForm} layout="vertical" name="kpmg">
                                        <div style={{ background: '#fff', padding: '1.5em', marginTop: 24 }}>
                                            {pageQuestions?.map((question) => (
                                                question.isQuestion ? (
                                                    <>
                                                        <div className={styles.PreviewPanel}>
                                                            {question.title}
                                                        </div>
                                                        <QuestionPreview question={question} parentSlug={question.slug} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className={styles.PreviewPanel}>
                                                            {question.title}
                                                        </div>
                                                        {question?.subquestions?.map((option: any) => <QuestionPreview question={option} parentSlug={question.slug} />)}
                                                    </>
                                                )
                                            ))}
                                        </div>
                                    </Form>
                                </div>
                            )
                        }
                        {
                            step === 3 && (
                                <FormSubmitSuccess />
                            )
                        }
                    </Col>
                </Row>
            </Content>
            {step < 3 && (
                <div className={styles.ButtonArea}>
                    <Button
                        className={styles.PrevButton}
                        onClick={onPrev}
                    >
                        {t('kpmgForm.prev')}
                    </Button>
                    <Button
                        className={styles.NextButton}
                        onClick={onNext}
                    >
                        {t('kpmgForm.next')}
                    </Button>
                </div>
            )}
        </Layout >
    )
}

export default KPMGFormPage;