import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, Button, Form, Input, Radio, RadioChangeEvent, Card, UploadFile, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import type { UploadProps } from 'antd';

import styles from '../index.module.scss';

const { Dragger } = Upload;

const OTHER_OPTION_ID = -1;

interface QuestionData {
    content: string,
    id: number,
    type: number,
    slug: string,
    isRequired: boolean,
    row: number,
    options: Array<{ id: number, label: string, value: number, explanation?: string }>
}

const Question = (props: { question: QuestionData, parentSlug?: string }) => {
    const { question, parentSlug } = props;
    const { t } = useTranslation();
    const form = Form.useFormInstance();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleFileChange: UploadProps['onChange'] = (info) => {
        let list = [...info.fileList];
        list = list.slice(-1);
        setFileList([...list]);
    }

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        customRequest: () => { },
        fileList: fileList,
        onChange: handleFileChange,
        beforeUpload: () => { return false; }
    };

    const OtherOption = () => {
        const currentSlug = question.slug;
        const formValues = form.getFieldsValue();
        const currentSlugValue = formValues[currentSlug];

        const [selectedOption, setSelectedOption] = useState<number | null>(currentSlugValue);

        const handleOptionSelect = (e: RadioChangeEvent) => {
            setSelectedOption(e.target.value);
        };

        useEffect(() => {
            if (currentSlugValue) {
                setSelectedOption(currentSlugValue);
            }
        }, [currentSlugValue]);

        return (
            <span>
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    <Radio.Group
                        value={selectedOption}
                        style={{ width: '100%' }}
                        onChange={handleOptionSelect}
                    >
                        {question.options?.map((r, index) => (
                            <Col key={index} span={24}>
                                <div style={{ display: 'flex' }}>
                                    <div className={styles.Radio}>
                                        <Radio checked={form.getFieldValue(question.slug) === r.value} value={r.value} />
                                    </div>
                                    <div className={styles.Label}>{r.label}</div>
                                </div>
                            </Col>
                        ))}
                    </Radio.Group>
                </Form.Item>
                {selectedOption === -1 && (
                    <Card style={{ background: '#efefef', borderRadius: 10 }}>
                        <Form.Item name={`${currentSlug}description`} label={t('kpmgForm.pleaseSpecify')} rules={[{ required: true, message: t('errors.required')! }]}>
                            <Input type="text" autoFocus />
                        </Form.Item>
                    </Card>

                )}
            </span>
        );
    };

    const RadioOption = () => {
        const currentSlug = question.slug;
        const formValues = form.getFieldsValue();
        const currentSlugValue = formValues[currentSlug];

        const [selectedOption, setSelectedOption] = useState<number | null>(currentSlugValue);

        const handleOptionSelect = (e: RadioChangeEvent) => {
            setSelectedOption(e.target.value);
        };

        useEffect(() => {
            if (currentSlugValue) {
                setSelectedOption(currentSlugValue);
            }
        }, [currentSlugValue]);

        return (
            <span>
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    <Radio.Group
                        value={selectedOption}
                        style={{ width: '100%' }}
                        onChange={handleOptionSelect}
                    >
                        {question.options?.map((r, index) => (
                            <Col key={index} span={24} >
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div className={styles.Radio}>
                                            <Radio checked={form.getFieldValue(question.slug) === r.value} value={r.value} />
                                        </div>
                                        <div className={styles.Label}>{r.label}</div>
                                    </div>
                                    {selectedOption === r.value && (
                                        <Card style={{ background: '#efefef', marginTop: '1em', marginBottom: '1em' }}>
                                            <div className={styles.InfoCard}>
                                                <p style={{ marginLeft: '1em' }}>{r.explanation}</p>
                                            </div>
                                            {(selectedOption === 1 || selectedOption === 2) && (
                                                <Row align="middle" gutter={[12, 12]} style={{ width: '100%' }}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name={[question.slug, 'name']}
                                                            label={t('kpmgForm.name')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('errors.required')!,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={question.content} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name={[question.slug, 'lastName']}
                                                            label={t('kpmgForm.lastName')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('errors.required')!,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={question.content} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name={[question.slug, 'phone']}
                                                            label={t('kpmgForm.phone')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('errors.required')!,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={question.content} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name={[question.slug, 'email']}
                                                            label={t('kpmgForm.email')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('errors.required')!,
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder={question.content} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Card>
                                    )}
                                </div>
                            </Col>
                        ))}
                    </Radio.Group>
                </Form.Item>

            </span>
        );
    };

    switch (question.type) {
        case 1:
            return (
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug || parentSlug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    {question.row === 1 ? <Input /> : <Input.TextArea rows={question.row} />}
                </Form.Item>
            )
        case 2:
            return (
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug || parentSlug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    <Form.List name={question.slug}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <Row align="middle" key={field.key} gutter={[12, 12]} style={{ width: '100%' }}>
                                        <Col span={21}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'subject']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('errors.required')!,
                                                    },
                                                ]}
                                            >
                                                <Input placeholder={question.content} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Col>
                                    </Row>
                                ))}

                                <Form.Item>
                                    <Button onClick={() => add()} type="primary" icon={<PlusOutlined />}>
                                        {t('kpmgForm.add')}
                                    </Button>
                                </Form.Item>

                            </>
                        )}
                    </Form.List>
                </Form.Item>
            )
        case 3:
            return (
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug || parentSlug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <img src="/static/images/kpmg-document.png" alt='logo' height="90" width="87" style={{ margin: '30px 30%' }} />
                        </p>
                        <p style={{ margin: 20 }}>{t('kpmgForm.draggerText')}</p>
                    </Dragger>
                </Form.Item>
            )
        case 4:
            return (
                <>
                    {question.options?.findIndex((o) => o.value === OTHER_OPTION_ID)! > -1 ? (
                        <OtherOption />
                    ) : (
                        <Form.Item
                            className={styles.Question}
                            label={question.content}
                            name={question.slug || parentSlug}
                            rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                        >
                            <Radio.Group>
                                <Row gutter={[12, 12]}>
                                    {question.options?.map((r, index) => (
                                        <Col key={index} span={24}>
                                            <div
                                                onClick={() => {
                                                    form.setFieldValue(question.slug, r.value);
                                                }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <div className={styles.Radio}>
                                                        <Radio checked={form.getFieldValue(question.slug) === r.value} value={r.value} />
                                                    </div>
                                                    <div className={styles.Label}>{r.label}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    )}
                </>
            )
        case 5:
            return (
                <RadioOption />
            )
        case 6:
            return (
                <Form.Item
                    className={styles.Question}
                    label={question.content}
                    name={question.slug}
                    rules={[{ required: question.isRequired, message: t('errors.required')! }]}
                >
                    <Select style={{ width: '100%' }}>
                        {question.options?.map((option, _) => (
                            <Select.Option key={option.label} value={option.value}>
                                <p style={{ margin: 0, whiteSpace: 'break-spaces' }}>{option.label}</p>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        default:
            return null;
    }
}

export default Question;