import React from "react";
import { useTranslation } from "react-i18next";

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";

import styles from './index.module.scss';

const IncidentChannel = (props: { type: string, onChangeType: (value: string) => void }) => {
    const { type, onChangeType } = props;
    const { t } = useTranslation();

    return (
        <Form.Item name="channel">
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button
                    className={[
                        styles.IncidentChannel,
                        type === 'phone' ? styles['IncidentChannel__button--active'] : '',
                    ].join(' ')}
                    onClick={() => onChangeType('phone')}
                >
                    <PhoneOutlined className={styles['IncidentChannel__button--icon']} />
                    {t('startIncident.incidentChannel.phone')}
                </Button>
                <Button
                    className={[
                        styles.IncidentChannel,
                        type === 'mail' ? styles['IncidentChannel__button--active'] : '',
                    ].join(' ')}
                    onClick={() => onChangeType('mail')}
                >
                    <MailOutlined className={styles['IncidentChannel__button--icon']} />
                    {t('startIncident.incidentChannel.mail')}
                </Button>
            </div>
        </Form.Item>
    )
}

export default IncidentChannel;