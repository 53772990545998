import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEBUG_STATE, DEFAULT_STATE } from '../constants';
import { UserData } from '../interface';
import { RootState } from '../store';

const initialState: UserData = process.env.REACT_APP_DEBUG === 'true' ? DEBUG_STATE : DEFAULT_STATE;

export const portalSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLanguage: (state, action: PayloadAction<string | null>) => {
			state.language = action.payload;
		},
		setToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload;
		},
		setReportKey: (state, action: PayloadAction<string | null>) => {
			state.id = action.payload;
		},
		setIsAdmin: (state, action: PayloadAction<boolean>) => {
			state.isAdmin = action.payload;
		},
	},
	extraReducers: (builder) => { }
});

export const { setLanguage, setToken, setReportKey, setIsAdmin } = portalSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default portalSlice.reducer; 
