import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Form, Input, Button, Alert, Space, Row, Col, Typography } from 'antd';
import { Footer } from 'antd/es/layout/layout';

import Header from '../../components/Header';
import LinkText from '../../components/LinkText';

import { setReportKey } from '../../app/slices/userSlice';
import { whistleblowerLogin } from '../../app/apis/whistleblower';
import { getSession, setSession } from '../../helpers/session';

import styles from './index.module.scss';


const UserLogin = () => {
	const { t } = useTranslation();
	const [loginForm] = Form.useForm();
	const session = getSession();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [error, setError] = useState<string>('');

	const onSubmit = () => {
		const id: string = loginForm.getFieldValue('id');
		if (id) {
			whistleblowerLogin(loginForm.getFieldsValue())
				.then((response: { data: { token: any } }) => {
					setTimeout(() => {
						setSession(response.data.token, { id: id }, false);
						dispatch(setReportKey(id));
						navigate('/panel/');
					}, 500)
				})
				.catch((error: { response: { data: any } }) => {
					setError(error?.response.data);
				});
		}
	};

	if (session?.token) {
		navigate('/panel/');
	}

	return (
		<>
			<Header showLogout={false} showUser={false} />
			<Space direction="vertical" size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
				<Row justify="center">
					<Col>
						<Link to="/">
							<img src="/static/images/logo.png" alt="company" width="auto" height="124" />
						</Link>
					</Col>
				</Row>
				<Typography.Text>{t('login.user.title')}</Typography.Text>
				<Form form={loginForm} layout="vertical" onFinish={onSubmit}>
					<Row>
						<Col span={24}>
							<Form.Item name="id" label={t('login.user.reportKey')} rules={[{ required: true, message: t('errors.required')! }]}>
								<Input size="large" placeholder={t('login.user.reportKeyPlaceholder')!} autoComplete="on" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="password" label={t('login.password')} rules={[{ required: true, message: t('errors.required')! }]}>
								<Input type='password' size="large" placeholder={t('login.passwordPlaceholder')!} autoComplete="off" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Button htmlType="submit" type="primary" block style={{ minHeight: 50 }}>
								{t('login.signIn')}
							</Button>
						</Col>
					</Row>
					{error && (
						<Alert
							showIcon
							style={{ marginTop: '2em' }}
							description={error || t('login.error.message')}
							type="error"
						/>
					)}
				</Form>
				<LinkText to='/admin-login' target='_self'>
					<p className={styles.OtherLoginText}>{t('login.adminLogin')}</p>
				</LinkText>
			</Space>
			<Footer style={{ textAlign: 'center' }}>{t('globals.footer')}</Footer>
		</>
	);
};

export default UserLogin;
