import { ReactNode } from 'react';

import { AreaChartOutlined, FileSearchOutlined, FileOutlined, FormOutlined, NotificationOutlined, SettingOutlined } from '@ant-design/icons';

import Dashboard from './pages/Dashboard';
import Incidents from './pages/Incidents';
import Report from './pages/Whistleblower';
import ActivityFeed from './pages/ActivityFeedPage/ActivityFeed';
import Settings from './pages/Settings/Settings';
import StartIncident from './pages/StartIncident/StartIncident';

export interface RouteData {
	children: Array<RouteData> | undefined;
	element: ReactNode;
	icon: ReactNode | null;
	label: ReactNode;
	path: string;
	showInSideMenu: boolean;
	isAdmin: boolean;
}

const routes: Array<RouteData> = [
	{
		children: undefined,
		element: <Dashboard />,
		icon: <AreaChartOutlined />,
		label: 'dashboard',
		path: '/panel/dashboard',
		showInSideMenu: true,
		isAdmin: true
	},
	{
		children: undefined,
		element: <StartIncident />,
		icon: <FileSearchOutlined />,
		label: 'startNew',
		path: '/panel/create',
		showInSideMenu: false,
		isAdmin: true
	},
	{
		children: undefined,
		element: <Incidents.List />,
		icon: <FileSearchOutlined />,
		label: 'incidents',
		path: '/panel/incidents',
		showInSideMenu: true,
		isAdmin: true
	},
	{
		children: undefined,
		element: <Incidents.Detail />,
		icon: null,
		label: 'incidentDetail',
		path: '/panel/incidents/:incidentId',
		showInSideMenu: false,
		isAdmin: true
	},
	{
		children: undefined,
		element: <Incidents.Form />,
		icon: null,
		label: 'form',
		path: '/panel/incidents/:incidentId/form',
		showInSideMenu: false,
		isAdmin: true
	},
	{
		children: undefined,
		element: <ActivityFeed />,
		icon: <NotificationOutlined />,
		label: 'activityFeed',
		path: '/panel/activityFeed',
		showInSideMenu: true,
		isAdmin: true
	},
	{
		children: undefined,
		element: <Settings />,
		icon: <SettingOutlined />,
		label: 'settings',
		path: '/panel/settings',
		showInSideMenu: true,
		isAdmin: true
	},
	{
		children: undefined,
		element: <Report.Detail />,
		icon: <AreaChartOutlined />,
		label: 'reportDetails',
		path: '/panel/',
		showInSideMenu: true,
		isAdmin: false
	},
	{
		children: undefined,
		element: <Report.IncomingQuestions />,
		icon: <FileSearchOutlined />,
		label: 'incomingQuestions',
		path: '/panel/incoming-questions',
		showInSideMenu: true,
		isAdmin: false
	},
	{
		children: undefined,
		element: <Report.Documents />,
		icon: <FileOutlined />,
		label: 'documents',
		path: '/panel/documents',
		showInSideMenu: true,
		isAdmin: false
	},
	{
		children: undefined,
		element: <Report.Notes />,
		icon: <FormOutlined />,
		label: 'notes',
		path: '/panel/notes',
		showInSideMenu: true,
		isAdmin: false
	}
];

export default routes;
