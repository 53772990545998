import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { Divider } from 'antd';

import { IncidentNoteData } from '../../../app/interface';
import { useIncidentNotes } from '../hooks';

import styles from '../index.module.scss';

const IncidentNotes = () => {
	const { t } = useTranslation();
	const { incidentId } = useParams();
	const query = useIncidentNotes(incidentId!);
	const results = query?.data?.results || [];

	return (
		<div className={styles.Tabs}>
			<div className={styles.Notes}>
				<Divider />
				{results?.map((note: IncidentNoteData) => (
					<div key={note.id}>
						<p className={styles.NoteInfo} >{`${note.user?.name || note.user?.email || t('globals.whistleblower')} - ${dayjs(note.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
						{note.content}
						<Divider />
					</div>
				))}
			</div>
		</div>
	);
};

export default IncidentNotes;
