import { useQuery } from 'react-query';
import { getIncidentAuthorities, getIncidentDetail, getIncidentFiles, getIncidentLogs, getIncidentNotes, getIncidentQuestions, getIncidentUsers, getIncidents } from '../../../app/apis/incidents';

export const useIncidentDetail = (incidentId: string) => {
	return useQuery(
		['incidentDetail', incidentId],
		async () => {
			const response: any = await getIncidentDetail(incidentId);

			return response;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};

export const useIncidentDocuments = (incidentId: string, page: number, page_size: number) => {
	return useQuery(
		['incidentDocuments', incidentId, page, page_size],
		async () => {
			const response: any = await getIncidentFiles(incidentId, page, page_size);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};

export const useIncidentLogs = (incidentId: string, page: number, page_size: number) => {
	return useQuery(
		['incidentLogs', incidentId, page, page_size],
		async () => {
			const response: any = await getIncidentLogs(incidentId, page, page_size);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};

export const useIncidentNotes = (incidentId: string) => {
	return useQuery(
		['incidentNotes', incidentId],
		async () => {
			const response: any = await getIncidentNotes(incidentId);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};

export const useIncidents = (page?: number, page_size?: number) => {
	return useQuery(
		['incidents', page, page_size],
		async () => {
			const response: any = await getIncidents(page, page_size);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};

export const useIncidentQuestions = (incidentId: string, toggle: boolean) => {
	return useQuery(
		['incidentQuestions', incidentId, toggle],
		async () => {
			const response: any = await getIncidentQuestions(incidentId);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};


export const usePossibleUsers = (incidentId: string) => {
	return useQuery(
		['possibleUsers', incidentId],
		async () => {
			const response: any = await getIncidentUsers(incidentId);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};

export const useIncidentAuthorities = (incidentId: string, toggle: boolean) => {
	return useQuery(
		['authorities', incidentId, toggle],
		async () => {
			const response: any = await getIncidentAuthorities(incidentId);

			return response.data;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: true
		}
	);
};