import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Button, Form, Input } from "antd";

const FormSubmitSuccess = () => {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', maxWidth: 500, margin: "auto", marginBottom: '3em' }}>
            <Alert
                message={t('kpmgForm.success.p1')}
                type="success"
                showIcon
            />
            <p>{t('kpmgForm.success.info')}</p>
            <Form>
                <Form.Item
                    name="reportKey"
                    label={t('kpmgForm.success.reportKey')}
                    style={{ marginBottom: '4em' }}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('kpmgForm.success.password')}
                    style={{ marginBottom: '4em' }}
                >
                    <Input disabled />
                </Form.Item>
            </Form>
            <Button
                type="primary"
                style={{ marginLeft: '40%' }}
                href="/login"
            >
                {t('kpmgForm.success.login')}
            </Button>
        </div>
    )
}

export default FormSubmitSuccess;