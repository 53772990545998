import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Result, Button } from "antd";

import styles from './index.module.scss';

const NotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className={styles.Page}>
            <div className={styles.Content}>
                <Result
                    status="warning"
                    title={t('error.notFound.title')}
                    subTitle={t('error.notFound.subtitle')}
                    extra={
                        <Button
                            type="primary"
                            key="console"
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            {t('error.notFound.button')}
                        </Button>
                    }
                />
            </div>
        </div>

    )
}

export default NotFound;