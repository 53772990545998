import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import type { UploadProps } from 'antd';

const WhistleblowerQuestionModal = (props: { isOpen: boolean; setOpen: any; onSubmit: (props: { content: string, files: [] }) => void; }) => {
    const { t } = useTranslation();
    const [noteValue, setNoteValue] = useState<string>('');
    const [fileList, setFileList] = useState<[]>([]);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setNoteValue(value);
    };

    const handleClose = () => {
        props.setOpen(false);
        setNoteValue('');
        setFileList([]);
    };

    const handleSendQuestion = () => {
        const payload = {
            content: noteValue,
            files: fileList
        };
        props.onSubmit(payload);
        setNoteValue('');
        setFileList([]);
    }

    const handleFileChange = (info: any) => {
        const { fileList } = info;
        setFileList(fileList);
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange: handleFileChange,
        beforeUpload: () => false,
        fileList: fileList
    };

    useEffect(() => {
        if (noteValue?.length === 0 && fileList?.length === 0) {
            setDisabled(true);
        }
        else if (noteValue?.length > 0 || fileList?.length > 0) {
            setDisabled(false);
        }
    }, [noteValue, fileList])

    return (
        <Modal
            title={t('globals.whistleblowerModal.title')}
            centered
            open={props.isOpen}
            onOk={handleSendQuestion}
            onCancel={handleClose}
            okText={t('globals.submit')}
            cancelText={t('globals.cancel')}
            okButtonProps={{
                disabled: disabled
            }}
        >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <TextArea
                    onChange={handleNoteChange}
                    value={noteValue}
                    rows={4}
                    placeholder={t('globals.whistleblowerModal.questionPlaceholder') || ''}
                />
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('globals.whistleblowerModal.description')}</p>
                    <p className="ant-upload-hint">{t('globals.whistleblowerModal.subdescription')}</p>
                </Dragger>
            </Space>
        </Modal>
    )
}

export default WhistleblowerQuestionModal;