
import { useQuery } from "react-query";

import { getQuestions } from "../app/apis/questions";
import i18n from "../app/i18n";

export const useQuestions = () => {
    const language = i18n.language;

    return useQuery(
        ['questions', language],
        async () => {
            const response = await getQuestions(language);

            return response.data;
        },
        {
            initialData: null,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );
};