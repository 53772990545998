import React from 'react';

import { Col, Row } from 'antd';

import BasePage from '../BasePage';
import TotalIncident from './components/TotalIncident';
import Priority from './components/Priority';
import IncidentStats from './components/IncidentStats';
import IncidentStatus from './components/IncidentStatus';
import IncidentCountry from './components/IncidentCountry';
import AverageIncidentComparison from './components/AverageIncidentComparison';
import WhistleblowerRelationship from './components/WhistleblowerRelationship';
import AverageIncidentClosingTime from './components/AverageIncidentClosingTime';
import IncidentCategrories from './components/IncidentCategories';

import { useDashboardData } from './hooks';

const Dashboard = () => {
	const data = useDashboardData();

	return (
		<BasePage
			content={
				<div style={{ padding: '1em', background: '#f5f5f5' }}>
					<Row gutter={16} style={{ marginBottom: '2em' }}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<TotalIncident states={data.data?.incidents?.states} />
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Priority priorities={data.data?.incidents?.priorities} />
						</Col>
					</Row>
					<Row gutter={[16, 8]} style={{ marginBottom: '2em' }}>
						<Col xs={24} sm={24} md={24} lg={14} xl={16}>
							<IncidentStats organizations={data.data?.incidents?.organizations} />
						</Col>
						<Col xs={24} sm={24} md={24} lg={10} xl={8}>
							<Row>
								<Col span={24} style={{ marginBottom: '2em' }}>
									<IncidentStatus
										states={data.data?.incidents?.states}
										organizations={data.data?.incidents?.organizations}
									/>
								</Col>
								<Col span={24}>
									<IncidentCountry countries={data.data?.incidents?.countries} />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={[16, 8]} style={{ marginBottom: '2em' }}>
						<Col span={16}>
							<AverageIncidentComparison organizations={data.data?.incidents?.organizations} />
						</Col>
						<Col span={8}>
							<Row>
								<Col span={24} style={{ marginBottom: '2em' }}>
									<WhistleblowerRelationship relationships={data.data?.incidents?.relationships} />
								</Col>
								<Col span={24}>
									<AverageIncidentClosingTime averageDuration={data.data?.incidents?.averageResolveDuration} />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<IncidentCategrories categories={data.data?.incidents?.categories} />
						</Col>
					</Row>
				</div>
			}
			isTop={true}
			header={null}
		/>
	);
};

export default Dashboard;
