import React from 'react';
import { useLocation } from 'react-router-dom';

interface QueryParams {
    [key: string]: string;
}

function useQueryParams(): QueryParams {
    const { search } = useLocation();

    const queries = React.useMemo(() => {
        const searchParams: QueryParams = {};
        const params = new URLSearchParams(search);

        params.forEach((value, key) => {
            searchParams[key] = decodeURIComponent(value);
        });

        return searchParams;
    }, [search]);

    return queries;
}

export default useQueryParams;
