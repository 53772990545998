import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import { Badge, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import BasePage from '../BasePage';
import Header from './components/Header';

import { IncidentData } from '../../app/interface';
import { useIncidents } from './hooks';
import Priority from '../../constants/priority';

import styles from './index.module.scss';

const List = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(25);

	const query = useIncidents(page, pageSize);
	const columns: ColumnsType<IncidentData> = [
		{
			title: t('incident.list.submissionDate'),
			dataIndex: 'createdDate',
			key: 'createdDate',
			sorter: (a, b) => dayjs(a.createdDate).diff(b.createdDate),
			defaultSortOrder: 'descend',
			render: (createdDate) => dayjs(createdDate).format('DD/MM/YYYY HH:MM')
		},
		{
			title: t('incident.list.resolvedDate'),
			dataIndex: 'resolution',
			key: 'resolution',
			align: 'center',
			sorter: (a, b) => dayjs(a.resolution?.createdDate).diff(b.resolution?.createdDate),
			render: (resolution) => resolution?.createdDate ? dayjs(resolution.createdDate).format('DD/MM/YYYY HH:MM') : '-'
		},
		{
			title: t('incident.list.incident'),
			dataIndex: 'id',
			key: 'id',
			sorter: (a, b) => a.id.localeCompare(b.id),
			render: (id) => `#${id}`
		},
		{
			title: t('incident.list.company'),
			dataIndex: 'company',
			key: 'company',
			align: 'center',
			sorter: (a, b) => a.company?.name.localeCompare(b.company?.name),
			render: (company) => company?.name || '-'
		},
		{
			title: t('incident.list.category'),
			dataIndex: 'category',
			key: 'category',
			align: 'center',
			sorter: (a, b) => a.category?.name.localeCompare(b.category?.name),
			render: (category) => category?.name || '-'
		},
		{
			title: t('incident.list.priority'),
			key: 'priority',
			dataIndex: 'priority',
			render: (priority) => <Tag>{t(`priority.${Priority[priority as keyof typeof Priority]}`)}</Tag>
		},
		{
			title: t('incident.list.status'),
			key: 'status',
			dataIndex: 'status',
			sorter: (a, b) => a.status.name.localeCompare(b.status.name),
			render: (status) => <Badge status={status.colour} text={status.name} />
		},
		{
			title: t('incident.list.source'),
			dataIndex: 'source',
			key: 'source',
			align: 'center',
			//sorter: (a, b) => a.source.localeCompare(b.source),
			render: (source) => source || '-'
		},
		{
			title: t('incident.list.reporter'),
			dataIndex: 'reporter',
			key: 'reporter',
			align: 'center',
			//sorter: (a, b) => a.reporter.localeCompare(b.reporter),
			render: (reporter) => reporter || '-'
		},
		{
			title: t('incident.list.action'),
			key: 'action',
			render: (_, incident) => (
				<Space size="middle">
					<Link to={`/panel/incidents/${incident.id}`}>{t('incident.list.manage')}</Link>
				</Space>
			)
		}
	];

	return (
		<BasePage
			content={
				<div className={styles.List}>
					<p className={styles.ListTitle}>{t('incident.list.title')}</p>
					<Table
						columns={columns}
						dataSource={query.data != null ? query.data.results : []}
						loading={query.isLoading}
						scroll={{ x: '1000' }}
						pagination={{
							current: page,
							onChange: (page: number, pageSize: number) => {
								setPage(page);
								setPageSize(pageSize);
							},
							pageSize: pageSize,
							total: query.data != null ? query.data.count : 0
						}}
					/>
				</div>
			}
			isTop={false}
			header={
				<Header
					options={{
						filters: [
							{
								name: 'query',
								label: null,
								placeholder: 'Search',
								type: 'input',
								style: {
									width: '30%'
								}
							}
						]
					}}
					type="filters"
				/>
			}
		/>
	);
};

export default List;
