import apiClient from "../api";

const BASE_URL = `${process.env.REACT_APP_API_URL}/auth`;

export const adminLogin = (data: { email: string, password: string }) => {
    return apiClient.post(`${BASE_URL}/login/`, data);
};

export const adminVerifyLogin = (data: { auth_code: string, verification_code: string }) => {
    return apiClient.post(`${BASE_URL}/login/verify/`, data);
};

export const logout = () => {
    return apiClient.post(`${BASE_URL}/logout/`);
};

export const forgotPassword = (data: { email: string }) => {
    return apiClient.post(`${BASE_URL}/password/forgot/`, data);
};

export const resetPassword = (data: { password: string, token: string }) => {
    return apiClient.post(`${BASE_URL}/password/reset/`, data);
};