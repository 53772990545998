import React, { useEffect } from 'react';

import { Divider, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { FormQuestionData } from '../app/interface';
import { useQuestions } from '../hooks/useQuestions';

import styles from './index.module.scss';
import Question from '../pages/FormPage/components/Question';

const FormPreview = (props: { data: any }) => {
	const questions = useQuestions();
	const [ethicsForm] = useForm();
	const customLabelSlugs: string[] = ["company", "locationcountry", "incidentcountry", "incidentcity", "category"];
	const customQuestions: FormQuestionData[] = questions?.data?.results?.filter((q: FormQuestionData) => customLabelSlugs.includes(q.slug));
	const otherQuestions: FormQuestionData[] = questions?.data?.results?.filter((q: FormQuestionData) => !customLabelSlugs.includes(q.slug));

	useEffect(() => {
		if (props.data?.data) {
			ethicsForm.setFieldsValue(props.data.data.answers);
		}
		else if (props.data) {
			ethicsForm.setFieldsValue(props.data.answers);
		}
	}, [props.data, ethicsForm]);

	return (
		<div className={styles.Tabs}>
			<div className={styles.Overview}>
				<Form
					form={ethicsForm}
					name='ethics'
					labelAlign="left"
					layout='vertical'
					disabled
				>
					{customQuestions?.map((question: FormQuestionData) => (
						<div key={question.slug}>
							<Question key={question.slug} question={question} disabled />
							<Divider />
						</div>
					))}
					{otherQuestions?.map((question: FormQuestionData) => (
						<div key={question.slug}>
							<Question key={question.slug} question={question} disabled />
							<Divider />
						</div>
					))}
				</Form>
			</div>
		</div>
	);
};

export default FormPreview;
