import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Gauge } from '@ant-design/plots';

import { AverageIncidentClosingTimeData } from "../../../app/interface";

import styles from './index.module.scss';

const AverageIncidentClosingTime = (props: { averageDuration: AverageIncidentClosingTimeData }) => {
    const { t } = useTranslation();

    const config = {
        percent: 0.75,
        height: 200,
        range: {
            ticks: [0, 1],
            color: '#1A56F1',
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: undefined,
        statistic: {
            title: {
                offsetY: -50,
                style: {
                    color: '#fff',
                    fontSize: 24
                },
                formatter: () => {
                    const text = props.averageDuration?.days > 0 ? `${props.averageDuration?.days} ${t('dashboard.incidentClosingTime.days')}` : ''
                        .concat(
                            props.averageDuration?.hours > 0 ? `${props.averageDuration?.hours} ${t('dashboard.incidentClosingTime.hours')}` : ''
                        )
                        .concat(
                            props.averageDuration?.minutes > 0 ? `${props.averageDuration?.minutes} ${t('dashboard.incidentClosingTime.mitutes')}` : '-'
                        )
                    return text;
                },
            },
        } as any
    };
    return (
        <Card
            className={styles.InfoCard}
            style={{ background: '#0042EF' }}
            bodyStyle={{
                padding: '24px'
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Row style={{ marginBottom: 3 }}>
                        <Col>
                            <Row gutter={[16, 0]}>
                                <Col>
                                    <h4 style={{ color: '#fff' }} className={styles.Title}>
                                        {t('dashboard.incidentClosingTime.title')}
                                    </h4>
                                </Col>
                                <Col>
                                    <Tooltip style={{ color: '#fff' }} title={t('dashboard.incidentClosingTime.tooltip')}>
                                        <InfoCircleOutlined style={{ color: '#fff' }} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Gauge {...config} />
                </Col>
            </Row>
        </Card>
    )
};

export default AverageIncidentClosingTime;