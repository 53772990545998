import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Row,
    Col,
    Form,
    Alert,
    Input,
    Button,
    Space,
    Typography,
} from 'antd';
import { Footer } from 'antd/es/layout/layout';

import useLocalStorage from '../../hooks/useLocalStorage';
import Header from '../../components/Header';
import { forgotPassword } from '../../app/apis/auth';
import messages from '../../utils/message';

import styles from './index.module.scss';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [localEmail] = useLocalStorage('email', '');

    const [error, setError] = useState<{ statusCode?: string } | undefined>();
    const [success, setSuccess] = useState<boolean>();

    const onSubmit = (formValues: { email: string }) => {
        const { email } = formValues;
        forgotPassword({ email }).then(() => {
            messages.showSuccesMessage();
            setSuccess(true);
        }).catch((err) => {
            messages.showErrorMessage(err?.response?.data);
            setError(err?.response?.data);
        })
    };

    return (
        <>
            <Header showLogout={false} showUser={false} />
            <Space direction="vertical" size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
                <Row justify="center">
                    <Col>
                        <Link to="/">
                            <img alt="Logo" src="/static/images/logo.png" width="auto" height="124" />
                        </Link>
                    </Col>
                </Row>
                <Typography.Text>{t('forgotPassword.title')}</Typography.Text>
                <Form
                    layout="vertical"
                    initialValues={{ email: localEmail }}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label={t('forgotPassword.email.label')}
                                rules={[
                                    { required: true, message: t('errors.required')! },
                                    { type: 'email' },
                                ]}
                            >
                                <Input
                                    disabled={success}
                                    placeholder={t('forgotPassword.email.placeholder')!}
                                    autoComplete="on"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className={styles['forgot-password__alert']}>
                            {error && (
                                <Alert
                                    showIcon
                                    type="error"
                                    message={t([`forgotPassword.errors.${error?.statusCode}`, 'forgotPassword.errors.default'])}
                                />
                            )}
                        </Col>
                        <Col span={24}>
                            <Row justify="center">
                                <Col xs={24} sm={19} md={20} lg={17} xl={16}>
                                    <Button
                                        disabled={success}
                                        htmlType="submit"
                                        type="primary"
                                        style={{ minHeight: 50 }}
                                        className={styles['forgot-password__reset-button']}
                                    >
                                        {t('forgotPassword.reset')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col xs={24} sm={19} md={20} lg={17} xl={16}>
                                    <Link to="/admin-login">
                                        <Button
                                            type="text"
                                            style={{ minHeight: 50 }}
                                            className={styles['forgot-password__back-button']}
                                        >
                                            {t('forgotPassword.back')}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Space>
            <Footer style={{ textAlign: 'center' }}>{t('globals.footer')}</Footer>
        </>
    );
};

export default ForgotPassword;
