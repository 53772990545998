import moment from 'moment';

const sessionKey = 'SESSION';

export const getSession = () => {
    try {
        const session = JSON.parse(localStorage.getItem(sessionKey)!);
        return session ?? {};
    } catch (error) {
        return {};
    }
};

export const setSession = (token = null, user = {}, isAdmin = false, socketToken = null) => {
    const expires = moment().add(4, 'hours').format();
    localStorage.setItem(sessionKey, JSON.stringify({ expires, token, user, isAdmin, socketToken }));
};

export const setUser = (data: {}) => {
    const session = JSON.parse(localStorage.getItem(sessionKey)!);
    localStorage.setItem(sessionKey, JSON.stringify({ ...session, user: data }));
};

export const removeSession = () => {
    localStorage.removeItem(sessionKey);
};

export const checkIsAdmin = () => {
    const session = getSession();
    return session.isAdmin;
}

export const checkSession = () => {
    try {
        const session = getSession();
        const sessionToken = session?.token;
        const sessionExpires = session?.expires;

        if (sessionExpires) {
            const expires = new Date(sessionExpires);

            if (new Date() < expires && sessionToken) {
                return true;
            }
        }
        removeSession();
        return false;
    } catch (error) {
        removeSession();
        return false;
    }
};

export const checkSessionWhistleblower = () => {
    try {
        const session = getSession();
        const sessionToken = session?.user?.id;
        const sessionExpires = session?.expires;

        if (sessionExpires) {
            const expires = new Date(sessionExpires);

            if (new Date() < expires && sessionToken) {
                return true;
            }
        }
        removeSession();
        return false;
    } catch (error) {
        removeSession();
        return false;
    }
};