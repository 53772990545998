import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Card, Row, Col, Tooltip, RadioChangeEvent, Radio, Select } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Column as ColumnPlot } from '@ant-design/plots';

import { CountsData, OrganizationTotalData } from "../../../app/interface";

import styles from './index.module.scss';

const IncidentStats = (props: { organizations: Array<OrganizationTotalData> }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState<string>('month');
    const [selectedYear, setSelectedYear] = useState<number>(2023);
    const [data, setData] = useState<Array<{ dateLabel: string, value: number, label: string }>>([]);
    const [yearOptions, setYearOptions] = useState<Array<{ value: number, label: string }>>([]);

    const onTabChange = ({ target: { value } }: RadioChangeEvent) => {
        setTab(value);
    };

    const onYearChange = (value: number) => {
        setSelectedYear(value);
    };

    function findObjectByYear(arr: CountsData[], selectedYear: number): CountsData | undefined {
        return arr.find(obj => obj.year === selectedYear);
    }

    function findYearOptions(arr: CountsData[]) {
        arr.forEach((elem) => {
            if (yearOptions.findIndex((obj) => obj.value === elem.year) < 0)
                yearOptions.push({ value: elem.year, label: elem.year.toString() })
        });
        setYearOptions([...yearOptions]);
    }

    useEffect(() => {
        props.organizations?.forEach((org) => {
            if (org.counts.length > 0) {
                findYearOptions(org.counts);
                const selectedYearData = findObjectByYear(org.counts, selectedYear);
                selectedYearData?.months?.forEach((month) => {
                    let tempMonth: string = month.month.toString();
                    if (tempMonth.length === 1) {
                        tempMonth = `0${tempMonth}`;
                    }
                    data.push({
                        dateLabel: moment(`${selectedYear}-${tempMonth}-${tempMonth}`).format('MMM'),
                        value: month.count,
                        label: org.name
                    })
                });

            }
        });
        setData([...data]);
    }, [props.organizations, selectedYear])

    const config = {
        data: data,
        autoFit: true,
        width: 1920,
        appendPadding: [18, 0, 0, 36],
        xField: 'dateLabel',
        yField: 'value',
        seriesField: 'label',
        color: ['#6294F8', '#63D8AA', '#657597', '#F5BF2C'],
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
    };

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row gutter={[16, 16]}>
                <Col>
                    <Row justify="space-between" style={{ marginBottom: 3 }}>
                        <Col>
                            <Row gutter={[16, 0]}>
                                <Col>
                                    <h4 className={styles.Title}>
                                        {t('dashboard.incidentStats.title')}
                                    </h4>
                                </Col>
                                <Col>
                                    <Tooltip title={t('dashboard.incidentStats.tooltip')}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Radio.Group
                                onChange={onTabChange}
                                buttonStyle="solid"
                                value={tab}
                            >
                                <Radio.Button value="month">{t('dashboard.incidentStats.monthly')}</Radio.Button>
                                <Radio.Button value="year">{t('dashboard.incidentStats.yearly')}</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: '2em' }}>
                        <Col>○ {selectedYear}</Col>
                        <Col>
                            <Select
                                bordered={false}
                                value={selectedYear}
                                onChange={onYearChange}
                                style={{ minWidth: 120 }}
                                placeholder={<span style={{ color: '#0042EF', display: 'flex' }}>{t('dashboard.incidentStats.selectYear')}</span>}
                                options={yearOptions}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginTop: '3em' }}>
                            <ColumnPlot {...config} />
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Card>
    )
};

export default IncidentStats;