import { useCallback } from 'react';

import { countries } from '../constants/countries';

function useCountries() {

  const getCountry = useCallback(
    (countryCode: string = '') =>
      countries.find(({ alpha2, alpha3 }) =>
        [alpha2, alpha3].includes(countryCode.toLowerCase())
      ),
    [],
  );

  return { getCountry };
}

export default useCountries;
