import { useQuery } from 'react-query';
import { getNotifications } from '../../../app/apis/notifications';

export const useNotifications = (refetch: boolean) => {
	return useQuery(
		['notifications', refetch],
		async () => {
			const response: any = await getNotifications();

			return response;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};
