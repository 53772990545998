import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";

import AccountSettings from "./components/AccountSettings";
import ChangePassword from "./components/ChangePassword";

import styles from './index.module.scss';


const Settings = () => {
    const { t } = useTranslation();

    return (
        <>
            <Card
                className={styles['settings-account__header-wrapper']}
                bodyStyle={{ padding: '1.688rem 2rem' }}
            >
                <Row gutter={[8, 4]} align="middle">
                    <Col>
                        <h1 className={styles['settings-account__header']}>
                            {t('settings.header.title')}
                        </h1>
                    </Col>
                    <Col>
                        <span className={styles['settings-account__header-details']}>
                            {t('settings.header.details')}
                        </span>
                    </Col>
                </Row>
            </Card>
            <Row
                gutter={[48, 48]}
                justify="center"
                className={styles['settings-account']}
            >
                <Col sm={24} md={14} lg={12} xl={9} xxl={7}>
                    <AccountSettings />
                </Col>
                <Col sm={24} md={14} lg={12} xl={9} xxl={7}>
                    <ChangePassword />
                </Col>

            </Row>
        </>
    )
}

export default Settings;