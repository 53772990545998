import { Card, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { Line } from '@ant-design/plots';
import { useTranslation } from "react-i18next";
import styles from './index.module.scss';
import { AverageIncidentComparisonData, OrganizationTotalData } from "../../../app/interface";
import moment from "moment";

const AverageIncidentComparison = (props: { organizations: Array<OrganizationTotalData> }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<Array<AverageIncidentComparisonData>>([]);

    useEffect(() => {
        props.organizations?.forEach((org) => {
            org.counts?.forEach((count) => {
                count?.months?.forEach((month) => {
                    let tempMonth: string = month.month.toString();
                    if (tempMonth.length === 1) {
                        tempMonth = `0${tempMonth}`;
                    }
                    data?.push({
                        date: `${count.year}-${tempMonth}-${tempMonth}`,
                        value: month.count,
                        company: org.name
                    })

                });
            })
            setData([...data]);
        })
    }, [props.organizations?.length]);

    const config = {
        data: (data || []).flatMap((item: AverageIncidentComparisonData) => [
            {
                dateLabel: moment(item?.date).format('YYYY'),
                company: item.company,
                value: item.value
            }
        ]),
        autoFit: true,
        xField: 'dateLabel',
        yField: 'value',
        seriesField: 'company',
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row gutter={[10, 16]}>
                <Col>
                    <Row gutter={[16, 0]} style={{ marginBottom: '4em' }}>
                        <Col>
                            <h4 className={styles.Title}>
                                {t('dashboard.averageIncidentComparison.title')}
                            </h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.averageIncidentComparison.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ height: 600 }}>
                    <Line {...config} />

                </Col>
            </Row>
        </Card>
    )
};

export default AverageIncidentComparison;