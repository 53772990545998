import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import Table, { ColumnsType } from 'antd/es/table';

import { IncidentLogData } from '../../../app/interface';
import { useIncidentLogs } from '../hooks';

import styles from '../index.module.scss';

const History = () => {
	const { t } = useTranslation();
	const { incidentId } = useParams();
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(25);

	const query = useIncidentLogs(incidentId!, page, pageSize);

	const columns: ColumnsType<IncidentLogData> = [
		{
			title: t('incident.details.history.updatedDate'),
			dataIndex: 'createdDate',
			key: 'createdDate',
			sorter: (a, b) => dayjs(a.createdDate).diff(b.createdDate),
			defaultSortOrder: 'descend',
			render: (createdDate) => dayjs(createdDate).format('DD/MM/YYYY HH:MM')
		},
		{
			title: t('incident.details.history.user'),
			dataIndex: 'user',
			key: 'userName',
			render: (user) => (user != null ? user.name : t('incident.details.history.system'))
		},
		{
			title: t('incident.details.history.email'),
			dataIndex: 'user',
			key: 'userEmail',
			render: (user) => (user != null ? user.email : t('incident.details.history.system'))
		},
		{
			title: '',
			dataIndex: 'log',
			key: 'log'
		}
	];

	return (
		<div className={styles.Tabs}>
			<div className={styles.History}>
				<Table
					columns={columns}
					dataSource={query.data != null ? query.data.results : null}
					pagination={{
						current: page,
						onChange: (page: number, pageSize: number) => {
							setPage(page);
							setPageSize(pageSize);
						},
						pageSize: pageSize,
						total: query.data != null ? query.data.count : 0
					}}
				/>
			</div>
		</div>
	);
};

export default History;
