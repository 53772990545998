import { useQuery } from 'react-query';
import apiClient from "../app/api";

export const useLanguage = () => {
	return useQuery(
		['language'],
		async () => {
            const response = apiClient.get(`${process.env.REACT_APP_API_URL}/languages/`);

			return response;
		},
		{
			initialData: null,
			keepPreviousData: true,
			refetchOnWindowFocus: false
		}
	);
};