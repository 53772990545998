import apiClient from "../api";

const BASE_URL = `${process.env.REACT_APP_API_URL}/whistleblower/incidents`;

export const createIncident = () => {
    return apiClient.post(`${BASE_URL}/`);
};

export const sendIncident = (data: {}) => {
    return apiClient.post(`${BASE_URL}/send/`, data);
};

export const whistleblowerLogin = (data: { id: string, password: string }) => {
    return apiClient.post(`${BASE_URL}/authorize/`, data);
};

export const getWhistleblowerIncidentDetail = (id: string, token: string) => {
    return apiClient.get(`${BASE_URL}/${id}/`, { headers: { Authorization: token } });
}

export const whistleblowerFileDownload = (data: { incident: string, file: any }) => {
    return apiClient.post(`${BASE_URL}/download-file/`, data, { responseType: "blob" });
};

export const whistleblowerFileUpload = (data: any) => {
    return apiClient.post(`${BASE_URL}/files/upload/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getWhistleblowerIncidentCheck = (token: string) => {
    return apiClient.get(`${BASE_URL}/check`, { headers: { Authorization: `Token ${token}` } });
}

export const getWhistleblowerQuestions = () => {
    return apiClient.get(`${BASE_URL}/questions/`);
}

export const getWhistleblowerNotes = () => {
    return apiClient.get(`${BASE_URL}/notes/`);
}

export const whistleblowerReplyQuestion = (data: { content: string, question_id: number }) => {
    return apiClient.post(`${BASE_URL}/questions/reply/`, data);
};


export const submitWhistleblowerNote = (data: {}) => {
    return apiClient.post(`${BASE_URL}/notes/create/`, data);
}