import React from 'react';

import dayjs from 'dayjs';

import { Divider } from 'antd';

import { IncidentData, IncidentNoteData } from '../../../app/interface';
import { useWhistleblowerNotes } from '../hooks';

import styles from '../index.module.scss';

const IncidentNotes = (props: { incident: IncidentData }) => {
	const query = useWhistleblowerNotes();

	return (
		<div className={styles.Tabs}>
			<div className={styles.Notes}>
				{query?.data?.results?.map((note: IncidentNoteData) => (
					<div key={note.id}>
						<p className={styles.NoteInfo} >{`${dayjs(note.createdDate).format('DD/MM/YYYY HH:MM')}`}</p>
						{note.content}
						<Divider />
					</div>
				))}
			</div>
		</div>
	);
};

export default IncidentNotes;
