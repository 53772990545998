import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Divider } from 'antd';
import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

const AddDocumentModal = (props: { isOpen: boolean; setOpen: any; onSubmit: (props: []) => void }) => {
    const { t } = useTranslation();

    const [fileList, setFileList] = useState<[]>([]);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleOK = () => {
        props.onSubmit(fileList)
        props.setOpen(false);
        setFileList([]);
    }

    const handleCancel = () => {
        setFileList([]);
        props.setOpen(false)
    }

    const handleFileChange = (info: any) => {
        const { fileList } = info;
        setFileList(fileList);
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange: handleFileChange,
        beforeUpload: () => false,
        fileList: fileList
    };

    useEffect(() => {
        if (fileList?.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [fileList]);

    return (
        <Modal
            title={t('globals.documentModal.title')}
            centered
            open={props.isOpen}
            onOk={handleOK}
            onCancel={handleCancel}
            okText={t('globals.submit')}
            cancelText={t('globals.cancel')}
            okButtonProps={{
                disabled: disabled
            }}
        >
            <Divider />
            <Dragger
                {...uploadProps}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('globals.documentModal.description')}</p>
                <p className="ant-upload-hint">{t('globals.documentModal.subdescription')}</p>
            </Dragger>
        </Modal>
    )
}

export default AddDocumentModal;