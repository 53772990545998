import React from "react";

import { Form } from "antd";
import { useForm } from "antd/es/form/Form";

import QuestionPreview from "../../KPMGFormPage/components/QuestionPreview";

import styles from './index.module.scss';

const Preview = () => {
    const [kpmgForm] = useForm();

    const QUESTIONS = [
        {
            id: 1,
            title: "Content of the Event",
            subquestions: [
                {
                    id: 1,
                    type: 1,
                    isRequired: true,
                    row: 1,
                    content: "Please indicate the company about which you will notify.",
                    slug: "company"
                },
                {
                    id: 2,
                    type: 1,
                    isRequired: true,
                    row: 5,
                    content: "Please specify the subject you want to report with all the details.",
                    slug: "subject"
                }
            ]
        },
        {
            id: 2,
            title: "Place and Time of the Event",
            subquestions: [
                {
                    id: 1,
                    type: 1,
                    isRequired: true,
                    row: 1,
                    content: "Please specify the place where the event subject to the notification took place.",
                    slug: "incidentCountry"
                },
                {
                    id: 2,
                    type: 1,
                    isRequired: true,
                    row: 1,
                    content: "Please indicate when the event occurred.",
                    slug: "when"
                }
            ]
        },
        {
            id: 3,
            title: "Attach Document",
            subquestions: [
                {
                    id: 1,
                    type: 3,
                    isRequired: false,
                    content: "You can share all documents, photographs and other documents that are in the nature of proof with us using the field below.",
                    slug: "documents"
                }
            ]
        },
        {
            id: 4,
            title: "Please Introduce Yourself",
            isRequired: true,
            slug: "personelInfo",
            isQuestion: true,
            type: 5,
            options: [
                {
                    id: 1,
                    value: 1,
                    label: "I would like my name and contact information to be shared with the Company management.",
                    explanation: "In this case, your name and contact information will be shared with the company you have notified."
                },
                {
                    id: 2,
                    value: 2,
                    label: "I would like my name and contact information to be shared only with KPMG.",
                    explanation: "In this case, your name and contact information will not be shared with the company you have notified and will only be known by KPMG. However, notification will be shared with the court if requested by the court."
                },
                {
                    id: 3,
                    value: 3,
                    label: "I do not want to share my name and contact information.",
                    explanation: "If you do not want to share your name and contact information, your notification will not be available to you when it is necessary to get feedback from you."
                }
            ]
        },
        {
            id: 5,
            title: "Please, indicate your affiliation with the company.",
            isRequired: true,
            slug: "affiliation",
            isQuestion: true,
            type: 4,
            options: [
                {
                    id: 1,
                    value: 1,
                    label: "I am a Company employee."
                },
                {
                    id: 2,
                    value: 2,
                    label: "I am a former Company employee."
                },
                {
                    id: -1,
                    value: -1,
                    label: "Other (Ex: Customer, Supplier, Consultant etc.)"
                }
            ]
        },
        {
            id: 6,
            title: "How did you become aware of the KPMG Ethics Hotline?",
            isRequired: true,
            slug: "howdidyoufindus",
            isQuestion: true,
            type: 4,
            options: [
                {
                    id: 1,
                    value: 1,
                    label: "Training"
                },
                {
                    id: 2,
                    value: 2,
                    label: "Email"
                },
                {
                    id: 3,
                    value: 3,
                    label: "Poster"
                },
                {
                    id: -1,
                    value: -1,
                    label: "Other"
                }
            ]
        }
    ]

    return (
        <div style={{ maxWidth: 700, margin: "auto" }}>
            <Form form={kpmgForm} layout="vertical" name="kpmg">
                {QUESTIONS?.map((question: any) => (
                    question.isQuestion ? (
                        <>
                            <div className={styles.PreviewPanel}>
                                {question.title}
                            </div>
                            <QuestionPreview question={question} parentSlug={question.slug} />
                        </>
                    ) : (
                        <>
                            <div className={styles.PreviewPanel}>
                                {question.title}
                            </div>
                            {question?.subquestions?.map((option: any) => <QuestionPreview question={option} parentSlug={question.slug} />)}
                        </>
                    )
                ))}
            </Form>
        </div>
    )
}

export default Preview;