import React from "react";

import { Card, Progress } from "antd";

import styles from '../index.module.scss';

const ProgressCard = (props: { activeId: number, percent: number, steps: Array<{ id: number, label: string }> }) => {
    return (
        <Card className={styles.CustomCard} >
            <img src="/static/images/kpmg-download.png" alt='logo' height="90" width="87" style={{ margin: '30px 30%' }} />
            <Progress strokeColor='#7d25eb' percent={props.percent} showInfo={false} />
            {props.steps?.map((step) => (
                <div
                    key={step.id}
                    className={`${styles.StepItem} ${step.id <= props.activeId ? styles.SelectedStepItem : ''}`}
                >
                    <div style={{ marginRight: '0.5em' }}>
                        {step.id === props.activeId ? (
                            <img src="/static/images/right-circle-icon.png" alt='right' height="12" width="12" />
                        ) : step.id < props.activeId ? (
                            <img src="/static/images/checkmark-circle-outline.png" alt='right' height="12" width="12" />
                        ) : (
                            <img src="/static/images/circle-notch.png" alt='circle' height="12" width="12" />
                        )}
                    </div>
                    {step.label}
                </div>
            ))}

        </Card >
    )
}

export default ProgressCard;