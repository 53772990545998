import React from 'react';

import { Col, Form, Row } from 'antd';

import Filter from './Filter';

import { FilterData } from '../../../app/interface';

const Header = (props: { type: 'filters'; options: { filters?: Array<FilterData> } }) => {
	switch (props.type) {
		case 'filters': {
			return (
				<Form layout="inline" style={{ padding: '2vh 1vw', width: '100%' }}>
					<Row style={{ width: '100%' }}>
						{props.options.filters?.map((filter, index) => (
							<Col key={index} flex={index === 0 ? 'auto' : 24 / (props.options.filters?.length || 1)}>
								<Form.Item name={filter.name}>
									<Filter {...filter} />
								</Form.Item>
							</Col>
						))}
					</Row>
				</Form>
			);
		}
	}
};

export default Header;
