import { Card, Row, Col, Tooltip } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Radar as RadarPlot } from '@ant-design/plots';
import styles from './index.module.scss';
import temsaCompanies from "../../../constants/companies";
import { IncidentCategoryData } from "../../../app/interface";


const IncidentCategrories = (props: { categories: Array<IncidentCategoryData> }) => {
    const { t } = useTranslation();

    const data = [
        { category: "Action Against Employees", user: temsaCompanies.SKODA, score: 70 },
        { category: "Action Against Company", user: temsaCompanies.NORTH, score: 30 },
        { category: "Specific Law", user: temsaCompanies.SAS, score: 60 },
        { category: "Specific Law", user: temsaCompanies.DEUTCH, score: 70 },
        { category: "Actions in Business Partner", user: temsaCompanies.SKODA, score: 50 }
    ];

    const config = useMemo(
        () => ({
            data: data,
            autoFit: true,
            width: 600,
            height: 600,
            xField: 'category',
            yField: 'score',
            seriesField: 'user',
            appendPadding: [0, 10, 0, 10],
            meta: {
                score: {
                    min: 0,
                    max: 100
                },
            },
            xAxis: {
                line: null,
                tickLine: null,
                grid: {
                    line: {
                        style: {
                            lineDash: null,
                        },
                    },
                },
            },
            point: {
                size: 4,
            },
            area: {},
        }),
        [data],
    );

    return (
        <Card
            className={styles.InfoCard}
            bodyStyle={{
                height: '100%',
                padding: '24px',
            }}
        >
            <Row justify="center">
                <Col span={24}>
                    <Row gutter={[10, 0]} style={{ marginBottom: '3em' }}>
                        <Col>
                            <h4 className={styles.Title}>
                                {t('dashboard.incidentCategories.title')}
                            </h4>
                        </Col>
                        <Col>
                            <Tooltip title={t('dashboard.incidentCategories.tooltip')}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <RadarPlot {...config} />
                </Col>
            </Row>
        </Card>
    )
};

export default IncidentCategrories;