import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Input, Button, Alert, Space, Row, Col, Typography, Checkbox } from 'antd';
import { Footer } from 'antd/es/layout/layout';

import VerificationModal from '../../modals/VerificationModal';
import LinkText from '../../components/LinkText';
import Header from '../../components/Header';

import { adminLogin, adminVerifyLogin } from '../../app/apis/auth';
import { getSession, setSession } from '../../helpers/session';
import useLocalStorage from '../../hooks/useLocalStorage';
import { MfaResponseData } from '../../app/interface';

import styles from './index.module.scss';

const AdminLogin = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const session = getSession();

	const [localEmail, setLocalEmail] = useLocalStorage('email', '');

	const [isVerificationModalOpen, setVerificationModalOpen] = useState<boolean>(false);
	const [payload, setPayload] = useState<MfaResponseData>({ verificationCode: '' });
	const [errors, setErrors] = useState<Array<string>>([]);
	const [values, setValues] = useState<{ email: string, password: string, remember?: boolean }>({ email: localEmail || '', password: '' });

	const onSubmit = (formValues: { email: string, password: string, remember?: boolean }) => {
		if (formValues.email) {
			setValues(formValues);
		}
		const { email, password, remember } = formValues;

		if (remember) {
			setLocalEmail(email);
		} else {
			setLocalEmail('');
		}

		if (!payload.verificationCode) {
			adminLogin({ email, password })
				.then((response) => {
					setVerificationModalOpen(true);
					setPayload(response.data);
				})
				.catch((error: { response: { data: {} } }) => {
					const errs: Array<string> = Object.values(error?.response?.data);
					setErrors(errs);
				});
		}
	};

	const onResend = () => {
		onSubmit(values);
	};

	const onCancel = () => {
		setVerificationModalOpen(false);
	};

	const onVerify = async (code: string) => {
		if (payload.verificationCode) {
			adminVerifyLogin({ auth_code: code, verification_code: payload.verificationCode })
				.then((response) => {
					const { token, user, socketToken } = response.data;
					setTimeout(() => {
						setSession(token, user, true, socketToken);
						navigate('/panel/dashboard');
						setVerificationModalOpen(false);
					}, 500);
					return true;
				})
			return false;
		}
		return false;
	};

	if (session?.token) {
		navigate('/panel/dashboard');
	}

	return (
		<>
			<Header showLogout={false} showUser={false} />
			<Space direction="vertical" size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15 }}>
				<Row justify="center">
					<Col>
						<Link to="/">
							<img alt="Logo" src="/static/images/logo.png" width="auto" height="124" />
						</Link>
					</Col>
				</Row>
				<Typography.Text>{t('login.admin.title')}</Typography.Text>
				<Form
					//form={loginForm}
					layout="vertical"
					onFinish={onSubmit}
					initialValues={{
						email: localEmail,
						password: '',
						remember: Boolean(localEmail),
					}}
				>
					<Row>
						<Col span={24}>
							<Form.Item name="email" label={t('login.admin.email')} rules={[{ required: true, message: t('errors.required')! }, { type: 'email' }]}>
								<Input placeholder={t('login.admin.emailPlaceholder') || ''} autoComplete="on" />
							</Form.Item>
						</Col>
						<Col span={24}>

							<Form.Item name="password" label={t('login.password')} rules={[{ required: true, message: t('errors.required')! }]}>
								<Input.Password placeholder={t('login.passwordPlaceholder') || ''} />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Button
								block
								htmlType='submit'
								type="primary"
								style={{ minHeight: 50 }}
							>
								{t('login.signIn')}
							</Button>
						</Col>
					</Row>
					{errors && errors.map((error) => (
						<Alert
							showIcon
							style={{ marginTop: '2em' }}
							description={error}
							type="error"
						/>
					))}
					<Col span={24} style={{ marginTop: 5 }} >
						<Row justify="space-between" style={{ alignItems: 'baseline' }}>
							<Col>
								<Form.Item name="remember" valuePropName="checked">
									<Checkbox>{t('login.rememberMe')}</Checkbox>
								</Form.Item>
							</Col>
							<Col>
								<Link to="/forgot-password">{t('login.forgotPassword')}</Link>
							</Col>
						</Row>
					</Col>
				</Form>
				<LinkText to='/login' target='_self'>
					<p className={styles.OtherLoginText}>{t('login.userLogin')}</p>
				</LinkText>
			</Space>
			<Footer style={{ textAlign: 'center' }}>{t('globals.footer')}</Footer>
			<VerificationModal
				pending={false}
				error={null}
				open={isVerificationModalOpen}
				email={payload.mfaType === 'email' ? payload.sentAddress : undefined}
				phone={payload.mfaType !== 'email' ? payload.sentAddress : undefined}
				timeout={120}
				onResend={onResend}
				onCancel={onCancel}
				onVerify={onVerify}
				method={payload.mfaType}
			/>
		</>
	);
};

export default AdminLogin;
